import React, { useEffect } from 'react';
import {useSelector} from 'react-redux'
import * as ROUTES from './constants/routes';
import { AuthUserContext, withAuthentication } from './context/session';
import { withFirebase } from './context/firebase';
import '../assets/css/navbar.css';
import logo from '../assets/img/logo.png';
import flecha from '../assets/img/flecha.png';
import {Nav, Navbar} from 'react-bootstrap';
import "../assets/css/sra.css";


const Navigation = (props) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth {...props} /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const Navbar2 = (props) =>{
  
  return (
    <Navbar expand="lg" className="background-nav">
    <Navbar.Brand>
    <img onClick={()=>window.history.back()} src={flecha} width="35" height="20" alt=""/>
    
    <img src={logo} width="40" height="40" alt=""/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Navigation {...props}/>
      </Nav>
    </Navbar.Collapse>
  </Navbar>  
  );
}

const NavigationAuth = (props) => {
  
  const user_role = useSelector((state) => state.user.role);

  const logout = async() => {
    try {
      await props.firebase.doSignOut();
      props.history.push("/signin");      
    }
    catch(Error){
        console.log(Error)            
    } 
  }

    if(user_role === 'asistente'){
      return(
        <Nav className="mr-auto">      
          <Nav.Link href={ROUTES.PARTICIPANTES}><h6 className="text-nav">Participantes</h6></Nav.Link>
          <Nav.Link href={ROUTES.ADMIN_CULTOS}><h6 className="text-nav">Asistencia Cultos</h6></Nav.Link>  
          <Nav.Link href={ROUTES.PASSWORD_CHANGE}><h6 className="text-nav">Cambiar Contraseña</h6></Nav.Link>          
          <button className="nav-link  btn btn-outline " onClick={logout}><h6 className="text-nav">Salir</h6></button>      
        </Nav>
      );
    }else if(user_role === 'superadmin'){
      return(
        <Nav className="mr-auto">      
          <Nav.Link href={ROUTES.SUPERADMIN}><h6 className="text-nav">Super Admin</h6></Nav.Link>
          <Nav.Link href={ROUTES.PARTICIPANTES}><h6 className="text-nav">Participantes</h6></Nav.Link>  
          <button className="nav-link  btn btn-outline " onClick={logout}><h6 className="text-nav">Salir</h6></button>      
        </Nav>
      );
    }else if(user_role === 'admin'){
      return(
        <Nav className="mr-auto">                  
          <Nav.Link href={ROUTES.PARTICIPANTES}><h6 className="text-nav">Participantes</h6></Nav.Link>  
          <Nav.Link href={ROUTES.ADMIN_CULTOS}><h6 className="text-nav">Administrar Cultos</h6></Nav.Link>  
          <Nav.Link href={ROUTES.PASSWORD_CHANGE}><h6 className="text-nav">Cambiar Contraseña</h6></Nav.Link>        
          <button className="nav-link  btn btn-outline " onClick={logout}><h6 className="text-nav">Salir</h6></button>      
        </Nav>
      );
    }else if(user_role === 'usuario'){
      return(
        <Nav className="mr-auto">      
          <Nav.Link href={ROUTES.PASSWORD_CHANGE}><h6 className="text-nav">Cambiar Contraseña</h6></Nav.Link>          
          <Nav.Link href={ROUTES.PARTICIPANTES}><h6 className="text-nav">Participantes</h6></Nav.Link>          
          <button className="nav-link  btn btn-outline " onClick={logout}><h6 className="text-nav">Salir</h6></button>      
        </Nav>
      );
    }else{
      return(<Nav className="mr-auto">               
              <button className="nav-link  btn btn-outline " onClick={logout}><h6 className="text-nav">Salir</h6></button>      
            </Nav>)
        }
}

const NavigationNonAuth = () => {
  let URLactual = window.location;
  URLactual = String(URLactual);
  if(URLactual.indexOf('signup')>=0){
    return(
    <Nav className="mr-auto">    
       <Nav.Link href={ROUTES.SIGN_IN}><h6 className="text-nav">Iniciar Sesión</h6></Nav.Link>
    </Nav>)
  }else if(URLactual.indexOf('signin')>=0){
    return(
    <Nav className="mr-auto">    
      <Nav.Link href={ROUTES.SIGN_UP}><h6 className="text-nav">Crear Cuenta</h6></Nav.Link>
    </Nav>
    )
  }else{
    return(
      <Nav className="mr-auto">    
        <Nav.Link href={ROUTES.SIGN_UP}><h6 className="text-nav">Crear Cuenta</h6></Nav.Link>
      </Nav>
      )
  }
  
};

export default withAuthentication(withFirebase(Navbar2));