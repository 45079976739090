import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import { withAuthorization } from '../context/session';
import {withAuthentication} from '../context/session';
import "../../assets/css/sra.css";
import Swal from "sweetalert2";
import GenerateQr from "./generarQr";
import {TimeConverter} from "../constants/funciones";

function Home (props) {
    
    const [checked1, setCheked1] = useState(false);
    const [checked2, setCheked2] = useState(false);
    const [checked3, setCheked3] = useState(false);
    const [checked4, setCheked4] = useState(false);
    const [checked5, setCheked5] = useState(false);
    const [checked6, setCheked6] = useState(false);
    const [checked7, setCheked7] = useState(false);
    const [checked8, setCheked8] = useState(false);
    const [checked9, setCheked9] = useState(false);
    const [checked10, setCheked10] = useState(false);
    const [checked11, setCheked11] = useState(false);
    const [checked12, setCheked12] = useState(false);
    const [checked13, setCheked13] = useState(false);
    const [iglesia, setIglesia] = useState({});
    const [usuario, setUsuario] = useState({});
    const [miembro, setMiembro] = useState({});
    const [culto, setCulto] = useState({});
    const [fechaCulto, setFechaCulto] = useState('');
    const [showQr, setShowQr] = useState(false);
    const [datosQR, setDatosQr] = useState({});

    const user_uid = useSelector((state) => state.user.uid);
    const iglesia_uid = useSelector((state) => state.user.iglesia);
    const culto_uid = useSelector((state) => state.user.culto);
    
    useEffect(()=>{
        if(iglesia_uid===null){
            props.history.push('/participantes');
        }else{
            props.firebase.doRefDoc('iglesias',iglesia_uid).get()
            .then((querySnapshot)=>{
                setIglesia({...querySnapshot.data(), id: querySnapshot.id})    
            })
            props.firebase.doRefDoc('iglesias',iglesia_uid).collection('cultos').doc(culto_uid).get()
            .then((querySnapshot)=>{
                setCulto({...querySnapshot.data(), id:querySnapshot.id });     
                setFechaCulto(TimeConverter(querySnapshot.data()['date'].seconds));          
            })
            props.firebase.doRefDoc('user',user_uid).get()
            .then((querySnapshot)=>{
                setUsuario({...querySnapshot.data(), id:querySnapshot.id });                    
            })

            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let idPart
            if(urlParams.has('idPart')){
                idPart = urlParams.get('idPart')
                props.firebase.doRefDoc('user',user_uid).collection('miembros').doc(idPart).get()
                .then((querySnapshot)=>{
                    setMiembro({...querySnapshot.data(), id:querySnapshot.id });                    
                })
            }
            
        }
        
        
    },[])

    const salir = () => {
        Swal.fire({                    
            icon: 'success',
            title: 'Reserva creada con éxito.',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#F9B000',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
                props.history.push('/participantes');
            }else{
                props.history.push('/participantes');
            }           
        })
        
    }   

    const generarQr = (data) =>{
        setDatosQr(data)
        setShowQr(true)        
    }

    const enviar = () =>{
        
        if( checked1 || checked2 || checked4 || checked5 ||
            checked6 || checked7 || checked8 ||
            checked10 || checked11 || checked12 
            ){

            Swal.fire({
                icon: 'warning',
                title: 'Su salud esta en riesgo.',
                text: 'Marcaste en una o más de las respuestas, por favor quédate en casa y utiliza tapabocas. Es posible que tengas síntomas de COVID-19, comunícate telefónicamente con tu EPS.',
                confirmButtonColor: '#F9B000',
            })
            props.history.push('/participantes'); 
            return           
        } 
        
        if( checked9===false || checked13===false ){
                Swal.fire({
                    icon: 'warning',
                    title: 'Responda correctamente',
                    text: '',
                    confirmButtonColor: '#F9B000',
                })
                return
        }
        if(checked9===true && checked13===true){

            props.firebase.doRefDoc('iglesias',iglesia_uid).collection('cultos').doc(culto_uid).get()
        .then(function(querySnapshot) {
                                
            let cupos = querySnapshot.data().cupos;

            if(cupos > 0){               
                props.firebase.doRefDoc('iglesias',iglesia_uid).collection('cultos').doc(culto_uid).collection('participantes').doc(miembro.id).get()
                .then(async(doc)=> {
                    if (doc.exists) {
                        Swal.fire({                    
                                icon: 'warning',
                                title: 'Ya tiene una reserva para este culto.',
                                showConfirmButton: true,
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#F9B000',
                                })
                        
                        props.history.push('/reservaCultos?idPart='+miembro.id);
                    } else {
                        let dataTemp1 = await  props.firebase.doRefDoc('iglesias',iglesia_uid).collection('cultos').doc(culto_uid).update({cupos: cupos-1});
                        
                        let dataTemp2 = await props.firebase.doRefDoc('iglesias',iglesia_uid).collection('cultos').doc(culto_uid).collection('participantes').doc(miembro.id).set({
                            asistencia: false,
                            user:usuario,
                            miembro:miembro
                        })

                        let dataTemp3 = await props.firebase.doRefDoc('user',user_uid).collection('reservas').add({
                            iglesiaReserva: iglesia,
                            fechaReserva: culto['date'].seconds,
                            cultoReserva: culto,
                            miembro
                        })
                       
                        let dataTemp4 = await props.firebase.doRefDoc('iglesias',iglesia_uid).collection('cultos').doc(culto_uid).collection('participantes').doc(miembro.id).set({
                            asistencia: false,
                            user:usuario,
                            miembro:miembro
                        })
                        
                        let datatemp5 = {                            
                            nameiglesia:iglesia.CHURCH_NAME,
                            nameculto:culto.name,
                            fechaculto:fechaCulto,
                            namemiembro:miembro.nombres +" "+miembro.apellidos,                                        
                            idiglesia:iglesia_uid,
                            idculto:culto_uid, 
                            idmiembro:miembro.id
                        }   
                                                                                           
                        generarQr(datatemp5)                           
                    }
                })
                .catch(()=>{                   
                })
                    
                }else{
                    Swal.fire({                    
                        icon: 'warning',
                        title: 'Se han agotado los cupos',
                        text: 'Busque un culto que tenga capacidad disponible',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#F9B000',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.history.push('/reservaCultos?idPart='+miembro.id);
                        }           
                    })
                }    
            });

        }

        
    } 
      
    return ( 
        <>            
           <div className="container  p-auto  bg-whithe text-dark">
                <h1 className="h3 mb-3 font-weight-normal text-dark">Seleccione los Síntomas que ha presentado en los últimos 14 días:</h1>        
                <div className="row">
                    <div className="col">
                        <div className="col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox1" onChange={() => setCheked1(!checked1)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox1">Fiebre de difícil control (+37,5°C)</label>
                        </div>     
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox2" onChange={() => setCheked2(!checked2)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox2">Dolor de garganta</label>                  
                        </div>
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox3" onChange={() => setCheked3(!checked3)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox3">Congestión nasal</label>                  
                        </div>
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox4" onChange={() => setCheked4(!checked4)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox4">Tos seca y persistente</label>                  
                        </div>
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox5" onChange={() => setCheked5(!checked5)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox5">Dificultad para respirar</label>                  
                        </div>
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox6" onChange={() => setCheked6(!checked6)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox6">Fatiga</label>                  
                        </div>       
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox7" onChange={() => setCheked7(!checked7)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox7">Escalofrío</label>                  
                        </div>       
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox8" onChange={() => setCheked8(!checked8)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox8">Dolor muscular</label>                  
                        </div>       
                        <p></p>
                        <div className=" col form-check form-check-inline">
                            <input className="form-check-input checked" type="checkbox" id="inlineCheckbox9" onChange={() => setCheked9(!checked9)}/>
                            <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox9">Ninguno de los anteriores</label>                  
                        </div>       
                        <p></p>
                        
                        
                    </div>
                    
                </div>

                <h1 className="h3 mb-3 font-weight-normal text-dark">Elige las opciones que aplique:</h1>        
                    <div className="row">
                        <div className="col">
                            <div className="col form-check form-check-inline">
                                <input className="form-check-input checked" type="checkbox" id="inlineCheckbox10" onChange={() => setCheked10(!checked10)}/>
                                <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox10">¿Estuvo en contacto con alguien que tuvo alguno de los síntomas en los últimos 14 días?</label>
                            </div>
                            <p></p>
                            <div className="col form-check form-check-inline">
                                <input className="form-check-input checked" type="checkbox" id="inlineCheckbox11" onChange={() => setCheked11(!checked11)}/>
                                <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox11">¿Esta en espera del resultado de la prueba del COVID 19?</label>
                            </div>
                            <p></p>
                            <div className="col form-check form-check-inline">
                                <input className="form-check-input checked" type="checkbox" id="inlineCheckbox12" onChange={() => setCheked12(!checked12)}/>
                                <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox12">¿Ha sido diagnosticado de COVID 19 en los últimos 14 días?</label>
                            </div>
                            <p></p>
                            <div className="col form-check form-check-inline">
                                <input className="form-check-input checked" type="checkbox" id="inlineCheckbox13" onChange={() => setCheked13(!checked13)}/>
                                <label className="form-check-label font-weight-bolder" htmlFor="inlineCheckbox13">Ninguna de las anteriores</label>
                            </div>
                            <p></p>
                        </div>
                    </div>         
                <div className="row-4"> 
                    <center>
                        <button type="button" className="btn btn-default center" style={{"backgroundColor": "#F9B000"}} onClick={()=> enviar()}>Confirmar</button>                                                               
                    </center>                    
                </div> 
                <div className="row-2"> 
                    <center>
                        <p><br></br></p>                                             
                    </center>                    
                </div> 
                
            </div> 
            {showQr &&             
                <GenerateQr 
                    data={datosQR} 
                    showQr={showQr} 
                    setShowQr={setShowQr}
                    salir={salir}
                />                          
            }           
        </>  
      );
}

const condition = authUser => authUser !== null;
 
export default withAuthorization(condition)(withAuthentication(Home));
