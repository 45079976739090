import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {useDispatch} from 'react-redux'
import {cargarUsuario,cargarUsuarioAdmin,cargarIglesia} from './actions/authAction';
import { createBrowserHistory } from "history";
import Login from "./components/Authentication/login.js";
import Account from "./components/Authentication/createUser.js";
import ResetPassword from "./components/Authentication/resetPassword.js";
import ChangePassword from "./components/Authentication/changePassword.js";
import VerifyEmail from "./components/Authentication/verify.js";
import { AuthUserContext } from './components/context/session/index.js';
import Navegation from './components/navegation';
import { withFirebase } from './components/context/firebase';
import Datauser from "./components/registroUser/datosBasicos";
import ReservaCultos from "./components/reserva/reservaCultos";
import Requisitos from "./components/reserva/requisitos";
import AdminCultos from "./components/reserva/adminCultos";
import Asitencia from "./components/reserva/asistencia";
import carga from "./components/context/firebase/importar";
import SuperAdmnin from "./components/Authentication/superadmin";
import Participantes from "./components/registroUser/participantes";
const hist = createBrowserHistory();

function App(props) {
  const [authUser, setAuthUser ] = useState(null);

  const dispatch=useDispatch();

  useEffect(()=>{
    props.firebase.auth.onAuthStateChanged(
      authUser2 => {
        if(authUser2 !== authUser){
            authUser2 
                ? setAuthUser(authUser2)
                : setAuthUser(null);
                
            dispatch(cargarUsuario({uid:authUser2.uid}))

            props.firebase.doRefDoc('user',authUser2.uid).get()
            .then((doc)=>{
              let role = doc.data().role
              let distrito = doc.data().distrito
              let iglesia = doc.data().iglesia
              let iglesiaAsistente = doc.data().iglesiaAsistente
              dispatch(cargarUsuarioAdmin({role, distrito,iglesiaAsistente }));
              dispatch(cargarIglesia({iglesia}));
             
            })
          }
      },
    );
  });
  return (
    <AuthUserContext.Provider value={authUser}>      
        <Router  history={hist}>
          <Navegation/>
          <Switch>
              <Route path='/signin' component={Login}  />
              <Route path='/signup' component={Account}/>                                                  
              <Route path='/verify' component={VerifyEmail}/>                                                  
              <Route path='/resetPassword' component={ResetPassword}/>
              <Route path='/changePassword' component={ChangePassword}/>                       
              <Route path='/datauser' component={Datauser}/>                            
              <Route path='/reservaCultos' component={ReservaCultos}/>                            
              <Route path='/requisitos' component={Requisitos}/>                            
              <Route path='/adminCultos' component={AdminCultos}/>                            
              <Route path='/asistencia' component={Asitencia}/>  
              <Route path='/participantes' component={Participantes}/>  
              <Route path='/xsuperadminx' component={SuperAdmnin}/>  
              {/* <Route path='/cargamasiva' component={carga}/>  */}                               
              <Route path="/" component={Participantes} />
            </Switch>
        </Router>      
    </AuthUserContext.Provider>              
  );
}



export default withFirebase(App);
