import React, {useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/signin.css';
import logo from "../../assets/img/logo.png";
import { withFirebase } from '../context/firebase';
import * as ROUTES from '../constants/routes';
import Swal from "sweetalert2";

function ResetPassword (props) {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    const reset = async(e) => {
      e.preventDefault();
        try {
           let user = await props.firebase.doPasswordReset(email);
           Swal.fire(
            'Se ha enviado un mensaje a su correo electrónico',
            '',
            'success'
        )
            props.history.push(ROUTES.SIGN_IN);
            
          }
        catch(Error){
            setError(Error)            
        }     
    }
  
    return ( 
        <>
            <center>
              <div className="text-center">       
                <form className="form-signin" onSubmit={reset}>
                  <img className="mb-4" src={logo} alt="" width="140" height="140"/>
                  <h1 className="h3 mb-3 font-weight-normal">Restaurar Contraseña</h1>
                  <label htmlFor="inputEmail" className="sr-only">Email address</label>
                  <input type="email" id="inputEmail" className="form-control" onChange= {e => setEmail(e.target.value)} placeholder="Email address" required autoFocus/>
                  <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} type="submit">Restaurar</button>
                  <button className="btn btn-lg btn-default btn-block" onClick={() => props.history.push(ROUTES.SIGN_IN)}>Iniciar Sesión</button>
                  {error && <div>{error.message}</div>}
                  <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
                </form>
               
              </div>
             
            </center>
        </>  
      );
}

export default withFirebase(ResetPassword);