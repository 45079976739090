export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_IN_ASAMBLEA = '/login/asamblea/';
export const HOME = '/home/';
export const PASSWORD_FORGET = '/resetPassword';
export const PASSWORD_CHANGE = '/changePassword';
export const ADMIN_ASAMBLEA = '/admin/asamblea/';
export const ADMIN = '/admin';
export const CREATE_ASAMBLEA = '/createAsamblea';
export const DATA_USER = '/datauser';
export const IGLESIAS = '/reservaCultos';
export const ADMIN_CULTOS = '/adminCultos';
export const SUPERADMIN = '/xsuperadminx';
export const PARTICIPANTES = '/participantes';
