import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {cargarIglesia, cargarCulto} from '../../actions/authAction';
import { withAuthorization } from '../context/session';
import {Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {withAuthentication} from '../context/session';
import '../../assets/css/list.css';
import {TimeConverter} from "../constants/funciones";
import Swal from "sweetalert2";

function Home (props) {
    const user_uid = useSelector((state) => state.user.uid);
    const [iglesias, setIglesias] = useState([]);
    const [iglesia, setIglesia] = useState('');
    const [nombreIglesia, setNombreIglesia] = useState('');
    const [cultos, setCultos] = useState([]);
    const [miembro, setMiembro] = useState({});
    
    
    
    const dispatch = useDispatch()
    
    useEffect(()=>{
        if(user_uid){
            props.firebase.doRefDoc('user',user_uid).get()
            .then((querySnapshot)=>{
                let idIglesia = querySnapshot.data().iglesia;
                if(idIglesia !== null){
                    dispatch(cargarIglesia({iglesia: idIglesia}));
                    consultarCultos(idIglesia);
                    consultarIglesia(idIglesia);

                    let queryString = window.location.search;
                    let urlParams = new URLSearchParams(queryString);
                    let idPart
                    if(urlParams.has('idPart')){
                        idPart = urlParams.get('idPart')
                        props.firebase.doRefDoc('user',user_uid).collection('miembros').doc(idPart).get()
                        .then((querySnapshot)=>{          
                            setMiembro({...querySnapshot.data(), id:querySnapshot.id });                 
                        })   
                    }
                }                               
            })  
        }  


        
        


         

    },[user_uid])

    

    const consultarCultos = (iglesia_id) =>{
        
        setIglesia(iglesia_id);
        
        props.firebase.doRefDoc('iglesias',iglesia_id).collection("cultos").orderBy('date', 'desc').get()
        .then(function(querySnapshot) {
            let temp =[];
           
            querySnapshot.forEach(function(doc) {    
                let fechaCulto = TimeConverter(doc.data()['date'].seconds);
                let mostrar = doc.data()['date'].seconds > Date.now()/1000-3600;    
                temp.push({...doc.data(), id: doc.id, fecha:fechaCulto, mostrar: mostrar  });
            });
            setCultos(temp);
        });
    }
    const consultarIglesia = (iglesia_id) =>{
                
        props.firebase.doRefDoc('iglesias',iglesia_id).get()
        .then(function(querySnapshot) {          
           
            setNombreIglesia(querySnapshot.data().CHURCH_NAME)
            
        });
    }


    const reservar = (culto_id) => {  

        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let idPart
        if(urlParams.has('idPart')){
            idPart = urlParams.get('idPart')
             
        }
        
        
        props.firebase.doRefDoc('iglesias',iglesia).collection('cultos').doc(culto_id).collection('participantes').doc(idPart).get()
                .then((doc)=> {
                    if (doc.exists) {
                        Swal.fire({                    
                                icon: 'warning',
                                title: 'Ya tiene una reserva para este culto.',
                                showConfirmButton: true,
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#F9B000',
                                })
                        
                        props.history.push('/reservaCultos?idPart='+miembro.id);
                    } else{
                        dispatch(cargarIglesia({iglesia: iglesia}));
                        dispatch(cargarCulto({culto:culto_id}));
                        let queryString = window.location.search;
                        let urlParams = new URLSearchParams(queryString);
                        
                        if(urlParams.has('idPart')){
                            let idPart = urlParams.get('idPart')
                            props.history.push('/requisitos?idPart='+idPart);
                        }else{
                            props.history.push('/participantes');
                        }
                        
                    }

                
            })
            .catch(()=>{                   
            })
        
        
        
        
    }
       
    return ( 
        <>            
           <div className="container">
                <div className="row">
                    <div className="col-xl-1"></div>                                            
                    <div className="col-xl-10">                                                              
                        <h1 className="text-center">Cultos Iglesia {nombreIglesia}</h1>
                        <center><Link className="text-warning" to={'/datauser'}>Actualizar Iglesia</Link><br/></center>                         
                    </div>                    
                </div>
                <center>
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 text-center">
                            { cultos.length > 0 ?
                            
                            cultos.map((culto, index) =>{

                                if(culto.mostrar === true){
                                    return (
                                        <div className="row" key={index}>
                                        <table className="table table-grey">
                                            <thead>
                                                <tr className="table-cultos">
                                                
                                                <th scope="col" className='text-white'>Culto</th>
                                                <th scope="col" className='text-white'>Fecha</th>
                                                <th scope="col" className='text-white'>Cupos</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                
                                                <td>{culto.name}</td>
                                                <td>{culto.fecha}</td>
                                                <td>{culto.cupos>0 && <div  className="text-success font-weight-bold" >{culto.cupos+'/'+culto.capacity}</div> || <div  className="text-warning font-weight-bold" >No Disponible</div> }</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3"> 
                                                        <center>
                                                            {culto.capacity > 0 && <button type="button" className="btn btn-success center" onClick={()=>reservar(culto.id)}>Reservar</button>}                                                                
                                                        </center>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                            </table>
                                        </div>
                                    )
                                }else{
                                    return 
                                }                                    
                            }):<div>
                                <p></p>
                                <center>
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </center>
                            </div>
                        } 
                        </div>
                    </div>
                </center>   
            </div>           
        </>  
      );
}

const condition = authUser => authUser !== null;
 
export default withAuthorization(condition)(withAuthentication(Home));
