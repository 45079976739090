import React, {useState} from 'react';
import {useDispatch} from 'react-redux'
import {cargarUsuarioAdmin, cargarIglesia} from '../../actions/authAction';
import '../../assets/css/signin.css';
import logo from "../../assets/img/logo.png";
import logo2 from "../../assets/img/logo2.png";
import { withFirebase } from '../context/firebase';
import {Link} from "react-router-dom";
import {withAuthentication} from "../context/session";
import * as ROUTES from '../constants/routes';
function Admin (props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorlogin, setErrorLogin] = useState(null);    
    const [user, setUser] = useState(props.authUser);
    const [showlogin, setShowlogin] = useState(true); 
    
    const login = async(e) => {
      e.preventDefault();
        try {
            let authUser = await props.firebase.doSignInWithEmailAndPassword(email, password);  
            setUser(authUser);          
            props.firebase.doRefDoc('user',authUser.user.uid).get()
            .then((querySnapshot)=>{              
                let superadmin = querySnapshot.data().superadmin;
               
                if(superadmin){
                  setShowlogin(false)
                } else{
                  props.firebase.doSignOut()
                  props.history.push("/");
                }                                          
            })            
        }
        catch(Error){
            setErrorLogin(Error)            
        }     
    }

    

    return ( 
        <>  {showlogin && 
              <center>
                <div className="text-center">       
                  <form className="form-signin" onSubmit={login}>
                    <img className="mb-4" src={logo} alt="" width="140" height="140"/>
                    
                    <h1 className="h3 mb-3 font-weight-normal text-dark">Iniciar Sesión Super Admin</h1>
                    <label htmlFor="inputEmail" className="h6 mb-1 font-weight-normal text-dark">Correo electronico</label>
                    <input type="email" id="inputEmail" className="form-control text-dark" onChange= {e => setEmail(e.target.value)} placeholder="Correo" required autoFocus/>
                    <h3 className="h6 mb-1 font-weight-normal text-dark"></h3>
                    <label htmlFor="inputPassword" className="h6 mb-1 font-weight-normal text-dark ">Contraseña</label>
                    <input type="password" id="inputPassword" className="form-control text-dark" onChange= {e => setPassword(e.target.value)} placeholder="Contraseña" required/>
                    <label htmlFor="inputConfirmPassword" className="sr-only">Confirm Password</label>
                      {errorlogin &&  <label  className="warnning">{errorlogin.message}</label> }
                    <button className="btn btn-lg btn-default btn-block" type="submit">Iniciar</button>
                    <Link class="text-warning" to={ROUTES.PASSWORD_FORGET}>Restaurar contraseña</Link><br/>
                    <Link class="text-warning" to={ROUTES.SIGN_IN}>Usuario</Link>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>              
                    <img className="mb-4" src={logo2} alt="" width="150" height="50"/>
                  </form>
                </div>
              </center>   
            }
            {!showlogin &&

              <div>PRUEBA YEISON</div>

            }         
        </>  
      );
}

export default withAuthentication(withFirebase(Admin));