import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import { withAuthorization } from '../context/session';
import {withAuthentication} from '../context/session';
import Swal from "sweetalert2";
import "../../assets/css/sra.css";

const Test = (props)=>{

    const [result, setResult] = useState("")
    const [idUsuario, setIdUsuario] = useState("")
    const [idIglesia, setIdIglesia] = useState("")
    const [idCulto, setIdCulto] = useState("")

    const [temperatura, setTemperatura] = useState("")
    const [sintomas, setSintomas] = useState("")
    
    const handleScan = data => {
        if (data) {
            setResult(data) 
            let miembroId = data.split(':')[0].substring(4)   
            let cultoId = data.split(':')[2].substring(4)
            let iglesiaId = data.split(':')[1].substring(4)

            setIdUsuario(miembroId);
            setIdIglesia(iglesiaId);
            setIdCulto(cultoId);            
            
            Swal.mixin({
                    input: 'text',
                    confirmButtonText: 'Siguiente',
                    showCancelButton: true,
                    progressSteps: ['1', '2']
                }).queue([
                {
                    title: 'Temperatura',
                    text: 'Escriba la temperatura',
                    input: 'text',
                },
                {
                    title: '¿Tiene síntomas?',
                    text: 'Seleccione',
                    input: 'radio',
                    inputOptions: {'si': "Si", "no": "No"}
                },
                
                ]).then((result) => {
                    let answers = result.value
                    if(Array.isArray(answers)){
                        if (answers[0]!=='' && answers[1]!=='') {
                            setTemperatura(answers[0])
                            setSintomas(answers[1])
                            if(answers[1]=== 'no' && parseFloat(answers[0])< 37.5){                            

                                props.firebase.doRefDoc('iglesias',iglesiaId).collection('cultos').doc(cultoId).get()
                                .then(async(doc)=>{
                                    let asiento = doc.data().asientos                                    

                                    let dataTemp1 = await props.firebase.doRefDoc('iglesias',iglesiaId).collection('cultos').doc(cultoId).collection('participantes').doc(miembroId).update({
                                        asistencia:true,
                                        temperatura: answers[0],
                                        sintomas: answers[1],
                                        asiento:asiento
                                    }).then(async()=>{

                                        let dataTemp2 = await props.firebase.doRefDoc('iglesias',iglesiaId).collection('cultos').doc(cultoId).update({asientos: asiento+1})
                                        Swal.fire(
                                            'Ingreso exitoso',
                                            'Su número de puesto es el: '+asiento,
                                            'success'
                                        )
                                                                                        
                                    }).catch(()=>{
                                        Swal.fire(
                                            'No existe reserva',
                                            '',
                                            'error'
                                        )
                                    })
                                })  
                            } else {                                
                                Swal.fire(
                                    'No cumple normativas para ingresar',
                                    '',
                                    'danger'
                                )
                            }
                        }
                    }
                })
            
            setResult("")
            setIdUsuario("")
            setIdIglesia("")
            setIdCulto("")
        }     
    }

    

    const handleError = err => {
    console.error(err)
    }
    return (
        <div>
        <center>
          <QrReader className="qr-image-wrapper"
            delay={1000}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '95%' }}
          />
          <p>{idUsuario}</p>
          <p>{idIglesia}</p>
          <p>{idCulto}</p>
          <p>{result}</p>
          </center>
        </div>
        
      )
    }

    const condition = authUser => authUser !== null;

export default withAuthorization(condition)(withAuthentication(Test));