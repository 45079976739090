
import React from 'react';
import QRCode from "qrcode.react";
import html2canvas from 'html2canvas';
import {Modal,Button} from "react-bootstrap"
import logo from "../../assets/img/logo.png";

const GenerateQr = (props)=>{
    const image = () => {
        window.scrollTo(0,0) 
        html2canvas( document.getElementById('figuras')).then(function(canvas){
            let pngUrl = canvas.toDataURL("image/jpeg")
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download =props.data.nameculto+"-"+props.data.namemiembro;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });        
    }

    return(
    <Modal show={props.showQr} onHide={()=>props.setShowQr(!props.showQr)} class="modal-sm">
        <Modal.Header closeButton>
            <Modal.Title>
                    Descargue su pase de entrada
            </Modal.Title>                    
        </Modal.Header>
        <Modal.Body id="figuras" >
            <center class="modal-body">
                <img className="mb-4" src={logo} alt="" width="60" height="60"/>
                <h1>Reserva</h1>
                <div>FECHA: {props.data.fechaculto}</div>
                <div>IGLESIA: {props.data.nameiglesia}</div>                       
                <div>CULTO: {props.data.nameculto}</div>
                <div>MIEMBRO: {props.data.namemiembro}</div>
                <QRCode                       
                    value={"idu="+props.data.idmiembro+":idi="+ props.data.idiglesia +":idc="+ props.data.idculto}
                    size={200}
                    level={"H"}
                    includeMargin={true}
                />                        
                <p></p>
            </center>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={()=> image()}>Descargar</Button>
            <Button variant="primary" onClick={()=>props.salir()}>Terminar</Button>
        </Modal.Footer>
    </Modal>
    )
}
export default GenerateQr;
