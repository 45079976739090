import React, {useState} from 'react';
import { withFirebase } from '../context/firebase';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/signin.css';
import logo from "../../assets/img/logo.png";
import logo2 from "../../assets/img/logo2.png";
import * as ROUTES from '../constants/routes';

function ChangePassword (props) {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorCreate, setErrorCreate] = useState(null);
      
    const submit = async (e) =>{
        e.preventDefault();
        try{
          if(password === confirmPassword && password !== "" ){
            await  props.firebase.doPasswordUpdate(password)
            alert("CONTRASEÑA MODIFICADA");
            props.history.push("/login");
          }
          else setErrorCreate("No coinciden las contraseñas");
        }
        catch(Error){
          setErrorCreate(Error);
        }
      };    

    return ( 
        <>
            <center>
              <div className="text-center">       
                <form className="form-signin" onSubmit={submit}>
                  <img className="mb-4" src={logo} alt="" width="140" height="140"/>
                  <h1 className="h3 mb-3 font-weight-normal text-dark">Cambiar contraseña</h1>  
                  <h3 className="h6 mb-1 font-weight-normal text-dark">Nueva contraseña</h3>     
                  <label htmlFor="inputPassword" className="sr-only  ">Contraseña</label>
                  <input type="password" id="inputPassword" className="form-control bg-white text-dark" onChange= {e => setPassword(e.target.value)} placeholder="Password" required/>
                  <h3 className="h6 mb-1 font-weight-normal text-dark">Confirma la contraseña</h3> 
                  <label htmlFor="inputConfirmPassword" className="sr-only  text-white">Confirm Password</label>
                  <input type="password" id="inputConfirmPassword" className="form-control bg-white text-dark" onChange= {e => setConfirmPassword(e.target.value)} placeholder="Password" required/>
                  {errorCreate && <label className="">{errorCreate.message}</label>}
                  <button className="btn btn-lg btn-default btn-block" type="submit">Cambiar</button>
                  <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
                  <img className="mb-4" src={logo2} alt="" width="150" height="50"/>
                </form>
              </div>
            </center>
        </>  
      );
}

export default withFirebase(ChangePassword);