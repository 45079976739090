import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import { withAuthorization } from '../context/session';
import {withAuthentication} from '../context/session';
import "../../assets/css/sra.css";
import Swal from "sweetalert2";
import {Modal,Button} from "react-bootstrap"
import ReactExport from "react-export-excel";
import {TimeConverter} from "../constants/funciones";
import { isArray } from 'jquery';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Home (props) {
   
    const [nameCulto, setNameCulto] = useState('');
    const [fechaCulto, setFechaCulto] = useState('');
    const [horaCulto, setHoraCulto] = useState('');
    const [capacidadCulto, setCapacidadCulto] = useState('');
    const [cultos, setCultos] = useState([]);
    const [participantes, setParticipantes] = useState([]);   
    const [participantesExcel, setParticipantesExcel] = useState([]);   
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);   
    const [cultoIden, setCultoIden] = useState("")    
    const [cultoExcel, setCultoExcel] = useState("")
    const [iglesias, setIglesias] = useState([])
    const [iglesia, setIglesia] = useState('')
    const [showCrearCulto, setShowCrearCulto] = useState(null)
    const [showEliminarCulto, setShowEliminarCulto] = useState(null)
    const [showDescargaExcel, setShowDescargaExcel] = useState(null)
    const [showCrearAsistente, setShowCrearAsistente] = useState(false)
    const [asistentes, setAsistentes] = useState([])
    const [inputNuevoAsistente, setInputNuevoAsistente] = useState('')

    const iglesiaAsistente = useSelector((state) => state.user.iglesiaAsistente);  
    const user_role = useSelector((state) => state.user.role);
    const user_distrito = useSelector((state) => state.user.distrito);
    const user_uid = useSelector((state) => state.user.uid);

    /* función para toma de datos entrada */
    const handleScan = (participante, cultoIden) => {
        setShow2(!show2)

        Swal.mixin({
                input: 'text',
                confirmButtonText: 'Siguiente',
                showCancelButton: true,
                progressSteps: ['1', '2']
            }).queue([
            {
                title: 'Temperatura',
                text: 'Escriba la temperatura',
                input: 'text',
            },
            {
                title: '¿Tiene síntomas?',
                text: 'Seleccione',
                input: 'radio',
                inputOptions: {'si': "Si", "no": "No"}
            },
            
            ]).then((result) => {
                let answers = result.value
                if(Array.isArray(answers)){
                    if (answers[0]!=='' && answers[1]!=='') {                   
                        if(answers[1]=== 'no' && parseFloat(answers[0])< 37.5){
                            props.firebase.doRefDoc('iglesias',iglesia).collection('cultos').doc(cultoIden).get()
                                .then((doc)=>{
                                    let asiento = doc.data().asientos                                    

                                    props.firebase.doRefDoc('iglesias',iglesia).collection('cultos').doc(cultoIden).collection('participantes').doc(participante).update({
                                        asistencia:true,
                                        temperatura: answers[0],
                                        sintomas: answers[1],
                                        asiento:asiento
                                    }).then(()=>{

                                        props.firebase.doRefDoc('iglesias',iglesia).collection('cultos').doc(cultoIden).update({asientos: asiento+1})
                                        Swal.fire(
                                            'Ingreso exitoso',
                                            'Su número de puesto es el: '+asiento,
                                            'success'
                                        )
                                                                                        
                                    }).catch(()=>{
                                        Swal.fire(
                                            'No existe reserva',
                                            '',
                                            'error'
                                        )
                                    })
                                })  
                        } else {
                            
                            Swal.fire(
                                'No cumple normativas para ingresar',
                                '',
                                'danger'
                            )
                        }
                    }
                }
            })
    }

    /* función para seleccionar la iglesia para el rol Admin */
    const seleccionarIglesia = (value) =>{
        setIglesia(value);
        consultarCultos(value);
    }
  
    /* función para consultar los cultos de una iglesia */
    const consultarCultos = (idIglesia)=>{

        Date.prototype.addHours= function(h){
            this.setHours(this.getHours()+h);
            return this;
        }
    
        if(user_role === 'asistente'){
            
            if (idIglesia==510) {
                let fecha = new Date().addHours(-4)
                props.firebase.doRefDoc('iglesias',idIglesia).collection('cultos').where('date','>=', fecha).orderBy('date', 'desc').get()
                .then(function(querySnapshot) {
                    let temp =[];
                    querySnapshot.forEach(function(doc) {               
                        temp.push({...doc.data(), id: doc.id, fecha:TimeConverter(doc.data()['date'].seconds), idIglesia: idIglesia });
                    });
                    setCultos(temp);
                });
            }else{
                let fecha = new Date().addHours(-1)
                props.firebase.doRefDoc('iglesias',idIglesia).collection('cultos').where('date','>=', fecha).orderBy('date', 'desc').get()
                .then(function(querySnapshot) {
                    let temp =[];
                    querySnapshot.forEach(function(doc) {               
                        temp.push({...doc.data(), id: doc.id, fecha:TimeConverter(doc.data()['date'].seconds), idIglesia: idIglesia });
                    });
                    setCultos(temp);
                });
            }
            
        }else{
            props.firebase.doRefDoc('iglesias',idIglesia).collection('cultos').orderBy('date', 'desc').get()
            .then(function(querySnapshot) {
                let temp =[];
                querySnapshot.forEach(function(doc) {               
                    temp.push({...doc.data(), id: doc.id, fecha:TimeConverter(doc.data()['date'].seconds), idIglesia: idIglesia });
                });
                setCultos(temp);
            });
        }
       
    }

    /* Consulta las iglesias de un distrito para el rol Admin */
    useEffect(()=>{
        
       props.firebase.doRefCollection('iglesias').where("DISTRICT_ID","==",user_distrito).get()
        .then(
            (querySnapshot) => {               
                let data=[];
               querySnapshot.forEach((doc) => {
                    data.push({...doc.data(), id:doc.id});
               });
               setIglesias(data);               
            }
        );
    },[user_distrito])

    /* funcion para activar los botnes de acciones dependiendo del rol */
    useEffect(()=>{
        if(user_role === 'asistente'){
            setShowCrearCulto(false);
            setShowEliminarCulto(false)
            setShowDescargaExcel(false)            
        }
        if(user_role === 'superadmin'){
            setShowCrearCulto(true);
            setShowEliminarCulto(true)
            setShowDescargaExcel(true)
        }
        if(user_role === 'admin'){
            setShowCrearCulto(true);
            setShowEliminarCulto(true)
            setShowDescargaExcel(true)
        }
        if(user_role === 'usuario'){
            setShowCrearCulto(false);
            setShowEliminarCulto(false)
            setShowDescargaExcel(false)
            props.history.push("/");
        }
    },[user_role])

    useEffect(()=>{
        if(iglesiaAsistente !== null && iglesiaAsistente !== '' && user_role === 'asistente'){
            seleccionarIglesia(iglesiaAsistente)
        }
        
    },[iglesiaAsistente])
    
    useEffect(()=>{
        if(iglesia !== '')
        consultarAsistentes(iglesia)
    },[iglesia])

    function validarFecha(fecha) {  
      
        try{        
            var fecha = fecha.split("-");        
            var dia = fecha[2];        
            var mes = fecha[1];        
            var ano = fecha[0];        
            var estado = true;  
            let dmax,mesC,diaC,anoC;
             
            if ((dia.length === 2) && (mes.length === 2) && (ano.length === 4)) {        
                switch (parseInt(mes)) {        
                    case 1:dmax = 31;break;        
                    case 2: if (ano % 4 === 0) dmax = 29; else dmax = 28;        
                    break;        
                    case 3:dmax = 31;break;        
                    case 4:dmax = 30;break;        
                    case 5:dmax = 31;break;        
                    case 6:dmax = 30;break;        
                    case 7:dmax = 31;break;        
                    case 8:dmax = 31;break;        
                    case 9:dmax = 30;break;        
                    case 10:dmax = 31;break;       
                    case 11:dmax = 30;break;      
                    case 12:dmax = 31;break;       
                }  
                  
                dmax = dmax!="" ? dmax : dmax=-1;
                if ((dia >= 1) && (dia <= dmax) && (mes >= 1) && (mes <= 12)) {        
                    for (var i = 0; i < fecha[2].length; i++) {         
                        diaC = fecha[2].charAt(i).charCodeAt(0);        
                        estado = (!((diaC > 47) && (diaC < 58)))?false:'';       
                        mesC = fecha[1].charAt(i).charCodeAt(0);        
                        estado = (!((mesC > 47) && (mesC < 58)))? false:'';       
                    }  
             
                }  
                
              for (var i = 0; i < fecha[0].length; i++) {               
                    anoC = fecha[0].charAt(i).charCodeAt(0);                      
                    estado = (!((anoC > 47) && (anoC < 58)))? false:'';        
                }
                
            } else estado = false;        
            
            return estado;    
            
        }catch(err){  
            alert("Error fechas");    
        }
    }

    const crearCulto = async() =>{
      
        if(validarFecha(fechaCulto)===false){
            Swal.fire({
                icon: 'info',
                title: 'El formato fecha está mal',
                confirmButtonColor: '#F9B000',
                
            })
            return
        }
        if(nameCulto === '' || fechaCulto === '' || horaCulto === '' || capacidadCulto === ''){
            Swal.fire({
                icon: 'error',
                title: 'Debe Ingresar todos los campos',
                confirmButtonColor: '#F9B000',
                
            })
            return
        }
        setShow(!show)        
        let dataTemp1 = await props.firebase.doRefDoc('iglesias',iglesia).collection('cultos').add({
            name: nameCulto,
            date: new Date(fechaCulto+" "+horaCulto),
            capacity: capacidadCulto,
            cupos: capacidadCulto,
            asientos: 1
        })
        

        Swal.fire({
            icon: 'success',
            title: 'Culto creado con exito.',
            confirmButtonColor: '#F9B000',
            
          })
            
          consultarCultos(iglesia)
    }
    
    const eliminarCulto = (idIglesia,idCulto, cultoCupos, cultoCapacity)=>{

        

        if (cultoCupos == cultoCapacity) {

            Swal.fire({
                title: '¿Está seguro de eliminar?',
                text: "Recuerde que al eliminar las reservas tambien son eliminadas",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar'
              }).then((result) => {
                if (result.isConfirmed) {
                    props.firebase.doRefDoc('iglesias',idIglesia).collection('cultos').doc(idCulto).delete()
                    .then(()=>{
                        Swal.fire(
                            'Eliminado!',
                            'El Culto ha sido eliminado',
                            'success'
                          )  
                        
                        consultarCultos(iglesia)
                    }).catch(()=>{console.log("No está registrado")})             
                }
              })
            
        } else {

            
            Swal.fire({
                title: 'Atención',
                text: "Ya hay reservas para este culto, si va a eliminar, contacte a las personas ya registradas.",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar'
              }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: '¿Está seguro de eliminar?',
                        text: "Recuerde que al eliminar las reservas tambien son eliminadas.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, eliminar'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            props.firebase.doRefDoc('iglesias',idIglesia).collection('cultos').doc(idCulto).delete()
                            .then(()=>{
                                Swal.fire(
                                    'Eliminado!',
                                    'El Culto ha sido eliminado',
                                    'success'
                                  )  
                                
                                consultarCultos(iglesia)
                            }).catch(()=>{console.log("No está registrado")})             
                        }
                      })            
                }
              })

              
        }

        
        
        
       
    }

    const registarAsistencia = (idIglesia, idCulto, nameCulto, fechaCulto)=>{
        props.firebase.doRefDoc('iglesias',idIglesia).collection('cultos').doc(idCulto).collection('participantes').orderBy("miembro.nombres", "asc").get()
            .then(function(querySnapshot) {
                let temp =[];
                let temp2 =[];
                querySnapshot.forEach(function(doc) {               
                    temp.push({...doc.data(), id: doc.id, ...doc.data()['user'],  });
                    temp2.push({...doc.data(), id: doc.id, ...doc.data()['miembro'],  });
                });
                setParticipantes(temp);
                setParticipantesExcel(temp2);
            });
        setCultoIden(idCulto);
        setCultoExcel(nameCulto + "-" + fechaCulto);
        setShow2(!show2)
    }
    
    const consultarAsistentes = (idIglesia)=>{
        props.firebase.doRefDoc('iglesias',idIglesia).collection('asistentes').get()
            .then(function(querySnapshot) {
                
               
                let tempAsistentes = []
                querySnapshot.forEach((asistente)=>{                  
                    props.firebase.doRefDoc('user',asistente.id).get()
                    .then((doc)=>{     
                        
                        tempAsistentes.push({...doc.data(), id:doc.id})
                    })

                })
                
                setAsistentes(tempAsistentes);         
            });
    }
    const verAsistentes = (idIglesia)=>{
       /*  if(user_role === 'admin'){
            consultarAsistentes(idIglesia)
        } */
        setShowCrearAsistente(true);
    }
    
    const nuevoAsistente = async()=>{       
        
        await props.firebase.doRefCollection('user').where("email","==",inputNuevoAsistente).get()
        .then(async(querySnapshot)=>{
                let data = [];                 
                querySnapshot.forEach((doc) => {
                    data.push({...doc.data(), id:doc.id});
               });                     
                
               if(data.length > 0 ){
                   if( user_uid === data[0].id ){
                    alert("Usted no tiene permisos para modificar su ROl")
                   }else{
                    let dataTemp1 = await props.firebase.doRefDoc('iglesias',iglesia).collection('asistentes').doc(data[0].id).set({email: inputNuevoAsistente })
                    let dataTemp2 = await props.firebase.doRefDoc('user',data[0].id).update({iglesiaAsistente: iglesia, role: 'asistente', distrito: user_distrito})
                    let tempArray = [...asistentes]
                    tempArray.push({id:data[0].id,email: inputNuevoAsistente})
                    setAsistentes(tempArray) 
                    setInputNuevoAsistente('')
                   }
                    
               }else{
                   alert("no exite este usuario")
               }                
            })
        
    }
    
    const eliminarAsistente = async (idAsistente)=>{      
        let dataTemp1 = await props.firebase.doRefDoc('iglesias',iglesia).collection('asistentes').doc(idAsistente).delete()
        let dataTemp2 = await props.firebase.doRefDoc('user',idAsistente).update({iglesiaAsistente: '', role: 'usuario'})
        let tempArray = [...asistentes] 
        setAsistentes(tempArray.filter(element => element.id !== idAsistente )) 
    }

    return ( 
        <>  
            {user_role === 'admin' && 
                <div className="form-select text-dark" type="iglesia">
                    <label><b>Seleccione una iglesia:</b></label>                            
                    <select className="form-control"  name="iglesia" onChange={(e) =>seleccionarIglesia(e.target.value)}>
                        <option >Seleccione ...</option>
                    {iglesias.length > 0 ?
                    iglesias.map((iglesia,index) => <option value={iglesia.CHURCH_ID} key={index}>{iglesia.CHURCH_NAME}</option> ):<div>
                        <p></p>
                    <center>
                        <div class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </center>
                </div>} 

                        

                    </select>
                </div>
            }
            {iglesia && <>
                <center>
                    <button type="button" className="btn btn-lg btn-default" style={{"backgroundColor": "#F9B000"}} onClick={()=>props.history.push("/asistencia")}>Registrar Asistencia con QR</button>                                                                
                </center>
                <p></p>   
                {showCrearCulto && 
                    <center>
                        <button type="button" className="btn btn-lg btn-default" style={{"backgroundColor": "#F9B000"}} onClick={()=>setShow(!show)} >Crear Culto</button>  {' '}                                                              
                        <button type="button" className="btn btn-lg btn-default" style={{"backgroundColor": "#F9B000"}} onClick={()=>verAsistentes(iglesia)} >Asignar Colaborador</button>                                                                
                    </center>  
                }                    
                <p></p>    
                <center>
                    <div className="row">
                          <div className="col-1"></div>
                          <div className="col-10 text-center">
                              {cultos.length > 0 ?
                              cultos.map((culto, index) => 
                                  <div className="row" key={index}>
                                    <table className="table table-grey">
                                      <thead>
                                          <tr className="table-cultos">
                                          
                                          <th scope="col" className='text-white'>Culto</th>
                                          <th scope="col" className='text-white'>Fecha</th>
                                          <th scope="col" className='text-white'>Cupos</th>
                                          <th scope="col" className='text-white'>Acciones</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                          
                                          <td>{culto.name}</td>
                                          <td>{culto.fecha}</td>
                                          <td>{culto.cupos>0 && <div  className="text-success font-weight-bold" >{culto.cupos+'/'+culto.capacity}</div> || <div  className="text-warning font-weight-bold" >No Disponible</div> }</td>
                                          <td>
                                                {showEliminarCulto  &&                                                  
                                                    <button type="button" className="btn btn-danger center" onClick={()=>eliminarCulto(culto.idIglesia,culto.id, culto.cupos, culto.capacity)}>Eliminar</button>
                                                }                                              
                                              <p></p>
                                              <button type="button" className="btn btn-primary center" onClick={()=>registarAsistencia(culto.idIglesia,culto.id, culto.name, culto.fecha)}>Ver</button>
                                              <p></p>
                                             
                                          </td>
                                          </tr>                                        
                                      </tbody>
                                      </table>
                                      <Modal show={show2} onHide={()=>setShow2(!show2)}>
                                          <Modal.Header closeButton>
                                            <Modal.Title>Asistencia - {participantes.length} miembros</Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                              <center>
                                                  <div className="row">
                                                  {showDescargaExcel && (
                                                      <div className="col">
                                                      <div className="Descargar">                                                            
                                                          <ExcelFile element={<span><center><button type="button" className="btn btn-success">Descargar Excel</button></center></span>} filename={cultoExcel}>
                                                              <ExcelSheet data={participantesExcel} name="Participantes">                                                                
                                                                  <ExcelColumn bold="true" label="Nombre" value="nombres"></ExcelColumn>
                                                                  <ExcelColumn label="Apellido" value="apellidos"></ExcelColumn>
                                                                  <ExcelColumn label="Tipo de Documento" value="tipoDocumento"></ExcelColumn>
                                                                  <ExcelColumn label="Documento" value="documento"></ExcelColumn>
                                                                {/*   <ExcelColumn label="Edad" value="edad"></ExcelColumn> */}
                                                                  <ExcelColumn label="Teléfono" value="telefono"></ExcelColumn>
                                                                  <ExcelColumn label="Síntomas" value="sintomas"></ExcelColumn>
                                                                  <ExcelColumn label="Temperatura" value="temperatura"></ExcelColumn>
                                                                  <ExcelColumn label="Asistencia" value="asistencia"></ExcelColumn>
                                                                  <ExcelColumn label="Asiento" value="asiento"></ExcelColumn>
                                                              </ExcelSheet>
                                                          </ExcelFile>
                                                      </div>
                                                      </div>
                                                      
                                                      
                                                  )}  
                                                  <div><p></p></div>                              
                                                      <div className="col-12 text-center">
                                                      <table className="table table-grey">
                                                          <thead>
                                                              <tr className="table-cultos">                                                
                                                                  <th scope="col" className='text-white'>Nombre</th>                                                                 
                                                                  <th scope="col" className='text-white'>Asistencia</th>
                                                              </tr>
                                                          </thead>
                                                          {participantes.map((participante, index) => 
                                                              <tbody>
                                                                  <tr>                                                
                                                                  <td>{participante.miembro.nombres + " " + participante.miembro.apellidos}</td>                                                                 
                                                                  <td>{participante.asistencia? '✔️':<button type="button" className="btn btn-transparent center" onClick={()=>handleScan(participante.miembro.documento, cultoIden)}>✏️</button>}</td>                                                
                                                                  </tr>                                        
                                                              </tbody>       
                                                          )}
                                                          </table>
                                                          
                                                      </div>
                                                  </div>
                                              </center>
                                          </Modal.Body>
                                          <Modal.Footer>
                                          <Button variant="primary" onClick={()=>setShow2(!show2)}>
                                              Salir
                                          </Button>                     
                                          </Modal.Footer>
                                      </Modal>         
                                  </div>                              
                              ): 

                              <div>
                                  <center>
                                      <div class="spinner-border text-warning" role="status">
                                          <span class="sr-only">Loading...</span>
                                      </div>
                                  </center>
                              </div>}

                          </div>
                      </div>
                </center>
                </>
            }
          
            <Modal show={show} onHide={()=>setShow(!show)}>
                <Modal.Header closeButton>
                <Modal.Title>Crear Culto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group text-dark">
                        <label htmlFor="exampleInputEmail1">Nombre:</label>
                        <input type="text" className="form-control  text-dark"  onChange={(e)=>setNameCulto(e.target.value)} name="nombre" placeholder="Nombre" />
                    </div>
                    <div className="form-group text-dark">
                        <label htmlFor="exampleInputEmail1">Fecha:</label>
                        <input type="date" className="form-control  text-dark" placeholder="aaaa-mm-dd" onChange={(e)=>setFechaCulto(e.target.value)} name="fecha" placeholder="Fecha" />
                    </div>
                    <div className="form-group text-dark">
                        <label htmlFor="exampleInputEmail1">Hora:</label>
                        <input type="time" className="form-control  text-dark" placeholder="--:-- ----"  onChange={(e)=>setHoraCulto(e.target.value)} name="hora" placeholder="Hora" />
                    </div>                        
                    <div className="form-group text-dark">
                        <label htmlFor="exampleInputEmail1">Capacidad:</label>
                        <input type="number" className="form-control  text-dark"  onChange={(e)=>setCapacidadCulto(e.target.value)} name="capacidad" placeholder="Capacidad" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShow(!show)}>
                    Salir
                </Button>
                <Button variant="primary" onClick={crearCulto}>
                    Guardar
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCrearAsistente} onHide={()=>setShowCrearAsistente(!showCrearAsistente)}>
                <Modal.Header closeButton>
                <Modal.Title>Asignar Personal de Registro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Correo:</label>
                                <input type="email" className="form-control  text-dark"  name="nombre" placeholder="Correo Usuario" onChange={e=>setInputNuevoAsistente(e.target.value)}/>
                            </div>
                            <center>
                                <button type='button' onClick={nuevoAsistente} className="btn btn-lg btn-default" style={{"backgroundColor": "#F9B000"}}>Agregar</button>
                            </center>
                        </div>
                    </div>
                    <table className="table table-grey">
                        <thead>
                            <tr className="table-cultos">                                
                                <th scope="col" className='text-white'>Usuario</th>    
                                <th scope="col" className='text-white'></th>                                
                            </tr>
                        </thead>
                        <tbody>
                        {asistentes.map((asistente)=>
                            <tr key={asistente.id}>                                
                                <td>{asistente.email}</td>
                                <td><button type="button" class="btn btn-link text-danger" style={{"font-size": "16px"}} onClick={()=>eliminarAsistente(asistente.id)}>X</button></td>
                                {/* <td><pan className="text-danger" onClick={()=>eliminarAsistente(asistente.id)}>X</pan></td>   */}                             
                            </tr>
                            )}                                        
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowCrearAsistente(!showCrearAsistente)}>
                    Salir
                </Button>
                </Modal.Footer>
            </Modal>        
        </>  
      );
}

const condition = authUser => authUser !== null;
 
export default withAuthorization(condition)(withAuthentication(Home));
