import {USER_LOADED, WORSHIP_LOADED,CHURCH_LOADED, ADMIN_LOADED} from '../types';


const initialState = {
    uid: null,
    iglesia: null,
    culto: null,
    role: null,
    distrito: null,
    iglesiaAsistente:null
}

export default function(state = initialState, action){
    
    switch(action.type){
        case USER_LOADED:
            return{
                ...state,
                uid: action.payload.uid               
            }
        case CHURCH_LOADED:
            return{
                ...state,
                iglesia: action.payload.iglesia               
            }                
        case WORSHIP_LOADED:
            return{
                ...state,
                culto: action.payload.culto               
            }                
        case ADMIN_LOADED:
            return{
                ...state,
                role: action.payload.role,
                distrito: action.payload.distrito,
                iglesiaAsistente: action.payload.iglesiaAsistente,               
            }                
       
        default: 
            return state
    }
}

