import React, {useState} from 'react';
import { withFirebase } from '../context/firebase';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/signin.css';
import logo from "../../assets/img/logo.png";
import logo3 from "../../assets/img/logo3.png";
import Swal from 'sweetalert2'

function Account (props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorCreate, setErrorCreate] = useState(null);
    
    const submit = async (e) =>{
        e.preventDefault();
        try{
          if(password === confirmPassword && password !== "" ){
            let authUser = await  props.firebase.doCreateUserWithEmailAndPassword(email, password);
            let data = await props.firebase.doSendEmailVerification();            
            let data2 = await props.firebase.doRefDoc('user',authUser.user.uid).set({id:authUser.user.uid, role:'usuario', register: false, email:email.toLowerCase()});            
          
            Swal.fire({
              icon: 'success',
              title: 'Registro exitoso',
              text: 'Se ha enviado un correo de validación.',
              confirmButtonColor: '#F9B000'            
            })

            await props.firebase.doSignOut();  
            props.history.push('/signin')         
          }
          else{ 
            Swal.fire({
            icon: 'info',
            title: 'Las contraseñas no coinciden.',
            text: 'Vuelva a escribir su contraseña.',
            confirmButtonColor: '#F9B000',
            
            })
          }
        }
        catch(Error){
          if(Error.code == "auth/email-already-in-use"){
            setErrorCreate({message:"Este email ya ha sido registrado."})  
           
          }else{
            setErrorCreate(Error);
          }          
        }
      };    

    return ( 
        <>
            <center>
              <div className="text-center">       
                <form className="form-signin" onSubmit={submit}>
                  <h1 className="mb-3 font-weight " style={{"color": "#2f557f", "font-size": "26px"}}>Registro Adventista para la Asistencia al Templo</h1>
                  <h1 className="h3 mb-3 font-weight-normal text-dark">Registro</h1>
                  <label htmlFor="inputEmail" className="h6 mb-1 font-weight-normal text-dark">Correo Electrónico</label>
                  <input type="email" id="inputEmail" className="form-control  text-dark" onChange= {e => setEmail(e.target.value)} placeholder="Correo" required autoFocus/>
                  <label htmlFor="inputPassword" className="h6 mb-1 font-weight-normal text-dark ">Contraseña</label>
                  <input type="password" id="inputPassword" className="form-control  text-dark" onChange= {e => setPassword(e.target.value)} placeholder="Contraseña" required/>
                  <label htmlFor="inputConfirmPassword" className="h6 mb-1 font-weight-normal text-dark ">Confirmar Contraseña</label>
                  <input type="password" id="inputConfirmPassword" className="form-control  text-dark" onChange= {e => setConfirmPassword(e.target.value)} placeholder="Contraseña" required/>
                  {errorCreate && <label className="text-danger">{errorCreate.message}</label>}
                  <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} type="submit">Crear usuario</button>
                  <div>
                    <p></p>
                    <p></p>  </div>
                  <img className="mb-4" src={logo3} alt="" width="160" height="100"/>
                  <p className="mt-1 mb-3 text-muted">&copy; 2020</p>
                  
                </form>
              </div>
            </center>
        </>  
      );
}

export default withFirebase(Account);