import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import { withAuthorization } from '../context/session';
import {useDispatch} from 'react-redux'
import {cargarUsuarioAdmin, cargarIglesia} from '../../actions/authAction';
import {withAuthentication} from '../context/session';
import "../../assets/css/sra.css";
import Swal from "sweetalert2";
import {Modal,Button} from "react-bootstrap"
import GenerateQr from "../reserva/generarQr"
import {TimeConverter} from "../constants/funciones";

function Participantes (props) {
   
    const [participantes, setParticipantes] = useState([]);
    const [showNuevoMiembro, setShowNuevoMiembro] = useState(false);
    const [showReservas, setShowReservas] = useState(false);
    const [reservas, setReservas] = useState([]);
    const [showQr, setShowQr] = useState(false);
    const [datosQR, setDatosQr] = useState({});
  
    const user_uid = useSelector((state) => state.user.uid);   
    const iglesia = useSelector((state) => state.user.iglesia);

    useEffect(()=>{
        if(user_uid){            
            props.firebase.doRefDoc('user',user_uid).collection('miembros').get()
            .then((querySnapshot)=>{
                let  temp = [];
                querySnapshot.forEach(function(doc) {    
                    temp.push({...doc.data(), id: doc.id });
                });
                setParticipantes(temp);                                    
            })            
        }   
    },[user_uid])
    
      
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-warning'
        },
        buttonsStyling: false
    })

    const enviar = (e) =>{
        e.preventDefault();
        let nombres = e.target.nombre.value;
        let apellidos = e.target.apellido.value;
        let telefono = e.target.telefono.value;
        let tipoDocumento = e.target.tipo_doc.value;
        let documento = e.target.doc.value;
      /*   let edad = e.target.edad.value; */

        nombres = nombres.toUpperCase();
        apellidos = apellidos.toUpperCase();
        telefono = telefono;
        tipoDocumento = tipoDocumento.toUpperCase();
        documento = documento.trim();
        
        if( nombres === '' || apellidos === '' || telefono === '' || tipoDocumento === 'Seleccione...' || documento === '' || iglesia==='')
            {
                Swal.fire({
                    icon: 'info',
                    title: 'Faltan datos o están mal escritos.',
                    text: 'Le agradecemos llenar todos los datos correctamente.',
                    confirmButtonColor: '#F9B000',
                })
                return;
            }

            swalWithBootstrapButtons.fire({
                title: '¿Acepta los términos y condiciones del uso de sus datos personales?',
                html:'<center>Los datos personales serán tratados por la Iglesia Adventista del Séptimo Día de Colombia ,en conformidad con la política de protección de datos personales de nuestra institución con la finalidad de gestionar reservas para el ingreso a los templos cumpliendo los protocolos de bioseguridad exigidos por el gobierno nacional. El Derecho al Habeas Data podrá ejercerlo a través del siguiente correo electrónico privacidad@iasd.org.co<br/><b><a href="https://www.unioncolombiana.org.co/es/politica-de-cookies/">https://www.unioncolombiana.org.co/es/politica-de-cookies/</a></b></center>',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Si, acepto',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    let dataPerson ={
                        nombres,
                        apellidos,
                        telefono,
                        tipoDocumento,
                        documento,                      
                        usuarioAdmin:user_uid
                    }
                            
                    props.firebase.doRefDoc('user',user_uid).collection('miembros').doc(documento).set(dataPerson)

                    let tempParticipantes = [...participantes]
                    dataPerson['id']=dataPerson.documento
                    tempParticipantes.push(dataPerson)
                    setParticipantes(tempParticipantes)

                    setShowNuevoMiembro(false)
                   
                } else if (
                    
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                    'Cancelado',
                    '',
                    'error'
                    )
                }
            })  
    } 

    const consultarReservas = () =>{
        props.firebase.doRefDoc('user',user_uid).collection('reservas').get()
            .then((querySnapshot)=>{
                let  temp = [];
                querySnapshot.forEach(function(doc) {    
                    temp.push({...doc.data(), id: doc.id });
                });
                setReservas(temp);                                                   
            })    
    }
    const verReservas = () =>{
        consultarReservas()
        setShowReservas(true)
    }

    const cancelarReserva = (data) =>{


        swalWithBootstrapButtons.fire({
                title: '¿Esta seguro de cancelar esta reserva?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    let reservaID = data.id
                    let iglesiaID = data.idiglesia
                    let cultoID = data.idculto
                    let miembroID = data.idmiembro

                    try{
                        props.firebase.doRefDoc('user',user_uid).collection('reservas').doc(reservaID).delete();
                        props.firebase.doRefDoc('iglesias',iglesiaID).collection('cultos').doc(cultoID).collection('participantes').doc(miembroID).delete();
                        props.firebase.doRefDoc('iglesias',iglesiaID).collection('cultos').doc(cultoID).get()
                        .then((querySnapshot)=>{               
                            let cupos = querySnapshot.data().cupos
                            props.firebase.doRefDoc('iglesias',iglesiaID).collection('cultos').doc(cultoID).update({cupos:cupos+1 });                               
                        })   

                        consultarReservas()

                        Swal.fire({
                            icon: 'success',
                            title: 'Reserva cancelada.',
                            confirmButtonColor: '#F9B000',
                            
                        })           

                    }catch(error){
                        console.log(error)
                    }
                }  
            })


        
    }

    const borrarMiembro = async (idParticipante) =>{
        swalWithBootstrapButtons.fire({
            title: '¿Esta seguro de borrar este participante?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                try{
                    props.firebase.doRefDoc('user',user_uid).collection('miembros').doc(idParticipante).delete();
                    props.history.push('/participantes')
                    Swal.fire({
                        icon: 'success',
                        title: 'Participante eliminado',
                        confirmButtonColor: '#F9B000',            
                    })     
                    let tempArray = [...participantes] 
                    setParticipantes(tempArray.filter(element => element.id !== idParticipante ))
                }catch(error){
                    console.log(error)
                }
            }  
        })
    }
    
    const generarQr = (data) =>{
        setDatosQr(data)
        setShowQr(true)        
    }

    const salir = () =>{
        setShowQr(!showQr)
    }

    return ( 
        <>   
            <div className="container">
                <div className="row">
                    <div className="col-xl-1"></div>                                            
                    <div className="col-xl-10">                                                              
                        <h1 className="text-center">Participantes</h1>
                        <center><button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} onClick={()=>setShowNuevoMiembro(true)}>Agregar Participante</button><br/></center>      
                        <center><button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} onClick={()=>verReservas(true)}>Ver Mis Reservas</button></center>                   
                    </div>                    
                </div>
                <div className="form-select text-dark" type="iglesia"> 
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 text-center">                            
                            <table className="table table-grey">
                                <thead>
                                    <tr className="table-cultos">                                        
                                        <th scope="col" className='text-white'>Participante</th>
                                        <th scope="col" className='text-white'>Ver</th>                                           
                                    </tr>
                                </thead>
                                <tbody>
                                {participantes.length > 0 ? 
                                
                                    participantes.map((participante)=>(
                                    <tr key={participante.id}>                                        
                                        <td style={{"font-weight": "bold"}}>{participante.nombres + " " + participante.apellidos}<br></br>
                                        <button type="button" class="btn btn-link text-danger" style={{"font-size": "12px"}} onClick={()=>borrarMiembro(participante.id)}>Eliminar</button>
                                        </td>
                                        <td>
                                            <button className="btn btn-lg btn-default" style={{"backgroundColor": "#F9B000"}} onClick={()=>props.history.push('/reservaCultos?idPart='+ participante.id)}>Cultos</button>                                           
                                        </td>
                                                                                
                                    </tr>      
                                )): 
                                
                                
                                    <tr>
                                        <td>
                                            <div class="spinner-border text-warning" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                }                                  
                                </tbody>
                            </table>                           
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showNuevoMiembro} onHide={()=>setShowNuevoMiembro(!showNuevoMiembro)}>
                <Modal.Header closeButton>
                <Modal.Title>Crear Participante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={(e)=>enviar(e)}>
                            <center><h1  className="font-weight-normal text-dark">Información Personal</h1></center>
                            
                                <div className="form-group text-dark">
                                    <label htmlFor="exampleInputEmail1">Nombre:</label>
                                    <input type="text" className="form-control  text-dark"  name="nombre" placeholder="Nombres" />
                                </div>
                                <div className="form-group text-dark">
                                    <label htmlFor="exampleInputEmail1">Apellido:</label>
                                    <input type="text" className="form-control  text-dark" name="apellido" placeholder="Apellidos" />
                                </div>
                                <div className="form-group text-dark">
                                    <label htmlFor="exampleInputEmail1">Teléfono:</label>
                                    <input type="number" className="form-control  text-dark"  name="telefono" placeholder="Teléfono"/>
                                </div>
                                <div className="form-group text-dark">
                                    <label htmlFor="exampleInputEmail1">Tipo Documento:</label>
                                    <select className="form-control  text-dark" name="tipo_doc" >
                                        <option>Seleccione...</option>
                                        <option value="CC">Cédula de Ciudadanía</option>
                                        <option value="TI">Tarjeta de Identidad</option>
                                        <option value="PP">Pasaporte</option>
                                    </select>                                                                
                                </div>
                                <div className="form-group text-dark">
                                    <label htmlFor="exampleInputEmail1">Documento:</label>
                                    <input type="text" className="form-control  text-dark" name="doc" placeholder="Numero Documento" />                    
                                </div>
                               {/*  <div className="form-group text-dark">
                                    <label htmlFor="exampleInputEmail1">Edad:</label>
                                    <input type="number" className="form-control  text-dark" name="edad" placeholder="Edad" />
                                </div> */}
                                <div className="form-group text-dark">
                                    <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} type="sumbit">Crear</button>
                                </div>                                
                            </form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowNuevoMiembro(!showNuevoMiembro)}>
                    Salir
                </Button>          
                </Modal.Footer>
            </Modal>
            
            <Modal show={showReservas} onHide={()=>setShowReservas(!showReservas)} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Mis Reservas</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <div className="container table-responsive-sm">
                        <table className="table table-grey">
                            <thead>
                                <tr className="table-cultos">                                        
                                    <th scope="col" className='text-white'>Iglesia</th>
                                    <th scope="col" className='text-white'>Culto</th>
                                    <th scope="col" className='text-white'>Fecha</th>
                                    <th scope="col" className='text-white'>Participante</th>
                                    <th scope="col" className='text-white'>Acciones</th>                                           
                                </tr>
                            </thead>
                            <tbody>
                                
                            
                                {reservas.length > 0 ?
                                reservas.map((reserva, index)=>{
                                    let data = {
                                        id:reserva.id,
                                        nameiglesia:reserva.iglesiaReserva.CHURCH_NAME,
                                        nameculto:reserva.cultoReserva.name,
                                        fechaculto:TimeConverter(reserva.cultoReserva['date'].seconds),
                                        namemiembro:reserva.miembro.nombres + " " + reserva.miembro.apellidos,                                        
                                        idiglesia:reserva.iglesiaReserva.CHURCH_ID,
                                        idculto:reserva.cultoReserva.id, 
                                        idmiembro:reserva.miembro.documento
                                    } 

                                    if (reserva.cultoReserva['date'].seconds > Date.now()/1000) {
                                        let fecha = reserva.cultoReserva['date'].seconds
                                        
                                        return(
                                        
                                            <tr key={index}>                                        
                                                <td>{reserva.iglesiaReserva.CHURCH_NAME}</td>
                                                <td>{reserva.cultoReserva.name}</td>
                                                <td>{TimeConverter(reserva.cultoReserva['date'].seconds)}</td>                                            
                                                <td>{reserva.miembro.nombres + " " + reserva.miembro.apellidos}</td>
                                                <td>
                                                    <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} onClick={()=>cancelarReserva(data)}>Cancelar</button>                                           
                                                    <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} onClick={()=>generarQr(data)}>QR</button>                                           
                                                </td>                                        
                                            </tr>      
                                    )
                                    }
                                }):<div>
                                <p></p>
                                <center>
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </center>
                            </div>
                                
                               /*  <div>No tiene reservas activas</div> */
                            }                             
                            </tbody>
                        </table>   
                    </div>                            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowReservas(!showReservas)}>
                    Salir
                </Button>          
                </Modal.Footer>
            </Modal>
        
            {showQr && 
            <GenerateQr 
                data={datosQR} 
                showQr={showQr} 
                setShowQr={setShowQr}
                salir={salir}
                
            />}
        </>  
      );
}

const condition = authUser => authUser !== null;
 
export default withAuthorization(condition)(withAuthentication(Participantes));
