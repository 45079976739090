export const TimeConverter = (UNIX_timestamp)=>{
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12; 
    min = min < 10 ? '0'+min : min;
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ' ' + ampm;
    return time;
}

