import React, {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {cargarUsuarioAdmin, cargarIglesia} from '../../actions/authAction';
import { withFirebase } from '../context/firebase';
import {Link} from "react-router-dom";
import {withAuthentication} from "../context/session";
import * as ROUTES from '../constants/routes';
import logo3 from "../../assets/img/logo3.png";
import '../../assets/css/sra.css';
import Swal from "sweetalert2";
function Admin (props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorlogin, setErrorLogin] = useState(null);

    const dispatch = useDispatch();  
    
    const login = async(e) => {
      e.preventDefault();
        try {
            let authUser = await props.firebase.doSignInWithEmailAndPassword(email, password);  
                      
            props.firebase.doRefDoc('user',authUser.user.uid).get()
            .then((querySnapshot)=>{     

                if(authUser.user.emailVerified === true){
                  let idIglesia = querySnapshot.data().adminIglesia;
                  let role = querySnapshot.data().role;
                  let distrito = querySnapshot.data().distrito;
                  let iglesiaAsistente = querySnapshot.data().iglesiaAsistente;
                  let register = querySnapshot.data().register;
                                   
                  if(register == true){
                    if(idIglesia !== null){
                        dispatch(cargarIglesia({iglesia: idIglesia}));
                        dispatch(cargarUsuarioAdmin({role, distrito, iglesiaAsistente}));
                    } 

                    if(role === 'asistente'){
                      props.history.push("/adminCultos")
                    }else if(role === 'superadmin'){
                      props.history.push("/superadmin")
                    }else if(role === 'admin'){
                      props.history.push("/adminCultos")
                    }else if(role === 'usuario'){
                      props.history.push("/participantes")
                    }else{
                      props.firebase.doSignOut();               
                    }
                    
                  }else{
                    props.history.push('/datauser')
                  }

                }else{
                                    
                  Swal.fire({
                    title: 'Cuenta no verificada',
                    text: "Por favor revise su correo electrónico y valide su cuenta, si no encuentra el mensaje vuelva a generarlo",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Generar Mensaje de Validación'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        'Mensaje Enviado!',
                        'Revise su bandeja de entrada o spam ',
                        'success'
                      )
                      props.firebase.doSendEmailVerification();
                    }
                    props.firebase.doSignOut();
                  })
               
                }
                                           
            })
            
        }
        catch(Error){
          if(Error.code == "auth/wrong-password"){
            setErrorLogin({message:"Contraseña incorrecta, ingrese nuevamente."})               
          } else if (Error.code == "auth/user-not-found"){
            setErrorLogin({message:"Usuario no encontrado, Cree una cuenta para iniciar."})             
          }else if (Error.code == "auth/invalid-email"){
            setErrorLogin({message:"Email inválido."})            
          } else {
            setErrorLogin(Error)             
          }                
        }     
    }

    

    return ( 
        <>
            <center>
              <div className="text-center">       
              
                <form className="form-signin" onSubmit={login}>
                  
                  <h1 className="mb-3 font-weight " style={{"color": "#2f557f", "font-size": "26px"}}>Registro Adventista para la Asistencia al Templo</h1>
                  <h1 className="h3 mb-3 font-weight-normal text-dark">Iniciar Sesión</h1>
                  <div><label htmlFor="inputEmail" className="h6 mb-1 font-weight-normal text-dark">Correo electrónico</label>
                  <input type="email" id="inputEmail" className="form-control text-dark" onChange= {e => setEmail(e.target.value)} placeholder="Correo" required autoFocus/>
                  </div>
                  <h3 className="h6 mb-1 font-weight-normal text-dark"></h3>
                  <label htmlFor="inputPassword" className="h6 mb-1 font-weight-normal text-dark ">Contraseña</label>
                  <input type="password" id="inputPassword" className="form-control text-dark" onChange= {e => setPassword(e.target.value)} placeholder="Contraseña" required/>
                  <label htmlFor="inputConfirmPassword" className="sr-only">Confirm Password</label>
                    {errorlogin &&  <label  className="text-danger">{errorlogin.message}</label> }
                  <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} type="submit">Ingresar</button>
                  <div>
                    <p></p>
                    <p></p>  </div>
                  <div class="row">
                    <div class="col-5">
                      <Link className="text-warning" to={ROUTES.SIGN_UP}>Crear Cuenta</Link><br/>
                    </div>
                    <div class="col-7">
                      <Link className="text-warning" to={ROUTES.PASSWORD_FORGET}>Restaurar contraseña</Link><br/>
                    </div>
                  </div>
                  <div>
                    <p></p>
                    <p></p>  </div>
                  <img className="mt-2 mb-4" src={logo3} alt="" width="160" height="100"/>
                  <p className="mt-1 mb-3 text-muted">&copy; 2020</p>
          
                </form>
              </div>
            </center>            
        </>  
      );
}

export default withAuthentication(withFirebase(Admin));