import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/signin.css';
import { withFirebase } from '../context/firebase';
import Swal from "sweetalert2";
import logo from "../../assets/img/logo.png";
import logo2 from "../../assets/img/logo2.png";

function VerifyEmail (props) {
  const [changePassword, setChangePassword] = useState(false)
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [errorCreate, setErrorCreate] = useState(null);
  const [actionCode, setActionCode] = useState(null);

   useEffect(()=>{
       
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if(urlParams.has('mode') && urlParams.has('oobCode')){
        let mode = urlParams.get('mode')
        let actionCode2 =urlParams.get('oobCode')
        setActionCode(actionCode2)
        switch (mode) {
            case 'resetPassword':            
              // Display reset password handler and UI.              
                setChangePassword(true)
                props.firebase.doverifyPasswordResetCode(actionCode2).then(function(email) {      
                  setEmailUser(email);
                }).catch((Error)=>{
                  if(Error.code == "auth/invalid-action-code"){
                    Swal.fire({
                      icon: 'error',
                      title: 'Esta acción a expirado, vuelva a restaurar contraseña',            
                      confirmButtonColor: '#F9B000',            
                  })
                                 
                  }else {
                    Swal.fire({
                      icon: 'error',
                      title: Error.message,            
                      confirmButtonColor: '#F9B000',            
                  })
                           
                  }
                  props.history.push('/signin')                  
                })    
              break;
            case 'recoverEmail':
              // Display email recovery handler and UI.
              /* handleRecoverEmail(auth, actionCode, lang); */
              break;
            case 'verifyEmail':
              // Display email verification handler and UI.
              handleVerifyEmail(actionCode2);
              break;
            default:
              // Error: invalid mode.
          }
        
    }else{
        Swal.fire({
            icon: 'error',
            title: 'URL equivocada',            
            confirmButtonColor: '#F9B000',            
        })
        props.history.push('/signin')        
    }

   },[])
   
   const handleVerifyEmail = actionCode => {
       props.firebase.dohandleVerifyEmail(actionCode)
       .then((resp)=>{
        Swal.fire({
            icon: 'success',
            title: 'Cuenta validada',
            text: 'Ya puede Iniciar sesión',
            confirmButtonColor: '#F9B000',
            allowOutsideClick: false
            
        })

        props.history.push('/signin')        
       })
       .catch(()=>{
        Swal.fire({
            icon: 'warning',
            title: 'Este enlace ha caducado, vuelva a crear la cuenta',
            text: '',
            confirmButtonColor: '#F9B000',
            
        })
        props.history.push('/signup')  
       })
   }


   function handleResetPassword(e) {    
      e.preventDefault()
      
          if(password=== confirmPassword && password !== "" ){
            props.firebase.doconfirmPasswordReset(actionCode, password).then(function(resp) {
              Swal.fire({
                icon: 'success',
                title: 'Contraseña actualizada',            
                confirmButtonColor: '#F9B000',            
              })
              props.history.push('/signin')
            }).catch(function(error) {             
              setErrorCreate({message:"Ha ocurrido un error, vuelva a intentar el proceso"});                  
            });
          }else{
            setErrorCreate({message:"No coinciden las contraseñas"});            
          }      
         

  }
    return ( 
        <>
        {changePassword && 
           <center>
              <div className="text-center">       
                <form className="form-signin" onSubmit={(e)=>handleResetPassword(e)}>
                  <img className="mb-4" src={logo} alt="" width="140" height="140"/>
                  <h1 className="h3 mb-3 font-weight-normal text-dark">Cambiar contraseña {emailUser}</h1>  
                  <h3 className="h6 mb-1 font-weight-normal text-dark">Nueva contraseña</h3>     
                  <label htmlFor="inputPassword" className="sr-only  ">Contraseña</label>
                  <input type="password" id="inputPassword" className="form-control bg-white text-dark" onChange= {e => setPassword(e.target.value)} placeholder="Password" required/>
                  <h3 className="h6 mb-1 font-weight-normal text-dark">Confirma la contraseña</h3> 
                  <label htmlFor="inputConfirmPassword" className="sr-only  text-white">Confirm Password</label>
                  <input type="password" id="inputConfirmPassword" className="form-control bg-white text-dark" onChange= {e => setConfirmPassword(e.target.value)} placeholder="Password" required/>
                  {errorCreate && <label className="">{errorCreate.message}</label>}
                  <button className="btn btn-lg btn-default btn-block" style={{"backgroundColor": "#F9B000"}} type="submit">Cambiar</button>
                  <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
                  <img className="mb-4" src={logo2} alt="" width="150" height="50"/>
                </form>
              </div>
            </center>
        }
           
        </>  
      );
}

export default withFirebase(VerifyEmail);