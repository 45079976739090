import {ADMIN_LOADED, CHURCH_LOADED, USER_LOADED, WORSHIP_LOADED} from '../types';

export const    cargarUsuario = item => {
    return {
        type: USER_LOADED,
        payload: item
    }
}
export const    cargarIglesia = item => {
    return {
        type: CHURCH_LOADED,
        payload: item
    }
}
export const    cargarCulto = item => {
    return {
        type: WORSHIP_LOADED,
        payload: item
    }
}
export const    cargarUsuarioAdmin = item => {
    return {
        type: ADMIN_LOADED,
        payload: item
    }
}
