import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { withAuthorization } from '../session';
import { withAuthentication } from '../session';

const datos = [
	{
		"DISTRICT_ID": "22",
		"CHURCH_ID": "25",
		"CHURCH_CODE": "CPEAGUAC01",
		"CHURCH_NAME": "CENTRAL AGUACHICA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "22",
		"CHURCH_ID": "26",
		"CHURCH_CODE": "CPEAPOST01",
		"CHURCH_NAME": "LOS APOSTOLES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "22",
		"CHURCH_ID": "27",
		"CHURCH_CODE": "CPEREDEN01",
		"CHURCH_NAME": "REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "22",
		"CHURCH_ID": "28",
		"CHURCH_CODE": "CPEROSAS01",
		"CHURCH_NAME": "ROSA SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "22",
		"CHURCH_ID": "29",
		"CHURCH_CODE": "CPESANMA01",
		"CHURCH_NAME": "SAN MARTIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "31",
		"CHURCH_CODE": "CCIMACED01",
		"CHURCH_NAME": "MACEDONIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "32",
		"CHURCH_CODE": "CCIMAHAN01",
		"CHURCH_NAME": "MAHANAIN ORIHUECA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "33",
		"CHURCH_CODE": "CCIMARAN01",
		"CHURCH_NAME": "MARANATHA SEVILLA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "34",
		"CHURCH_CODE": "CCIORIHU01",
		"CHURCH_NAME": "ORIHUECA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "35",
		"CHURCH_CODE": "CCIVAREL01",
		"CHURCH_NAME": "EMANUEL VARELA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "36",
		"CHURCH_CODE": "CFUARACA01",
		"CHURCH_NAME": "ARACATACA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "37",
		"CHURCH_CODE": "CFURENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "38",
		"CHURCH_CODE": "CFURETEN01",
		"CHURCH_NAME": "RETEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "39",
		"CHURCH_CODE": "CBOASTRE01",
		"CHURCH_NAME": "ASTREA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "40",
		"CHURCH_CODE": "CBOBETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "41",
		"CHURCH_CODE": "CBOCENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "42",
		"CHURCH_CODE": "CBOLALOM01",
		"CHURCH_NAME": "LA LOMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "43",
		"CHURCH_CODE": "CBOPENIE01",
		"CHURCH_NAME": "PENIEL BOSCONIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "44",
		"CHURCH_CODE": "CBORIOSA01",
		"CHURCH_NAME": "RIOS DE AGUA VIVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "45",
		"CHURCH_CODE": "CPEBANCO01",
		"CHURCH_NAME": "EL BANCO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "46",
		"CHURCH_CODE": "CPEBELEN01",
		"CHURCH_NAME": "BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "47",
		"CHURCH_CODE": "CPEBOTIL01",
		"CHURCH_NAME": "BOTILLERO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "48",
		"CHURCH_CODE": "CPECASTA01",
		"CHURCH_NAME": "CASTAÑAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "49",
		"CHURCH_CODE": "CPECHIMI01",
		"CHURCH_NAME": "CHIMICHAGUA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "50",
		"CHURCH_CODE": "CPEKIL1201",
		"CHURCH_NAME": "KILOMETRO 12",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "25",
		"CHURCH_ID": "52",
		"CHURCH_CODE": "CCECENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "25",
		"CHURCH_ID": "53",
		"CHURCH_CODE": "CCEMONTE01",
		"CHURCH_NAME": "MONTE DE HEBROM CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "25",
		"CHURCH_ID": "56",
		"CHURCH_CODE": "CPNMARAN01",
		"CHURCH_NAME": "MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "57",
		"CHURCH_CODE": "CCIBETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "58",
		"CHURCH_CODE": "CCICENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "59",
		"CHURCH_CODE": "CCIEBENE01",
		"CHURCH_NAME": "EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "60",
		"CHURCH_CODE": "CCIEMANU01",
		"CHURCH_NAME": "EMANUEL SEVILLANO CIENAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "61",
		"CHURCH_CODE": "CCIJERIC01",
		"CHURCH_NAME": "JERICO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "62",
		"CHURCH_CODE": "CCIJERUS01",
		"CHURCH_NAME": "JERUSALEN CIENAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "63",
		"CHURCH_CODE": "CCIJORDA01",
		"CHURCH_NAME": "JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "64",
		"CHURCH_CODE": "CCIRENAC01",
		"CHURCH_NAME": "RENACER CIENAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "26",
		"CHURCH_ID": "65",
		"CHURCH_CODE": "CCIRIOFR01",
		"CHURCH_NAME": "RIOFRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "66",
		"CHURCH_CODE": "CPABECER01",
		"CHURCH_NAME": "BECERRIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "67",
		"CHURCH_CODE": "CPACODAZ01",
		"CHURCH_NAME": "CODAZZI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "68",
		"CHURCH_CODE": "CPAEDENM01",
		"CHURCH_NAME": "EDEN MANANTIAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "69",
		"CHURCH_CODE": "CPAJAGUA01",
		"CHURCH_NAME": "LA JAGUA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "70",
		"CHURCH_CODE": "CPAMONTE01",
		"CHURCH_NAME": "MONTE DE SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "71",
		"CHURCH_CODE": "CPATESOR01",
		"CHURCH_NAME": "TESORO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "72",
		"CHURCH_CODE": "CBOGETSE01",
		"CHURCH_NAME": "GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "73",
		"CHURCH_CODE": "CFUCENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "74",
		"CHURCH_CODE": "CFUCRIST01",
		"CHURCH_NAME": "CRISTO VIENE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "75",
		"CHURCH_CODE": "CFUJUVEN01",
		"CHURCH_NAME": "JUVENIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "76",
		"CHURCH_CODE": "CFULOMAF01",
		"CHURCH_NAME": "LOMA FRESCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "77",
		"CHURCH_CODE": "CFUPENIE01",
		"CHURCH_NAME": "PENIEL ALGARROBO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "78",
		"CHURCH_CODE": "CFUPINUE01",
		"CHURCH_NAME": "PIÑUELA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "79",
		"CHURCH_CODE": "CFUSANPU01",
		"CHURCH_NAME": "SAN PUES EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "80",
		"CHURCH_CODE": "CFUSIMON01",
		"CHURCH_NAME": "SIMON BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "81",
		"CHURCH_CODE": "CRIBETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "82",
		"CHURCH_CODE": "CRICENTR01",
		"CHURCH_NAME": "CENTRAL RIHOCHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "83",
		"CHURCH_CODE": "CRIEDENJ01",
		"CHURCH_NAME": "EDEN JARDIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "84",
		"CHURCH_CODE": "CRIFILAD01",
		"CHURCH_NAME": "FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "85",
		"CHURCH_CODE": "CRIHOREB01",
		"CHURCH_NAME": "HOREB GUAJIRA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "86",
		"CHURCH_CODE": "CRILALUZ01",
		"CHURCH_NAME": "LA LUZ DE CRISTO GUAJIRA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "29",
		"CHURCH_ID": "87",
		"CHURCH_CODE": "CRIMINGU01",
		"CHURCH_NAME": "NUEVO EDEN MINGUEO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "89",
		"CHURCH_CODE": "CCESHADA01",
		"CHURCH_NAME": "SHADDAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "91",
		"CHURCH_CODE": "CJECENTR01",
		"CHURCH_NAME": "CENTRAL JERUSALEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "92",
		"CHURCH_CODE": "CJEHOSAN01",
		"CHURCH_NAME": "HOSANA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "93",
		"CHURCH_CODE": "CJEOLIVO01",
		"CHURCH_NAME": "LOS OLIVOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "95",
		"CHURCH_CODE": "CJERENAJ01",
		"CHURCH_NAME": "RENACER JUAN MINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "96",
		"CHURCH_CODE": "CPACENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "97",
		"CHURCH_CODE": "CPAMANAU01",
		"CHURCH_NAME": "MANAURE LA PAZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "98",
		"CHURCH_CODE": "CPAMEDIA01",
		"CHURCH_NAME": "MEDIA LUNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "99",
		"CHURCH_CODE": "CPAMOLIN01",
		"CHURCH_NAME": "EL MOLINO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "100",
		"CHURCH_CODE": "CPAMONTE01",
		"CHURCH_NAME": "MONTE DE SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "101",
		"CHURCH_CODE": "CPANUEVO01",
		"CHURCH_NAME": "GRUPO NUEVO ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "102",
		"CHURCH_CODE": "CPASANJO01",
		"CHURCH_NAME": "SAN JOSE ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "103",
		"CHURCH_CODE": "CPAVILLA01",
		"CHURCH_NAME": "VILLANUEVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "104",
		"CHURCH_CODE": "CMACENTR01",
		"CHURCH_NAME": "MAICAO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "105",
		"CHURCH_CODE": "CMAEBENE01",
		"CHURCH_NAME": "MAICAO EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "106",
		"CHURCH_CODE": "CMAFUENT01",
		"CHURCH_NAME": "FUENTE DE VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "107",
		"CHURCH_CODE": "CMANUEVA01",
		"CHURCH_NAME": "NUEVA JERUSALEN PARAGUACHON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "108",
		"CHURCH_CODE": "CMAPRIME01",
		"CHURCH_NAME": "PRIMERA DE MAYO NVA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "109",
		"CHURCH_CODE": "CMARENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "110",
		"CHURCH_CODE": "CMAURIBI01",
		"CHURCH_NAME": "URIBIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "111",
		"CHURCH_CODE": "CNEEBENE01",
		"CHURCH_NAME": "EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "112",
		"CHURCH_CODE": "CRIGETSE01",
		"CHURCH_NAME": "GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "113",
		"CHURCH_CODE": "CNEBELEN01",
		"CHURCH_NAME": "BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "114",
		"CHURCH_CODE": "CNECAMIN01",
		"CHURCH_NAME": "CAMINO A CRISTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "115",
		"CHURCH_CODE": "CNEELOHI01",
		"CHURCH_NAME": "ELOHIM NVO EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "116",
		"CHURCH_CODE": "CNEEMAUS01",
		"CHURCH_NAME": "EMAUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "117",
		"CHURCH_CODE": "CNEENMAN01",
		"CHURCH_NAME": "ENMANUEL LA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "118",
		"CHURCH_CODE": "CNEESPER01",
		"CHURCH_NAME": "GRUPO LA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "119",
		"CHURCH_CODE": "CNEESTRE01",
		"CHURCH_NAME": "ESTRELLA DE BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "120",
		"CHURCH_CODE": "CNELIRIO01",
		"CHURCH_NAME": "LIRIOS DEL VALLE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "121",
		"CHURCH_CODE": "CNENUEVO01",
		"CHURCH_NAME": "NUEVO EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "122",
		"CHURCH_CODE": "CPEAYACU01",
		"CHURCH_NAME": "AYACUCHO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "123",
		"CHURCH_CODE": "CPECENTR01",
		"CHURCH_NAME": "CENTRAL PELAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "124",
		"CHURCH_CODE": "CPECURUM01",
		"CHURCH_NAME": "CURUMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "125",
		"CHURCH_CODE": "CPEEFESO01",
		"CHURCH_NAME": "EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "126",
		"CHURCH_CODE": "CPEFILAD01",
		"CHURCH_NAME": "FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "127",
		"CHURCH_CODE": "CPEGLORI01",
		"CHURCH_NAME": "LA GLORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "128",
		"CHURCH_CODE": "CPELOMAL01",
		"CHURCH_NAME": "LOMA LINDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "129",
		"CHURCH_CODE": "CPEMARAN01",
		"CHURCH_NAME": "MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "130",
		"CHURCH_CODE": "CPEPAILI01",
		"CHURCH_NAME": "PAILITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "34",
		"CHURCH_ID": "131",
		"CHURCH_CODE": "CPERENAC01",
		"CHURCH_NAME": "RENACER PELAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "35",
		"CHURCH_ID": "133",
		"CHURCH_CODE": "CPNPENIE01",
		"CHURCH_NAME": "PENIEL NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "36",
		"CHURCH_ID": "134",
		"CHURCH_CODE": "CCEORION01",
		"CHURCH_NAME": "ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "36",
		"CHURCH_ID": "135",
		"CHURCH_CODE": "CJEEMANU01",
		"CHURCH_NAME": "EMANUEL TUBARA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "36",
		"CHURCH_ID": "136",
		"CHURCH_CODE": "CJEISION01",
		"CHURCH_NAME": "SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "36",
		"CHURCH_ID": "137",
		"CHURCH_CODE": "CJEMARDG01",
		"CHURCH_NAME": "MAR DE GALILEA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "36",
		"CHURCH_ID": "138",
		"CHURCH_CODE": "CPNHEBRO01",
		"CHURCH_NAME": "MONTE HEBROM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "140",
		"CHURCH_CODE": "CRECENTR01",
		"CHURCH_NAME": "CENTRAL REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "141",
		"CHURCH_CODE": "CREGETSE01",
		"CHURCH_NAME": "GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "142",
		"CHURCH_CODE": "CREHEBRO01",
		"CHURCH_NAME": "HEBROM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "143",
		"CHURCH_CODE": "CREHOREB01",
		"CHURCH_NAME": "HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "144",
		"CHURCH_CODE": "CREILAFE01",
		"CHURCH_NAME": "LA FE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "145",
		"CHURCH_CODE": "CREISION01",
		"CHURCH_NAME": "SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "146",
		"CHURCH_CODE": "CREMILAG01",
		"CHURCH_NAME": "EL MILAGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "147",
		"CHURCH_CODE": "CREPALER01",
		"CHURCH_NAME": "PALERMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "148",
		"CHURCH_CODE": "CRERENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "37",
		"CHURCH_ID": "149",
		"CHURCH_CODE": "CRESALEM01",
		"CHURCH_NAME": "SALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "150",
		"CHURCH_CODE": "CVAEBENE01",
		"CHURCH_NAME": "EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "151",
		"CHURCH_CODE": "CVAEMANU01",
		"CHURCH_NAME": "EMANUEL LEÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "152",
		"CHURCH_CODE": "CVAFILAD01",
		"CHURCH_NAME": "FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "153",
		"CHURCH_CODE": "CVAGRANO01",
		"CHURCH_NAME": "GRANO DE MOSTAZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "154",
		"CHURCH_CODE": "CVARENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "155",
		"CHURCH_CODE": "CVAROSAS01",
		"CHURCH_NAME": "ROSA DE SARON CANDELARIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "38",
		"CHURCH_ID": "156",
		"CHURCH_CODE": "CVAUNION01",
		"CHURCH_NAME": "UNION EN VERDAD GALAPA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "157",
		"CHURCH_CODE": "CSCCENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "158",
		"CHURCH_CODE": "CSCGERIZ01",
		"CHURCH_NAME": "GERIZIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "159",
		"CHURCH_CODE": "CSCNUEVO01",
		"CHURCH_NAME": "NUEVO JARDIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "160",
		"CHURCH_CODE": "CSCPARAI01",
		"CHURCH_NAME": "PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "161",
		"CHURCH_CODE": "CSCREMAN01",
		"CHURCH_NAME": "REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "162",
		"CHURCH_CODE": "CSCSHALO01",
		"CHURCH_NAME": "SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "163",
		"CHURCH_CODE": "CSSBETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "164",
		"CHURCH_CODE": "CSSCRIST01",
		"CHURCH_NAME": "CRISTO REY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "165",
		"CHURCH_CODE": "CSSEBENE01",
		"CHURCH_NAME": "EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "166",
		"CHURCH_CODE": "CSSGETSE01",
		"CHURCH_NAME": "GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "167",
		"CHURCH_CODE": "CSSGUACH01",
		"CHURCH_NAME": "GUACHACA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "168",
		"CHURCH_CODE": "CSSMAGAN01",
		"CHURCH_NAME": "MAHANAIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "169",
		"CHURCH_CODE": "CSSOLIVO01",
		"CHURCH_NAME": "OLIVOS GAIRA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "170",
		"CHURCH_CODE": "CSSSALEM01",
		"CHURCH_NAME": "SALEM FUNDADORES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "171",
		"CHURCH_CODE": "CSSSINAI01",
		"CHURCH_NAME": "SINAI SAN FERNANDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "40",
		"CHURCH_ID": "172",
		"CHURCH_CODE": "CSSSIONB01",
		"CHURCH_NAME": "SION BASTIDAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "173",
		"CHURCH_CODE": "CNEEBENE01",
		"CHURCH_NAME": "EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "174",
		"CHURCH_CODE": "CNENAZAR01",
		"CHURCH_NAME": "NAZARETH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "175",
		"CHURCH_CODE": "CNENUEVA01",
		"CHURCH_NAME": "NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "176",
		"CHURCH_CODE": "CNEROCAE01",
		"CHURCH_NAME": "ROCA ETERNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "41",
		"CHURCH_ID": "177",
		"CHURCH_CODE": "CNESARSH01",
		"CHURCH_NAME": "SAR SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "41",
		"CHURCH_ID": "178",
		"CHURCH_CODE": "CREJEHOV01",
		"CHURCH_NAME": "JEHOVA JIREH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "41",
		"CHURCH_ID": "179",
		"CHURCH_CODE": "CRELAFEJ01",
		"CHURCH_NAME": "LA FE DE JESUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "41",
		"CHURCH_ID": "180",
		"CHURCH_CODE": "CREPENIE01",
		"CHURCH_NAME": "PENIEL DEL SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "41",
		"CHURCH_ID": "181",
		"CHURCH_CODE": "CRESINAI01",
		"CHURCH_NAME": "SINAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "42",
		"CHURCH_ID": "182",
		"CHURCH_CODE": "CSOJEHOV01",
		"CHURCH_NAME": "JEHOVA SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "41",
		"CHURCH_ID": "183",
		"CHURCH_CODE": "CSOSOLED01",
		"CHURCH_NAME": "SOLEDAD CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "43",
		"CHURCH_ID": "188",
		"CHURCH_CODE": "CSBBETHE01",
		"CHURCH_NAME": "BETHEL SANTA ROSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "43",
		"CHURCH_ID": "189",
		"CHURCH_CODE": "CSBEMANU01",
		"CHURCH_NAME": "EMANUEL CERRO CUADRADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "43",
		"CHURCH_ID": "190",
		"CHURCH_CODE": "CSBMARAN01",
		"CHURCH_NAME": "MARANATHA SAN ISIDRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "44",
		"CHURCH_ID": "191",
		"CHURCH_CODE": "CPASANJU01",
		"CHURCH_NAME": "ETERNA ROCA - SAN JUAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "44",
		"CHURCH_ID": "192",
		"CHURCH_CODE": "CRIALFAO01",
		"CHURCH_NAME": "ALFA Y OMEGA - ALBANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "44",
		"CHURCH_ID": "193",
		"CHURCH_CODE": "CRIBELEN01",
		"CHURCH_NAME": "BELEN - MAYABANGLOMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "44",
		"CHURCH_ID": "195",
		"CHURCH_CODE": "CRIPENIE01",
		"CHURCH_NAME": "PENIEL - ALBANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "44",
		"CHURCH_ID": "196",
		"CHURCH_CODE": "CSGEMANU01",
		"CHURCH_NAME": "EMMANUEL - FONSECA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "44",
		"CHURCH_ID": "197",
		"CHURCH_CODE": "CSGGETSE01",
		"CHURCH_NAME": "GETSEMANI - BARRANCAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "198",
		"CHURCH_CODE": "CSOBELLA01",
		"CHURCH_NAME": "BELLAVISTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "199",
		"CHURCH_CODE": "CSOCAMIN01",
		"CHURCH_NAME": "CAMINO A SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "200",
		"CHURCH_CODE": "CSOCAMPO01",
		"CHURCH_NAME": "CAMPO DE LA CRUZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "201",
		"CHURCH_CODE": "CSOEFESO01",
		"CHURCH_NAME": "EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "202",
		"CHURCH_CODE": "CSOEMANU01",
		"CHURCH_NAME": "EMANUEL SGRANDE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "203",
		"CHURCH_CODE": "CSOEMAUS01",
		"CHURCH_NAME": "EMAUS SANTO TOMAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "204",
		"CHURCH_CODE": "CSOPALAB01",
		"CHURCH_NAME": "PALABRAS DE VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "205",
		"CHURCH_CODE": "CSOPALMA01",
		"CHURCH_NAME": "PALMAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "206",
		"CHURCH_CODE": "CSOPONED01",
		"CHURCH_NAME": "PONEDERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "207",
		"CHURCH_CODE": "CSORIOSA01",
		"CHURCH_NAME": "RIOS DE AGUA VIVA STIO NVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "208",
		"CHURCH_CODE": "CSOSABANO1",
		"CHURCH_NAME": "SABANAGRANDE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "209",
		"CHURCH_CODE": "CJEBETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "210",
		"CHURCH_CODE": "CJERENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "211",
		"CHURCH_CODE": "CVABETAN01",
		"CHURCH_NAME": "BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "212",
		"CHURCH_CODE": "CVACENTR01",
		"CHURCH_NAME": "CENTRAL VALLE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "213",
		"CHURCH_CODE": "CVASARON01",
		"CHURCH_NAME": "SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "214",
		"CHURCH_CODE": "CVAVICTO01",
		"CHURCH_NAME": "LA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "215",
		"CHURCH_CODE": "CVAVOCES01",
		"CHURCH_NAME": "VOCES DE ESPERANZA VALLE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "217",
		"CHURCH_CODE": "CVLCENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "218",
		"CHURCH_CODE": "CVLEMANU01",
		"CHURCH_NAME": "EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "221",
		"CHURCH_CODE": "CVLHOREB01",
		"CHURCH_NAME": "HOREB NOVALITO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "222",
		"CHURCH_CODE": "CVLJERUS01",
		"CHURCH_NAME": "NUEVA JERUSALEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "223",
		"CHURCH_CODE": "CVLMARAN01",
		"CHURCH_NAME": "MARANATHA GUACOCHITO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "226",
		"CHURCH_CODE": "CVLRENAC01",
		"CHURCH_NAME": "RENACER VPAR CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "227",
		"CHURCH_CODE": "CVLROSAS01",
		"CHURCH_NAME": "ROSA SARON VPAR CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "47",
		"CHURCH_ID": "228",
		"CHURCH_CODE": "CVLSHALO01",
		"CHURCH_NAME": "SHALOM GARUPAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "230",
		"CHURCH_CODE": "CVLBETHE01",
		"CHURCH_NAME": "BETHEL VPAR PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "231",
		"CHURCH_CODE": "CVLFILAD01",
		"CHURCH_NAME": "FILADELFIA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "232",
		"CHURCH_CODE": "CVLGETSE01",
		"CHURCH_NAME": "GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "233",
		"CHURCH_CODE": "CVLPARAI01",
		"CHURCH_NAME": "PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "234",
		"CHURCH_CODE": "CVLPRIME01",
		"CHURCH_NAME": "PRIMERA MAYO NVO EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "235",
		"CHURCH_CODE": "CVLSINAI01",
		"CHURCH_NAME": "SINAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "49",
		"CHURCH_ID": "236",
		"CHURCH_CODE": "CNOCENTR01",
		"CHURCH_NAME": "CENTRAL SAN ANDRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "49",
		"CHURCH_ID": "237",
		"CHURCH_CODE": "CNOGOONE01",
		"CHURCH_NAME": "GOOD NEWS GROUP",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "49",
		"CHURCH_ID": "238",
		"CHURCH_CODE": "CNONATAN01",
		"CHURCH_NAME": "NATANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "49",
		"CHURCH_ID": "239",
		"CHURCH_CODE": "CSUNEWLI01",
		"CHURCH_NAME": "NEW LIFE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "50",
		"CHURCH_ID": "240",
		"CHURCH_CODE": "CPRCENTR01",
		"CHURCH_NAME": "CENTRAL PROVIDENCIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "50",
		"CHURCH_ID": "241",
		"CHURCH_CODE": "CPRROCKY01",
		"CHURCH_NAME": "ROCKY POINT",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "50",
		"CHURCH_ID": "242",
		"CHURCH_CODE": "CPRSUROE01",
		"CHURCH_NAME": "SUR OESTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "51",
		"CHURCH_ID": "244",
		"CHURCH_CODE": "CSUFISHE01",
		"CHURCH_NAME": "FISHER ROCK",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "51",
		"CHURCH_ID": "245",
		"CHURCH_CODE": "CSUIHOPE01",
		"CHURCH_NAME": "HOPE GROUP",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "51",
		"CHURCH_ID": "246",
		"CHURCH_CODE": "CSUILOMA01",
		"CHURCH_NAME": "LA LOMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "51",
		"CHURCH_ID": "247",
		"CHURCH_CODE": "CSUNEWLI01",
		"CHURCH_NAME": "GRUPO NEW LIFE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "51",
		"CHURCH_ID": "248",
		"CHURCH_CODE": "CSUSANLU01",
		"CHURCH_NAME": "SAN LUIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "249",
		"CHURCH_CODE": "CEDEN05",
		"CHURCH_NAME": "EDEN - ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "250",
		"CHURCH_CODE": "CEMAUS02",
		"CHURCH_NAME": "EMAUS -ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "251",
		"CHURCH_CODE": "CHOREB08",
		"CHURCH_NAME": "HOREB ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "252",
		"CHURCH_CODE": "CJERUS05",
		"CHURCH_NAME": "JERUSALEM - ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "253",
		"CHURCH_CODE": "CMARAN16",
		"CHURCH_NAME": "MARANATHA -ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "254",
		"CHURCH_CODE": "CMAXTR01",
		"CHURCH_NAME": "MAX TRUMER - ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "255",
		"CHURCH_CODE": "CNUEVA07",
		"CHURCH_NAME": "NUEVA ESPERANZA - ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "256",
		"CHURCH_CODE": "CORION07",
		"CHURCH_NAME": "ORION  - ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "52",
		"CHURCH_ID": "257",
		"CHURCH_CODE": "CSINAI04",
		"CHURCH_NAME": "SINAI - ARATOCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "53",
		"CHURCH_ID": "258",
		"CHURCH_CODE": "CAZAFR01",
		"CHURCH_NAME": "AZAFRAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "259",
		"CHURCH_CODE": "CCITE01",
		"CHURCH_NAME": "CITE - BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "260",
		"CHURCH_CODE": "CCRIST03",
		"CHURCH_NAME": "CRISTO VIENE GUEPSA - D. BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "262",
		"CHURCH_CODE": "CGUANO01",
		"CHURCH_NAME": "GUANOMO SHADAY -BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "263",
		"CHURCH_CODE": "CHATIL01",
		"CHURCH_NAME": "HATILLO - D. BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "264",
		"CHURCH_CODE": "CMARAN11",
		"CHURCH_NAME": "MARANATHA  - BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "265",
		"CHURCH_CODE": "CMONIQ01",
		"CHURCH_NAME": "SION MONIQUIRA - BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "90",
		"CHURCH_ID": "266",
		"CHURCH_CODE": "CNUEVA10",
		"CHURCH_NAME": "NUEVA JERUSALEN  - SUATIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "267",
		"CHURCH_CODE": "CNUEVO06",
		"CHURCH_NAME": "NUEVO AMANECER- BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "90",
		"CHURCH_ID": "268",
		"CHURCH_CODE": "CRENAC17",
		"CHURCH_NAME": "RENACER -SUAITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "90",
		"CHURCH_ID": "269",
		"CHURCH_CODE": "CSUITA01",
		"CHURCH_NAME": "SUAITA -DS SUAITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "54",
		"CHURCH_ID": "270",
		"CHURCH_CODE": "CTOLOT01",
		"CHURCH_NAME": "TOLOTA - BARBOSA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "271",
		"CHURCH_CODE": "CBARBO01",
		"CHURCH_NAME": "BARBOSA - D. BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "272",
		"CHURCH_CODE": "CBETHE01",
		"CHURCH_NAME": "BETHEL -BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "273",
		"CHURCH_CODE": "CEMMAN01",
		"CHURCH_NAME": "EMMANUEL - D. BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "274",
		"CHURCH_CODE": "CJERUS01",
		"CHURCH_NAME": "JERUSALEM - D. BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "275",
		"CHURCH_CODE": "CMONIQ01",
		"CHURCH_NAME": "SION MONIQUIRA - BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "276",
		"CHURCH_CODE": "CREDIM03",
		"CHURCH_NAME": "REDIMIR DS BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "277",
		"CHURCH_CODE": "CSANTU02",
		"CHURCH_NAME": "SANTUARIO DS BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "55",
		"CHURCH_ID": "278",
		"CHURCH_CODE": "CTOGÜI01",
		"CHURCH_NAME": "SHALOM TOGUI -BARBOSA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "56",
		"CHURCH_ID": "279",
		"CHURCH_CODE": "CCAMPO01",
		"CHURCH_NAME": "CAMPO HERMOSO - D. CAMPO HERMOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "56",
		"CHURCH_ID": "280",
		"CHURCH_CODE": "CCANAA10",
		"CHURCH_NAME": "IGLESIA CANAAN - CAMPOHERMOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "56",
		"CHURCH_ID": "281",
		"CHURCH_CODE": "CEDEN01",
		"CHURCH_NAME": "EDEN - D. CAMPO HERMOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "56",
		"CHURCH_ID": "282",
		"CHURCH_CODE": "CJERUS03",
		"CHURCH_NAME": "JERUSALEM - D. CAMPO HERMOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "56",
		"CHURCH_ID": "283",
		"CHURCH_CODE": "CJOYA01",
		"CHURCH_NAME": "LA JOYA - D. CAMPO HERMOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "284",
		"CHURCH_CODE": "CANTOR02",
		"CHURCH_NAME": "ANTORCHA DE CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "285",
		"CHURCH_CODE": "CBOQUE01",
		"CHURCH_NAME": "BOQUERON - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "286",
		"CHURCH_CODE": "CBORIG01",
		"CHURCH_NAME": "BORIGA - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "287",
		"CHURCH_CODE": "CCAPIT01",
		"CHURCH_NAME": "CAPITANEJO LA ESPERANZA - CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "288",
		"CHURCH_CODE": "CDUART01",
		"CHURCH_NAME": "DUARTES - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "290",
		"CHURCH_CODE": "CILARG01",
		"CHURCH_NAME": "ILARGUTA - CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "291",
		"CHURCH_CODE": "CNARAN01",
		"CHURCH_NAME": "NARANJOS - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "292",
		"CHURCH_CODE": "CPLANB01",
		"CHURCH_NAME": "EL PLAN BETHEL - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "293",
		"CHURCH_CODE": "CRENAC02",
		"CHURCH_NAME": "RENACER - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "295",
		"CHURCH_CODE": "CSION02",
		"CHURCH_NAME": "SION - D. CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "57",
		"CHURCH_ID": "296",
		"CHURCH_CODE": "CSOATA01",
		"CHURCH_NAME": "SOATA - CAPITANEJO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "59",
		"CHURCH_ID": "300",
		"CHURCH_CODE": "CCENTR01",
		"CHURCH_NAME": "CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "59",
		"CHURCH_ID": "301",
		"CHURCH_CODE": "CPENIE07",
		"CHURCH_NAME": "PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "302",
		"CHURCH_CODE": "CEMMAN03",
		"CHURCH_NAME": "EMMANUEL-COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "303",
		"CHURCH_CODE": "CFILAD03",
		"CHURCH_NAME": "FILADELFIA - COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "304",
		"CHURCH_CODE": "CJERUS07",
		"CHURCH_NAME": "JERUSALEM - COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "305",
		"CHURCH_CODE": "CLAESP01",
		"CHURCH_NAME": "LA ESPERANZA DISTRITO LA COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "306",
		"CHURCH_CODE": "CLIBAN03",
		"CHURCH_NAME": "LIBANO -COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "307",
		"CHURCH_CODE": "CMARAN07",
		"CHURCH_NAME": "MARANATHA - COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "308",
		"CHURCH_CODE": "CRENAC07",
		"CHURCH_NAME": "RENACER - COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "309",
		"CHURCH_CODE": "CSINAI06",
		"CHURCH_NAME": "SINAI - COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "60",
		"CHURCH_ID": "310",
		"CHURCH_CODE": "CSURAT01",
		"CHURCH_NAME": "SURATA ZURIEL DS LA COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "311",
		"CHURCH_CODE": "CCAMPE01",
		"CHURCH_NAME": "CAMPESTRE NORTE -DISTRITO COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "312",
		"CHURCH_CODE": "CHERAL01",
		"CHURCH_NAME": "HERALDOS DEL REINO ORION - COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "313",
		"CHURCH_CODE": "CJIREH01",
		"CHURCH_NAME": "JIREH DS COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "314",
		"CHURCH_CODE": "CLOMAL02",
		"CHURCH_NAME": "LOMA LINDA -COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "315",
		"CHURCH_CODE": "CMONTE14",
		"CHURCH_NAME": "MONTEVIDEO -COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "316",
		"CHURCH_CODE": "CNOGAL01",
		"CHURCH_NAME": "NOGAL COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "317",
		"CHURCH_CODE": "CPARAI02",
		"CHURCH_NAME": "PARAISO - COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "318",
		"CHURCH_CODE": "CRENAC05",
		"CHURCH_NAME": "RENACER - COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "319",
		"CHURCH_CODE": "CSARON02",
		"CHURCH_NAME": "SARON - COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "320",
		"CHURCH_CODE": "CVIJAG01",
		"CHURCH_NAME": "VIJAGUAL - COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "61",
		"CHURCH_ID": "321",
		"CHURCH_CODE": "CVILLA02",
		"CHURCH_NAME": "VILLA CARMELO DS COLORADOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "62",
		"CHURCH_ID": "322",
		"CHURCH_CODE": "CCRIST04",
		"CHURCH_NAME": "CRISTO VIVE - DIAMANTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "62",
		"CHURCH_ID": "323",
		"CHURCH_CODE": "CNUEVO05",
		"CHURCH_NAME": "NUEVO AMANECER - DIAMANTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "62",
		"CHURCH_ID": "324",
		"CHURCH_CODE": "CREDIM01",
		"CHURCH_NAME": "REDIMIR - DIAMANTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "63",
		"CHURCH_ID": "325",
		"CHURCH_CODE": "CCAMIN01",
		"CHURCH_NAME": "IGLESIA CAMINO A CRISTO - FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "63",
		"CHURCH_ID": "326",
		"CHURCH_CODE": "CFILAD01",
		"CHURCH_NAME": "FILADELFIA - D. FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "63",
		"CHURCH_ID": "327",
		"CHURCH_CODE": "CLUZSA01",
		"CHURCH_NAME": "LUZ DE SALVACION - FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "328",
		"CHURCH_CODE": "CBETHE04",
		"CHURCH_NAME": "BETHEL - FLORIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "329",
		"CHURCH_CODE": "CFLORI02",
		"CHURCH_NAME": "FLORIDABLANCA - D. FLORIDABLANCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "331",
		"CHURCH_CODE": "CHOREB03",
		"CHURCH_NAME": "HOREB -CAÑAVERAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "332",
		"CHURCH_CODE": "CLAGOS01",
		"CHURCH_NAME": "LAGOS LA ESPERANZA - D. FLORIDABLANCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "333",
		"CHURCH_CODE": "CLAPAZ01",
		"CHURCH_NAME": "LA PAZ KADMIEL  - FLORIDABLANCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "334",
		"CHURCH_CODE": "CLIMON01",
		"CHURCH_NAME": "LIMONCITO DS FLORIDABLANCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "335",
		"CHURCH_CODE": "CNUEVA11",
		"CHURCH_NAME": "NUEVA JERUSALEN DS FLORIDABLANCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "64",
		"CHURCH_ID": "337",
		"CHURCH_CODE": "CNUEVO07",
		"CHURCH_NAME": "NUEVO AMANECER DS FLORIDABLANCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "65",
		"CHURCH_ID": "338",
		"CHURCH_CODE": "CBETHE08",
		"CHURCH_NAME": "BETHEL SAN ANDRES -DS GUACA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "65",
		"CHURCH_ID": "340",
		"CHURCH_CODE": "CMARAN05",
		"CHURCH_NAME": "MARANATHA GUACA - DS GUACA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "65",
		"CHURCH_ID": "341",
		"CHURCH_CODE": "CMONTE09",
		"CHURCH_NAME": "MONTE SION - DS GUACA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "65",
		"CHURCH_ID": "342",
		"CHURCH_CODE": "CORTIG01",
		"CHURCH_NAME": "ORTIGAL - DS GUACA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "65",
		"CHURCH_ID": "343",
		"CHURCH_CODE": "CSHALO01",
		"CHURCH_NAME": "SHALOM - D. MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "66",
		"CHURCH_ID": "344",
		"CHURCH_CODE": "CCOAL01",
		"CHURCH_NAME": "COAL - D. COAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "66",
		"CHURCH_ID": "346",
		"CHURCH_CODE": "CGALIL01",
		"CHURCH_NAME": "GALILEA - COAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "66",
		"CHURCH_ID": "347",
		"CHURCH_CODE": "CREVIV01",
		"CHURCH_NAME": "REVIVE -CAÑAVERAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "66",
		"CHURCH_ID": "348",
		"CHURCH_CODE": "CSHALO05",
		"CHURCH_NAME": "SHALOM - DS COAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "67",
		"CHURCH_ID": "349",
		"CHURCH_CODE": "CJARDI01",
		"CHURCH_NAME": "JARDIN - D. JARDIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "67",
		"CHURCH_ID": "350",
		"CHURCH_CODE": "CMONTE13",
		"CHURCH_NAME": "MONTEVERDE - D. JARDIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "67",
		"CHURCH_ID": "351",
		"CHURCH_CODE": "CSHALO04",
		"CHURCH_NAME": "SHALOM - JARDIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "352",
		"CHURCH_CODE": "CBETEL01",
		"CHURCH_NAME": "BETEL DS KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "353",
		"CHURCH_CODE": "CESTRE03",
		"CHURCH_NAME": "ESTRELLA DE LA MAÑANA - D. KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "354",
		"CHURCH_CODE": "CKENNE01",
		"CHURCH_NAME": "KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "355",
		"CHURCH_CODE": "CMORIA01",
		"CHURCH_NAME": "IGLESIA MORIAT  - IGLESIA KENEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "356",
		"CHURCH_CODE": "CNUEVA08",
		"CHURCH_NAME": "NUEVA JERUSALEN - KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "357",
		"CHURCH_CODE": "CPUERT02",
		"CHURCH_NAME": "PUERTA DEL CIELO - KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "68",
		"CHURCH_ID": "358",
		"CHURCH_CODE": "CVERDA01",
		"CHURCH_NAME": "VERDAD PRESENTE - D. KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "69",
		"CHURCH_ID": "359",
		"CHURCH_CODE": "CACAPU01",
		"CHURCH_NAME": "ACAPULCO - D. LA CUMBRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "69",
		"CHURCH_ID": "360",
		"CHURCH_CODE": "CCUMBR01",
		"CHURCH_NAME": "LA CUMBRE  - D. LA CUMBRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "69",
		"CHURCH_ID": "361",
		"CHURCH_CODE": "CMARAN02",
		"CHURCH_NAME": "MARANATHA - D. LA CUMBRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "69",
		"CHURCH_ID": "362",
		"CHURCH_CODE": "CMONTE07",
		"CHURCH_NAME": "MONTE SION  -LA CUMBRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "69",
		"CHURCH_ID": "363",
		"CHURCH_CODE": "CPRADO01",
		"CHURCH_NAME": "PRADOS CELESTIALES - LA CUMBRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "69",
		"CHURCH_ID": "364",
		"CHURCH_CODE": "CPUERT01",
		"CHURCH_NAME": "PUERTA DEL CIELO - D. LA CUMBRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "365",
		"CHURCH_CODE": "CBELEN01",
		"CHURCH_NAME": "BELEN EL CERRITO - D. MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "366",
		"CHURCH_CODE": "CCARCA01",
		"CHURCH_NAME": "CARCASI - D. MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "367",
		"CHURCH_CODE": "CEDEN04",
		"CHURCH_NAME": "EDEN - D. MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "368",
		"CHURCH_CODE": "CESPER03",
		"CHURCH_NAME": "LA ESPERANZA - D. MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "369",
		"CHURCH_CODE": "CHEBRO01",
		"CHURCH_NAME": "HEBRON -MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "370",
		"CHURCH_CODE": "CMALAG01",
		"CHURCH_NAME": "MALAGA - D. MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "371",
		"CHURCH_CODE": "CMARAN01",
		"CHURCH_NAME": "MARANATHA - MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "372",
		"CHURCH_CODE": "CRENAC19",
		"CHURCH_NAME": "RENACER PUERTAS MALAGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "70",
		"CHURCH_ID": "373",
		"CHURCH_CODE": "CSHALO01",
		"CHURCH_NAME": "SHALOM D. GUACA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "71",
		"CHURCH_ID": "374",
		"CHURCH_CODE": "CALBAN01",
		"CHURCH_NAME": "ALBANIA - D. MIRAFLORES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "71",
		"CHURCH_ID": "375",
		"CHURCH_CODE": "CGETSE02",
		"CHURCH_NAME": "GETSEMANI - D. MIRAFLORES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "71",
		"CHURCH_ID": "376",
		"CHURCH_CODE": "CMARAN04",
		"CHURCH_NAME": "MARANATHA - D. MIRAFLORES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "71",
		"CHURCH_ID": "377",
		"CHURCH_CODE": "CMARAN06",
		"CHURCH_NAME": "MARANATHA - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "71",
		"CHURCH_ID": "378",
		"CHURCH_CODE": "CMIRAF01",
		"CHURCH_NAME": "MIRAFLORES - D. MIRAFLORES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "72",
		"CHURCH_ID": "379",
		"CHURCH_CODE": "CCANAA04",
		"CHURCH_NAME": "CANAAN - D. MUTIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "72",
		"CHURCH_ID": "380",
		"CHURCH_CODE": "CMONTE12",
		"CHURCH_NAME": "MONTERREDONDO - NUEVA JERUSALEN - MUTIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "72",
		"CHURCH_ID": "381",
		"CHURCH_CODE": "CMUTIS01",
		"CHURCH_NAME": "MUTIS - D. MUTIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "72",
		"CHURCH_ID": "382",
		"CHURCH_CODE": "CORION06",
		"CHURCH_NAME": "ORION  - D. MUTIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "72",
		"CHURCH_ID": "383",
		"CHURCH_CODE": "CPENIE05",
		"CHURCH_NAME": "PENIEL ESTORAQUES - D. MUTIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "73",
		"CHURCH_ID": "384",
		"CHURCH_CODE": "CHOREB04",
		"CHURCH_NAME": "HOREB MARANATHA - DISTRITO  NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "73",
		"CHURCH_ID": "386",
		"CHURCH_CODE": "CNORTE01",
		"CHURCH_NAME": "NORTE - D. NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "74",
		"CHURCH_ID": "387",
		"CHURCH_CODE": "CCANAA01",
		"CHURCH_NAME": "CANAAN - CANAAN NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "74",
		"CHURCH_ID": "389",
		"CHURCH_CODE": "CEMMAN07",
		"CHURCH_NAME": "EMMANUEL - CANAAN NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "74",
		"CHURCH_ID": "390",
		"CHURCH_CODE": "CSALEM02",
		"CHURCH_NAME": "SALEM - CANAAN NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "74",
		"CHURCH_ID": "391",
		"CHURCH_CODE": "CSINAI03",
		"CHURCH_NAME": "SINAI - CANAAN NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "75",
		"CHURCH_ID": "392",
		"CHURCH_CODE": "CEBENE03",
		"CHURCH_NAME": "EBENEZER - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "393",
		"CHURCH_CODE": "CGETSE06",
		"CHURCH_NAME": "GETSEMANI DS VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "75",
		"CHURCH_ID": "394",
		"CHURCH_CODE": "CMANAN03",
		"CHURCH_NAME": "MANANTIAL DE VIDA - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "75",
		"CHURCH_ID": "395",
		"CHURCH_CODE": "CMARAN06",
		"CHURCH_NAME": "MARANATHA - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "75",
		"CHURCH_ID": "396",
		"CHURCH_CODE": "CNUEVO01",
		"CHURCH_NAME": "NUEVO AMANECER - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "75",
		"CHURCH_ID": "398",
		"CHURCH_CODE": "CPARAI03",
		"CHURCH_NAME": "PARAISO - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "399",
		"CHURCH_CODE": "CPARAI09",
		"CHURCH_NAME": "PARAISO - DS VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "75",
		"CHURCH_ID": "400",
		"CHURCH_CODE": "CRENAC06",
		"CHURCH_NAME": "RENACER - D. PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "76",
		"CHURCH_ID": "403",
		"CHURCH_CODE": "CARENA01",
		"CHURCH_NAME": "ARENALES - D. PARAMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "76",
		"CHURCH_ID": "404",
		"CHURCH_CODE": "CEMMAN06",
		"CHURCH_NAME": "EMMANUEL - D.PARAMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "76",
		"CHURCH_ID": "405",
		"CHURCH_CODE": "CLAFE01",
		"CHURCH_NAME": "LA FE ASEQUIAS DS PARAMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "76",
		"CHURCH_ID": "406",
		"CHURCH_CODE": "CREMAN03",
		"CHURCH_NAME": "REMANENTE - PARAMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "76",
		"CHURCH_ID": "407",
		"CHURCH_CODE": "CSINAI01",
		"CHURCH_NAME": "SINAI -PARAMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "77",
		"CHURCH_ID": "408",
		"CHURCH_CODE": "CBETHA02",
		"CHURCH_NAME": "SESTA BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "77",
		"CHURCH_ID": "409",
		"CHURCH_CODE": "CCRIST01",
		"CHURCH_NAME": "CRISTALES - PIEDECUESTA BETHANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "77",
		"CHURCH_ID": "410",
		"CHURCH_CODE": "CESTRE04",
		"CHURCH_NAME": "ESTRELLA DE DAVID -PIEDECUESTA BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "77",
		"CHURCH_ID": "411",
		"CHURCH_CODE": "CRENAC08",
		"CHURCH_NAME": "RENACER -PIEDECUESTA BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "77",
		"CHURCH_ID": "412",
		"CHURCH_CODE": "CSANTU01",
		"CHURCH_NAME": "EL SANTUARIO - D. PIEDECUESTA BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "413",
		"CHURCH_CODE": "CBETHE11",
		"CHURCH_NAME": "BETHESDA LA MESA - PIEDECUESTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "414",
		"CHURCH_CODE": "CEDEN08",
		"CHURCH_NAME": "EDEN - PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "415",
		"CHURCH_CODE": "CEMMAN12",
		"CHURCH_NAME": "EMMANUEL REFUGIO - D. PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "416",
		"CHURCH_CODE": "CLAMAT01",
		"CHURCH_NAME": "LA MATA DS PIEDECUESTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "417",
		"CHURCH_CODE": "CPIEDE01",
		"CHURCH_NAME": "PIEDECUESTA - D. PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "418",
		"CHURCH_CODE": "CQUINTA01",
		"CHURCH_NAME": "QUINTA GRANADA PINARES -PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "419",
		"CHURCH_CODE": "CREMAN01",
		"CHURCH_NAME": "REMANENTE - D. PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "420",
		"CHURCH_CODE": "CROCAE02",
		"CHURCH_NAME": "ROCA ETERNA - PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "78",
		"CHURCH_ID": "421",
		"CHURCH_CODE": "CSANMI01",
		"CHURCH_NAME": "SAN MIGUEL DS PIEDECUESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "422",
		"CHURCH_CODE": "CCANAA06",
		"CHURCH_NAME": "CANAAN - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "423",
		"CHURCH_CODE": "CEMMAN08",
		"CHURCH_NAME": "EMMANUEL - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "424",
		"CHURCH_CODE": "CFILO01",
		"CHURCH_NAME": "EL FILO - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "425",
		"CHURCH_CODE": "CHOREB09",
		"CHURCH_NAME": "HOREB DS.PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "426",
		"CHURCH_CODE": "CJERIC01",
		"CHURCH_NAME": "JERICO -PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "427",
		"CHURCH_CODE": "CJERUS06",
		"CHURCH_NAME": "JERUSALEM - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "428",
		"CHURCH_CODE": "CLIRIO01",
		"CHURCH_NAME": "LIRIO DE LOS VALLES DS.PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "429",
		"CHURCH_CODE": "COLIVO02",
		"CHURCH_NAME": "LOS OLIVOS - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "430",
		"CHURCH_CODE": "CPARAI04",
		"CHURCH_NAME": "PARAISO - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "431",
		"CHURCH_CODE": "CRENAC13",
		"CHURCH_NAME": "RENACER LA VEGA - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "432",
		"CHURCH_CODE": "CROCA01",
		"CHURCH_NAME": "LA ROCA - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "433",
		"CHURCH_CODE": "CSIONP01",
		"CHURCH_NAME": "SION PLAYON - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "79",
		"CHURCH_ID": "434",
		"CHURCH_CODE": "CSIONT01",
		"CHURCH_NAME": "SION TURBAY - D. PLAYON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "435",
		"CHURCH_CODE": "CBELLE01",
		"CHURCH_NAME": "LA BELLEZA - D. PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "436",
		"CHURCH_CODE": "CBETEL02",
		"CHURCH_NAME": "BETEL DS PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "438",
		"CHURCH_CODE": "CEMAUS03",
		"CHURCH_NAME": "EMAUS -PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "439",
		"CHURCH_CODE": "CEMMAN17",
		"CHURCH_NAME": "EMMANUEL - PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "440",
		"CHURCH_CODE": "CESPER04",
		"CHURCH_NAME": "LA ESPERANZA - D. PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "441",
		"CHURCH_CODE": "CHOREB05",
		"CHURCH_NAME": "HOREB - D. PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "442",
		"CHURCH_CODE": "CMANAN04",
		"CHURCH_NAME": "MANANTIAL FLORIAN - PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "443",
		"CHURCH_CODE": "CMERCA01",
		"CHURCH_NAME": "MERCADILLO DS PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "444",
		"CHURCH_CODE": "CNUEVA04",
		"CHURCH_NAME": "JERUSALEM - D. PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "445",
		"CHURCH_CODE": "CREMAN02",
		"CHURCH_NAME": "REMANENTE - D. PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "446",
		"CHURCH_CODE": "CRENAC18",
		"CHURCH_NAME": "RENACER  -PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "80",
		"CHURCH_ID": "447",
		"CHURCH_CODE": "CSINAI05",
		"CHURCH_NAME": "SINAI - D. PUENTE NACIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "81",
		"CHURCH_ID": "448",
		"CHURCH_CODE": "CREDEN03",
		"CHURCH_NAME": "REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "81",
		"CHURCH_ID": "449",
		"CHURCH_CODE": "CVEGAS02",
		"CHURCH_NAME": "VEGAS MORRORICO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "82",
		"CHURCH_ID": "450",
		"CHURCH_CODE": "CREDEN03",
		"CHURCH_NAME": "REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "83",
		"CHURCH_ID": "456",
		"CHURCH_CODE": "CCAÑAV01",
		"CHURCH_NAME": "CAÑAVERAL ENGLISH - RENUEVAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "83",
		"CHURCH_ID": "457",
		"CHURCH_CODE": "CNUEVA09",
		"CHURCH_NAME": "NUEVA JERUSALEN - RENUEVAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "83",
		"CHURCH_ID": "458",
		"CHURCH_CODE": "CRENUE01",
		"CHURCH_NAME": "RENUEVAME - RENUEVAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "459",
		"CHURCH_CODE": "CBETHA03",
		"CHURCH_NAME": "BETHANIA - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "460",
		"CHURCH_CODE": "CBETHE03",
		"CHURCH_NAME": "BETHEL - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "461",
		"CHURCH_CODE": "CEDEN06",
		"CHURCH_NAME": "EDEN - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "462",
		"CHURCH_CODE": "CGALIL02",
		"CHURCH_NAME": "GALILEA RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "463",
		"CHURCH_CODE": "CGETSE03",
		"CHURCH_NAME": "GETSEMANI - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "464",
		"CHURCH_CODE": "CJORDA01",
		"CHURCH_NAME": "JORDAN - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "465",
		"CHURCH_CODE": "CMONTE01",
		"CHURCH_NAME": "MONTE ARARAT - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "466",
		"CHURCH_CODE": "CMONTE06",
		"CHURCH_NAME": "MONTE NEBO - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "467",
		"CHURCH_CODE": "CMONTE10",
		"CHURCH_NAME": "MONTE SION - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "468",
		"CHURCH_CODE": "CNUEVA06",
		"CHURCH_NAME": "NUEVA ESPERANZA-D.RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "469",
		"CHURCH_CODE": "CPARAI05",
		"CHURCH_NAME": "PARAISO - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "470",
		"CHURCH_CODE": "CSHADA02",
		"CHURCH_NAME": "SHADAY - RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "471",
		"CHURCH_CODE": "CSINAI08",
		"CHURCH_NAME": "SINAI - RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "84",
		"CHURCH_ID": "472",
		"CHURCH_CODE": "CVIRTUD01",
		"CHURCH_NAME": "LA VIRTUD - D. RIONEGRO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "473",
		"CHURCH_CODE": "CEMMAN18",
		"CHURCH_NAME": "EMMANUEL - SAN ISIDR R. SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "474",
		"CHURCH_CODE": "CEMMAU02",
		"CHURCH_NAME": "EMAUS - D. RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "475",
		"CHURCH_CODE": "CESMER01",
		"CHURCH_NAME": "ESMERALDA - D. RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "476",
		"CHURCH_CODE": "CESTRE05",
		"CHURCH_NAME": "ESTRELLA DE BELEN- RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "477",
		"CHURCH_CODE": "CORION08",
		"CHURCH_NAME": "ORION  - D. RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "478",
		"CHURCH_CODE": "CPALES04",
		"CHURCH_NAME": "PALESTINA - D. RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "479",
		"CHURCH_CODE": "CPARAI01",
		"CHURCH_NAME": "PARAISO - RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "480",
		"CHURCH_CODE": "CSAMAR03",
		"CHURCH_NAME": "REMANENTE SAMARIA - D. RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "481",
		"CHURCH_CODE": "CSHALO02",
		"CHURCH_NAME": "SHALOM - D. RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "85",
		"CHURCH_ID": "482",
		"CHURCH_CODE": "CZARET01",
		"CHURCH_NAME": "ZARETH SAN JUAN RIONEGRO SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "483",
		"CHURCH_CODE": "CEBENE04",
		"CHURCH_NAME": "EBENEZER - D. SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "484",
		"CHURCH_CODE": "CEDEN07",
		"CHURCH_NAME": "EDEN - D. SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "485",
		"CHURCH_CODE": "CESTRE06",
		"CHURCH_NAME": "ESTRELLA DE LA MAÑANA DS SANGIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "486",
		"CHURCH_CODE": "CMARAN15",
		"CHURCH_NAME": "MARANATHA MEDIOS - SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "487",
		"CHURCH_CODE": "CORION09",
		"CHURCH_NAME": "ORION  - D. SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "488",
		"CHURCH_CODE": "CRENAC09",
		"CHURCH_NAME": "RENACER - D. SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "489",
		"CHURCH_CODE": "CRENUE06",
		"CHURCH_NAME": "EL RENUEVO DS SANGIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "491",
		"CHURCH_CODE": "CSARON04",
		"CHURCH_NAME": "SARON PARAMO DS SANGIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "86",
		"CHURCH_ID": "492",
		"CHURCH_CODE": "CVIDAN01",
		"CHURCH_NAME": "VIDA NUEVA VILLANUEVA DS SANGIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "87",
		"CHURCH_ID": "493",
		"CHURCH_CODE": "CANTOR01",
		"CHURCH_NAME": "ANTORCHA DS SION SANGIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "87",
		"CHURCH_ID": "494",
		"CHURCH_CODE": "CEMMAN11",
		"CHURCH_NAME": "EMMANUEL CHARALA SION SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "87",
		"CHURCH_ID": "495",
		"CHURCH_CODE": "CJERUS10",
		"CHURCH_NAME": "JERUSALEN DS.SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "87",
		"CHURCH_ID": "496",
		"CHURCH_CODE": "CMARAN18",
		"CHURCH_NAME": "MANANTIAL DS SION SANGIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "87",
		"CHURCH_ID": "497",
		"CHURCH_CODE": "CNUEVA01",
		"CHURCH_NAME": "NUEVA ESPERANZA  - SION SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "87",
		"CHURCH_ID": "498",
		"CHURCH_CODE": "CSIONP02",
		"CHURCH_NAME": "SION PROVIVIENDA - D. SION SAN GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "500",
		"CHURCH_CODE": "CCHIMA01",
		"CHURCH_NAME": "CHIMA - D. SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "501",
		"CHURCH_CODE": "CEMMAN10",
		"CHURCH_NAME": "EMMANUEL - D. SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "502",
		"CHURCH_CODE": "CMARAN10",
		"CHURCH_NAME": "MARANATHA - D. SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "503",
		"CHURCH_CODE": "CPALES05",
		"CHURCH_NAME": "PALESTINA - D. SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "504",
		"CHURCH_CODE": "CREDIM02",
		"CHURCH_NAME": "REDIMIR GUAPOTA DS SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "505",
		"CHURCH_CODE": "CRENAC12",
		"CHURCH_NAME": "RENACER EN CRISTO - SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "507",
		"CHURCH_CODE": "CSALEM04",
		"CHURCH_NAME": "SALEM OIBA -SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "508",
		"CHURCH_CODE": "CSIMAC01",
		"CHURCH_NAME": "SIMACOTA - D. SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "88",
		"CHURCH_ID": "509",
		"CHURCH_CODE": "LAVIÑ01",
		"CHURCH_NAME": "LA VIÑA DEL SEÑOR PALMAS - SOCORRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "89",
		"CHURCH_ID": "510",
		"CHURCH_CODE": "CSOTOM01",
		"CHURCH_NAME": "SOTOMAYOR - D. SOTOMAYOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "90",
		"CHURCH_ID": "511",
		"CHURCH_CODE": "CGUADA01",
		"CHURCH_NAME": "GUADALUPE DS SUAITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "90",
		"CHURCH_ID": "513",
		"CHURCH_CODE": "CSUITA01",
		"CHURCH_NAME": "SUAITA -DS SUAITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "514",
		"CHURCH_CODE": "CEBENE07",
		"CHURCH_NAME": "IGLESIA EBENEZER -VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "515",
		"CHURCH_CODE": "CGETSE06",
		"CHURCH_NAME": "GETSEMANI DS VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "517",
		"CHURCH_CODE": "CMARAN17",
		"CHURCH_NAME": "MARANATHA - VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "518",
		"CHURCH_CODE": "CORION02",
		"CHURCH_NAME": "ORION PEÑON - VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "519",
		"CHURCH_CODE": "CPARAI09",
		"CHURCH_NAME": "PARAISO - DS VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "520",
		"CHURCH_CODE": "CRENUE04",
		"CHURCH_NAME": "RENUEVO EL TRAPAL - VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "91",
		"CHURCH_ID": "521",
		"CHURCH_CODE": "CROCAV02",
		"CHURCH_NAME": "ROCA VIVA HERMOSURA - VELEZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "522",
		"CHURCH_CODE": "CARNAN01",
		"CHURCH_NAME": "ARNANIA TONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "523",
		"CHURCH_CODE": "CEFESO03",
		"CHURCH_NAME": "EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "524",
		"CHURCH_CODE": "CLOMAL01",
		"CHURCH_NAME": "LOMA LINDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "525",
		"CHURCH_CODE": "CORION05",
		"CHURCH_NAME": "ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "526",
		"CHURCH_CODE": "CRENAC20",
		"CHURCH_NAME": "RENACER REPOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "527",
		"CHURCH_CODE": "CSHADA01",
		"CHURCH_NAME": "SHADDAY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "528",
		"CHURCH_CODE": "CVEGAS01",
		"CHURCH_NAME": "LAS VEGAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "92",
		"CHURCH_ID": "529",
		"CHURCH_CODE": "CVICTOR02",
		"CHURCH_NAME": "LA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "530",
		"CHURCH_CODE": "CBABEREA01",
		"CHURCH_NAME": "IGLESIA BEREA LAS PIEDRAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "531",
		"CHURCH_CODE": "CBABETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL SAN ANTERO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "532",
		"CHURCH_CODE": "CBACANAA01",
		"CHURCH_NAME": "IGLESIA CANAAN BARCELONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "533",
		"CHURCH_CODE": "CBAEBENE01",
		"CHURCH_NAME": "IGLESIA EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "534",
		"CHURCH_CODE": "CBAEDENL01",
		"CHURCH_NAME": "IGLESIA EDEN LOS MONOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "535",
		"CHURCH_CODE": "CBAESTRE01",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE BELEN LORICA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "536",
		"CHURCH_CODE": "CBAJERUS01",
		"CHURCH_NAME": "IGLESIA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "537",
		"CHURCH_CODE": "CBAJESUS01",
		"CHURCH_NAME": "IGLESIA JESUS VIENE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "538",
		"CHURCH_CODE": "CBANUERE01",
		"CHURCH_NAME": "IGLESIA NUEVO RENACER - BAJO SINU",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "539",
		"CHURCH_CODE": "CBANUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA LUZ EL VIENTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "540",
		"CHURCH_CODE": "CBANUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM MANZUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "541",
		"CHURCH_CODE": "CBAORION01",
		"CHURCH_NAME": "IGLESIA ORION RECUELA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "542",
		"CHURCH_CODE": "CBAPENIE01",
		"CHURCH_NAME": "IGLESIA PENIEL MORALES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "543",
		"CHURCH_CODE": "CBAPENIE02",
		"CHURCH_NAME": "IGLESIA PENIEL CERETE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "544",
		"CHURCH_CODE": "CBAREDEN01",
		"CHURCH_NAME": "IGLESIA REDENCION - BAJO SINU",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "545",
		"CHURCH_CODE": "CBARENAC01",
		"CHURCH_NAME": "IGLESIA RENACER FLAMENCO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "546",
		"CHURCH_CODE": "CBASARON01",
		"CHURCH_NAME": "IGLESIA SARON CERETÉ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "547",
		"CHURCH_CODE": "CBASHALO01",
		"CHURCH_NAME": "IGLESIA SHALOOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "93",
		"CHURCH_ID": "548",
		"CHURCH_CODE": "CBASIONM01",
		"CHURCH_NAME": "IGLESIA SION MOÑITOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "549",
		"CHURCH_CODE": "CBOBETAN01",
		"CHURCH_NAME": "IGLESIA BETANIA CAMPESTRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "550",
		"CHURCH_CODE": "CBOCAMIN01",
		"CHURCH_NAME": "IGLESIA CAMINO A CRISTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "551",
		"CHURCH_CODE": "CBOCANAA01",
		"CHURCH_NAME": "IGLESIA CANAAN SAN PEDRO MARTIR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "552",
		"CHURCH_CODE": "CBOJAZZI01",
		"CHURCH_NAME": "IGLESIA JAZZIEL DS BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "553",
		"CHURCH_CODE": "CBONEDEN01",
		"CHURCH_NAME": "NUEVO EDEN - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "554",
		"CHURCH_CODE": "CBOSILOE01",
		"CHURCH_NAME": "IGLESIA SILOE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "94",
		"CHURCH_ID": "555",
		"CHURCH_CODE": "CCEFILAD01",
		"CHURCH_NAME": "IGLESIA DE FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "556",
		"CHURCH_CODE": "CCABETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "557",
		"CHURCH_CODE": "CCACANAA01",
		"CHURCH_NAME": "IGLESIA CANAAN - CARMEN DE BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "558",
		"CHURCH_CODE": "CCACENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "559",
		"CHURCH_CODE": "CCAESTRE01",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "560",
		"CHURCH_CODE": "CCAGALIL01",
		"CHURCH_NAME": "IGLESIA GALILEA - CARMEN DE BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "561",
		"CHURCH_CODE": "CCAGETSE01",
		"CHURCH_NAME": "IGLESIA GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "562",
		"CHURCH_CODE": "CCAHOREB01",
		"CHURCH_NAME": "IGLESIA HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "563",
		"CHURCH_CODE": "CCAJUVEN01",
		"CHURCH_NAME": "IGLESIA JUVENIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "564",
		"CHURCH_CODE": "CCAMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "565",
		"CHURCH_CODE": "CCAMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE DE SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "566",
		"CHURCH_CODE": "CCANUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "567",
		"CHURCH_CODE": "CCANUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "568",
		"CHURCH_CODE": "CCAORION01",
		"CHURCH_NAME": "IGLESIA ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "569",
		"CHURCH_CODE": "CCAREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE CARMEN DE BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "570",
		"CHURCH_CODE": "CCARENAC01",
		"CHURCH_NAME": "IGLESIA RENACER OVEJAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "571",
		"CHURCH_CODE": "CCASANTA01",
		"CHURCH_NAME": "IGLESIA SANTA LUCIA ISRAEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "572",
		"CHURCH_CODE": "CCASARON01",
		"CHURCH_NAME": "IGLESIA SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "573",
		"CHURCH_CODE": "CCASINAI01",
		"CHURCH_NAME": "IGLESIA SINAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "95",
		"CHURCH_ID": "574",
		"CHURCH_CODE": "CCASIONC01",
		"CHURCH_NAME": "IGLESIA SION CHALAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "96",
		"CHURCH_ID": "575",
		"CHURCH_CODE": "CCAORION01",
		"CHURCH_NAME": "IGLESIA ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "96",
		"CHURCH_ID": "576",
		"CHURCH_CODE": "CCECENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL CARTAGENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "96",
		"CHURCH_ID": "577",
		"CHURCH_CODE": "CEBREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "97",
		"CHURCH_ID": "579",
		"CHURCH_CODE": "CSCALFAO01",
		"CHURCH_NAME": "IGLESIA ALFA Y OMEGA CHINU",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "97",
		"CHURCH_ID": "580",
		"CHURCH_CODE": "CSCBUENP01",
		"CHURCH_NAME": "IGLESIA BUEN PASTOR CAMPO BELLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "97",
		"CHURCH_ID": "581",
		"CHURCH_CODE": "CSCMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA SAN ANDRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "97",
		"CHURCH_ID": "582",
		"CHURCH_CODE": "CSCMARAN02",
		"CHURCH_NAME": "IGLESIA MARANATHA SAHAGUN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "583",
		"CHURCH_CODE": "CCEZARAG01",
		"CHURCH_NAME": "IGLESIA ZARAGOCILLA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "584",
		"CHURCH_CODE": "CEBCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "585",
		"CHURCH_CODE": "CEBCRIST01",
		"CHURCH_NAME": "IGLESIA CRISTO REDENTOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "586",
		"CHURCH_CODE": "CEBGOSEN01",
		"CHURCH_NAME": "IGLESIA GOSEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "587",
		"CHURCH_CODE": "CEBMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE SINAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "588",
		"CHURCH_CODE": "CEBRIOSD01",
		"CHURCH_NAME": "IGLESIA RIOS DE AGUA VIVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "589",
		"CHURCH_CODE": "CEBROCA01",
		"CHURCH_NAME": "IGLESIA ROCA ETERNA PASACABALLOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "98",
		"CHURCH_ID": "590",
		"CHURCH_CODE": "CEBROSAD01",
		"CHURCH_NAME": "IGLESIA ROSA DE SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "591",
		"CHURCH_CODE": "CBOBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL TURBACO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "592",
		"CHURCH_CODE": "CBOMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE DE SION TURBACO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "593",
		"CHURCH_CODE": "CBOSINAI01",
		"CHURCH_NAME": "IGLESIA SINAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "594",
		"CHURCH_CODE": "CEMCAMIN01",
		"CHURCH_NAME": "IGLESIA CAMINO A SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "595",
		"CHURCH_CODE": "CEMEMAUS01",
		"CHURCH_NAME": "IGLESIA EMAUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "596",
		"CHURCH_CODE": "CEMHITIE01",
		"CHURCH_NAME": "IGLESIA HITIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "597",
		"CHURCH_CODE": "CEMMACED01",
		"CHURCH_NAME": "IGLESIA MACEDONIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "598",
		"CHURCH_CODE": "CEMMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE CARMELO-CAÑAVERAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "599",
		"CHURCH_CODE": "CEMRIOSD01",
		"CHURCH_NAME": "IGLESIA RIOS DE AGUA VIVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "99",
		"CHURCH_ID": "600",
		"CHURCH_CODE": "CORCRIST01",
		"CHURCH_NAME": "IGLESIA CRISTO MI REDENTOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "601",
		"CHURCH_CODE": "CGUACHÍG01",
		"CHURCH_NAME": "IGLESIA ACHÍ GRUPO/NUEVA SIMIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "602",
		"CHURCH_CODE": "CGUBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL MATA DE PITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "603",
		"CHURCH_CODE": "CGUCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS GUARANDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "604",
		"CHURCH_CODE": "CGUCENTR02",
		"CHURCH_NAME": "IGLESIA CENTRO ALEGRE/REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "605",
		"CHURCH_CODE": "CGUENMAN01",
		"CHURCH_NAME": "IGLESIA ENMANUEL LAS FLORES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "606",
		"CHURCH_CODE": "CGUESTRE01",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE ORION BRISAS DEL RIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "607",
		"CHURCH_CODE": "CGUGETSE01",
		"CHURCH_NAME": "IGLESIA GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "608",
		"CHURCH_CODE": "CGUHERMO01",
		"CHURCH_NAME": "IGLESIA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "609",
		"CHURCH_CODE": "CGULIRIO01",
		"CHURCH_NAME": "IGLESIA LIRIOS DEL VALLE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "610",
		"CHURCH_CODE": "CGUMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA HUIRA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "611",
		"CHURCH_CODE": "CGUMERCE01",
		"CHURCH_NAME": "IGLESIA MERCED DE DIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "612",
		"CHURCH_CODE": "CGUMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE MORIAH/AGUACATE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "613",
		"CHURCH_CODE": "CGUNUEVO01",
		"CHURCH_NAME": "IGLESIA NUEVO EDEN EL PALMAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "614",
		"CHURCH_CODE": "CGUNUEVO02",
		"CHURCH_NAME": "IGLESIA NUEVO PARAISO PALOMAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "615",
		"CHURCH_CODE": "CGUPANDE01",
		"CHURCH_NAME": "IGLESIA PAN DE VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "616",
		"CHURCH_CODE": "CGUPARAI01",
		"CHURCH_NAME": "IGLESIA PARAISO TARULLA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "617",
		"CHURCH_CODE": "CGUPRINC01",
		"CHURCH_NAME": "IGLESIA PRINCIPE DE PAZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "618",
		"CHURCH_CODE": "CGUPUERT01",
		"CHURCH_NAME": "IGLESIA PUERTO VENECIA/ROSA DE SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "619",
		"CHURCH_CODE": "CGUPUERT02",
		"CHURCH_NAME": "IGLESIA PUERTO RICO/BERAKA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "620",
		"CHURCH_CODE": "CGURENAC01",
		"CHURCH_NAME": "IGLESIA RENACER LAS MOCHILAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "621",
		"CHURCH_CODE": "CGURIVER01",
		"CHURCH_NAME": "IGLESIA RIVERAS DE CANAAN MONTECRISTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "622",
		"CHURCH_CODE": "CGUSANMA01",
		"CHURCH_NAME": "IGLESIA SAN MATEO/RIOS DE AGUA VIVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "100",
		"CHURCH_ID": "623",
		"CHURCH_CODE": "CGUSANRA01",
		"CHURCH_NAME": "IGLESIA SAN RAFAEL/MAHANAIM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "101",
		"CHURCH_ID": "624",
		"CHURCH_CODE": "CMGCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS MAGANGUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "101",
		"CHURCH_ID": "625",
		"CHURCH_CODE": "CMGEMMAN01",
		"CHURCH_NAME": "IGLESIA EMMANUEL - MOMPOX",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "101",
		"CHURCH_ID": "626",
		"CHURCH_CODE": "CMGPORTA01",
		"CHURCH_NAME": "IGLESIA PORTALUZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "101",
		"CHURCH_ID": "627",
		"CHURCH_CODE": "CMGREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "628",
		"CHURCH_CODE": "CBAREDEN01",
		"CHURCH_NAME": "IGLESIA REDENCION - BAJO SINU",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "629",
		"CHURCH_CODE": "CMJALFAY01",
		"CHURCH_NAME": "IGLESIA ALFA Y OMEGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "630",
		"CHURCH_CODE": "CMJBELLA01",
		"CHURCH_NAME": "IGLESIA BELLA LUZ LOS PANDOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "631",
		"CHURCH_CODE": "CMJCANAA01",
		"CHURCH_NAME": "IGLESIA CANAAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "632",
		"CHURCH_CODE": "CMJEBENE01",
		"CHURCH_NAME": "IGLESIA EBENEZER - DS MAJAGUAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "633",
		"CHURCH_CODE": "CMJEDENS01",
		"CHURCH_NAME": "IGLESIA EDEN SAN ROQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "634",
		"CHURCH_CODE": "CMJEMMAN01",
		"CHURCH_NAME": "IGLESIA EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "635",
		"CHURCH_CODE": "CMJMANAN01",
		"CHURCH_NAME": "IGLESIA MANANTIAL DE VIDA GRAMALOTICO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "636",
		"CHURCH_CODE": "CMJNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "637",
		"CHURCH_CODE": "CMJNUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA LUZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "638",
		"CHURCH_CODE": "CMJREDEN01",
		"CHURCH_NAME": "IGLESIA REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "639",
		"CHURCH_CODE": "CMJREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE LOS PALMITOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "640",
		"CHURCH_CODE": "CMJSANTI01",
		"CHURCH_NAME": "IGLESIA SANTIDAD A JEHOVÁ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "641",
		"CHURCH_CODE": "CMJSARON01",
		"CHURCH_NAME": "IGLESIA SARON LOS PATOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "642",
		"CHURCH_CODE": "CMJSEDDE01",
		"CHURCH_NAME": "IGLESIA SED DE DIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "643",
		"CHURCH_CODE": "CMJSHADD01",
		"CHURCH_NAME": "IGLESIA SHADDAY PUERTO PASTRANA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "102",
		"CHURCH_ID": "644",
		"CHURCH_CODE": "CMJSUCRE01",
		"CHURCH_NAME": "IGLESIA SUCRE - SALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "645",
		"CHURCH_CODE": "CCEROCAD01",
		"CHURCH_NAME": "IGLESIA ROCA DE HORED",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "646",
		"CHURCH_CODE": "CCEUNALU01",
		"CHURCH_NAME": "IGLESIA UNALUZ EN EL LÍBANO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "647",
		"CHURCH_CODE": "CEMREDEN01",
		"CHURCH_NAME": "IGLESIA REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "648",
		"CHURCH_CODE": "CMASHALO01",
		"CHURCH_NAME": "IGLESIA SHALOM DS MARANATHA",
		"STATUS": " CARTAGENA"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "649",
		"CHURCH_CODE": "CORESTRE01",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "650",
		"CHURCH_CODE": "CORMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "103",
		"CHURCH_ID": "651",
		"CHURCH_CODE": "CORNUEVO01",
		"CHURCH_NAME": "IGLESIA NUEVO PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "652",
		"CHURCH_CODE": "CBOLUZDE01",
		"CHURCH_NAME": "IGLESIA LUZDE AURORA ARJONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "654",
		"CHURCH_CODE": "CJUDIOSH01",
		"CHURCH_NAME": "IGLESIA DIOS HABLA HOY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "655",
		"CHURCH_CODE": "CJUEMMAN01",
		"CHURCH_NAME": "IGLESIA EMMANUEL - MARIA LA BAJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "656",
		"CHURCH_CODE": "CJUESTRE01",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "657",
		"CHURCH_CODE": "CJUGERIZ01",
		"CHURCH_NAME": "IGLESIA GERIZIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "658",
		"CHURCH_CODE": "CJUNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "659",
		"CHURCH_CODE": "CJUNUEVO01",
		"CHURCH_NAME": "IGLESIA NUEVO AMANECER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "660",
		"CHURCH_CODE": "CJUPALES01",
		"CHURCH_NAME": "IGLESIA PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "104",
		"CHURCH_ID": "661",
		"CHURCH_CODE": "CJUPENIE01",
		"CHURCH_NAME": "IGLESIA PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "662",
		"CHURCH_CODE": "CMCALMAM01",
		"CHURCH_NAME": "IGLESIA ALMA MISIONERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "663",
		"CHURCH_CODE": "CMCBASAN01",
		"CHURCH_NAME": "IGLESIA BASAN MOCARI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "664",
		"CHURCH_CODE": "CMCBETAN01",
		"CHURCH_NAME": "IGLESIA BETANIA/COREA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "665",
		"CHURCH_CODE": "CMCBETAN02",
		"CHURCH_NAME": "IGLISIA BETANIA/SABANAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "666",
		"CHURCH_CODE": "CMCCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS MONTERIA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "667",
		"CHURCH_CODE": "CMCEFESO01",
		"CHURCH_NAME": "IGLESIA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "668",
		"CHURCH_CODE": "CMCESMER01",
		"CHURCH_NAME": "IGLESIA  ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "669",
		"CHURCH_CODE": "CMCHOREB01",
		"CHURCH_NAME": "IGLESIA HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "670",
		"CHURCH_CODE": "CMCPARAI01",
		"CHURCH_NAME": "IGLESIA PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "671",
		"CHURCH_CODE": "CMCREDEN01",
		"CHURCH_NAME": "IGLESIA REDENCIÓN VENEZUELA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "105",
		"CHURCH_ID": "672",
		"CHURCH_CODE": "CMCRENAC01",
		"CHURCH_NAME": "IGLESIA RENACER- EL VIDRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "674",
		"CHURCH_CODE": "CMBAGUAS01",
		"CHURCH_NAME": "IGLESIA AGUAS VIVAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "675",
		"CHURCH_CODE": "CMBBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL GUINEO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "676",
		"CHURCH_CODE": "CMBFILAD01",
		"CHURCH_NAME": "IGLESIA FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "677",
		"CHURCH_CODE": "CMBGALIL01",
		"CHURCH_NAME": "IGLESIA GALILEA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "678",
		"CHURCH_CODE": "CMBGETSE01",
		"CHURCH_NAME": "IGLESIA GETSEMANI GUINEO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "679",
		"CHURCH_CODE": "CMBMANAN01",
		"CHURCH_NAME": "IGLESIA MANANTIAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "680",
		"CHURCH_CODE": "CMBMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "681",
		"CHURCH_CODE": "CMBNAZAR01",
		"CHURCH_NAME": "IGLESIA NAZARETH REGAO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "682",
		"CHURCH_CODE": "CMBNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEN COCUELO- SALGAREÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "683",
		"CHURCH_CODE": "CMBNUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEN LIMON - DISTRIRO MONTERIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "684",
		"CHURCH_CODE": "CMBPENIE01",
		"CHURCH_NAME": "IGLESIA PENIEL DEL RIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "685",
		"CHURCH_CODE": "CMBPORTA01",
		"CHURCH_NAME": "IGLESIA PORTAL DE BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "686",
		"CHURCH_CODE": "CMBREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "687",
		"CHURCH_CODE": "CMBSINAI01",
		"CHURCH_NAME": "IGLESIA SINAI CANALETE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "106",
		"CHURCH_ID": "688",
		"CHURCH_CODE": "CMBSION01",
		"CHURCH_NAME": "IGLESIA SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "689",
		"CHURCH_CODE": "CEBENMAN01",
		"CHURCH_NAME": "IGLESIA ENMANUEL TIERRABOMBA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "690",
		"CHURCH_CODE": "CNOCAPER01",
		"CHURCH_NAME": "IGLESIA CAPERNAUM BOQUILLA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "691",
		"CHURCH_CODE": "CNOCOLIN01",
		"CHURCH_NAME": "IGLESIA COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "692",
		"CHURCH_CODE": "CNOCORAL01",
		"CHURCH_NAME": "IGLESIA LOS CORALES DS NORTE CARTAGENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "693",
		"CHURCH_CODE": "CNODIVIN01",
		"CHURCH_NAME": "IGLESIA DIVINO PASTOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "694",
		"CHURCH_CODE": "CNOENMAN01",
		"CHURCH_NAME": "IGLESIA ENMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "695",
		"CHURCH_CODE": "CNOGENEZ01",
		"CHURCH_NAME": "IGLESIA GENEZARETH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "696",
		"CHURCH_CODE": "CNOMARIA01",
		"CHURCH_NAME": "IGLESIA MARIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "697",
		"CHURCH_CODE": "CNONUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEN DS NORTE CARTAGENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "107",
		"CHURCH_ID": "698",
		"CHURCH_CODE": "CNORENAC01",
		"CHURCH_NAME": "IGLESIA RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "699",
		"CHURCH_CODE": "CEMBETES01",
		"CHURCH_NAME": "IGLESIA BETESDA VILLANUEVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "700",
		"CHURCH_CODE": "CEMBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL BAYUNCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "701",
		"CHURCH_CODE": "CEMLUZNA01",
		"CHURCH_NAME": "IGLESIA LUZ NACIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "702",
		"CHURCH_CODE": "CEMSANTA01",
		"CHURCH_NAME": "IGLESIA SANTA ROSA-MAHANAIM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "703",
		"CHURCH_CODE": "CEMSANTU01",
		"CHURCH_NAME": "IGLESIA SANTUARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "704",
		"CHURCH_CODE": "CORCAMIN01",
		"CHURCH_NAME": "IGLESIA CAMINANDO CON CRISTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "705",
		"CHURCH_CODE": "CORFLORD01",
		"CHURCH_NAME": "IGLESIA FLOR DEL CAMPO-ALFA Y OMEGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "706",
		"CHURCH_CODE": "CORGALIL01",
		"CHURCH_NAME": "IGLESIA GALILEA POZON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "707",
		"CHURCH_CODE": "CORLAODI01",
		"CHURCH_NAME": "IGLESIA LAODICEA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "708",
		"CHURCH_CODE": "CORMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE DE SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "709",
		"CHURCH_CODE": "CORPENIE01",
		"CHURCH_NAME": "IGLESIA PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "710",
		"CHURCH_CODE": "CORRENAC01",
		"CHURCH_NAME": "IGLESIA RENACER ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "108",
		"CHURCH_ID": "711",
		"CHURCH_CODE": "CORROSAD01",
		"CHURCH_NAME": "IGLESIA ROSA DE SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "712",
		"CHURCH_CODE": "CPLCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL PLATO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "713",
		"CHURCH_CODE": "CPLCHIVO01",
		"CHURCH_NAME": "IGLESIA CHIVOLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "714",
		"CHURCH_CODE": "CPLENMAN01",
		"CHURCH_NAME": "IGLESIA ENMANUEL PLATO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "715",
		"CHURCH_CODE": "CPLNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM-CORDOBA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "716",
		"CHURCH_CODE": "CPLORION02",
		"CHURCH_NAME": "IGLESIA ORION ZAMBRANO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "717",
		"CHURCH_CODE": "CPLRIOSD01",
		"CHURCH_NAME": "IGLESIA RIOS DE AGUA VIVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "718",
		"CHURCH_CODE": "CPLSANLU01",
		"CHURCH_NAME": "IGLESIA SAN LUIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "719",
		"CHURCH_CODE": "CPLZARAT01",
		"CHURCH_NAME": "IGLESIA ZARATE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "109",
		"CHURCH_ID": "720",
		"CHURCH_CODE": "CVANUEVA04",
		"CHURCH_NAME": "IGLESIA NUEVA EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "721",
		"CHURCH_CODE": "CJOAYAPE01",
		"CHURCH_NAME": "IGLESIA AYAPEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "722",
		"CHURCH_CODE": "CJOENMAN01",
		"CHURCH_NAME": "IGLESIA EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "723",
		"CHURCH_CODE": "CJOGERIZ01",
		"CHURCH_NAME": "IGLESIA GERIZIN PLANETA RICA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "724",
		"CHURCH_CODE": "CJOMONTE01",
		"CHURCH_NAME": "IGLESIA MONTELIBANO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "725",
		"CHURCH_CODE": "CJOREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE DS SANJORGE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "726",
		"CHURCH_CODE": "CJOSINAI01",
		"CHURCH_NAME": "IGLESIA SINAI SAN MARCOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "727",
		"CHURCH_CODE": "CJOVARAL01",
		"CHURCH_NAME": "IGLESIA EL VARAL  - DISTRITO SAN JORGE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "110",
		"CHURCH_ID": "728",
		"CHURCH_CODE": "CJOVILLA01",
		"CHURCH_NAME": "IGLESIA VILLA ROCIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "730",
		"CHURCH_CODE": "CJUCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL SAN JUAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "731",
		"CHURCH_CODE": "CJUCRIST01",
		"CHURCH_NAME": "IGLESIA CRISTO REDENTOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "732",
		"CHURCH_CODE": "CJUNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "733",
		"CHURCH_CODE": "CJUNUEVO02",
		"CHURCH_NAME": "IGLESIA NUEVO EDEN SAN JACINTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "734",
		"CHURCH_CODE": "CJURENAC01",
		"CHURCH_NAME": "IGLESIA RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "735",
		"CHURCH_CODE": "CJUROSAD01",
		"CHURCH_NAME": "IGLESIA ROSA DE SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "111",
		"CHURCH_ID": "736",
		"CHURCH_CODE": "CJUSARDI01",
		"CHURCH_NAME": "IGLESIA SARDIS HAYAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "112",
		"CHURCH_ID": "737",
		"CHURCH_CODE": "CMGBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL - SAN SEBASTIAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "112",
		"CHURCH_ID": "738",
		"CHURCH_CODE": "CMGCOCO01",
		"CHURCH_NAME": "IGLESIA MANANTIAL - EL COCO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "112",
		"CHURCH_ID": "739",
		"CHURCH_CODE": "CMGEMMAN01",
		"CHURCH_NAME": "IGLESIA EMMANUEL - MOMPOX",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "112",
		"CHURCH_ID": "740",
		"CHURCH_CODE": "CMGTRONC01",
		"CHURCH_NAME": "IGLESIA EBENEZER - TRONCOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "741",
		"CHURCH_CODE": "CSCCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS SINCELEJO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "742",
		"CHURCH_CODE": "CSCDIVIN01",
		"CHURCH_NAME": "IGLESIA DIVINO SALVADOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "743",
		"CHURCH_CODE": "CSCGUAIM01",
		"CHURCH_NAME": "IGLESIA GUAIMI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "744",
		"CHURCH_CODE": "CSCNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "745",
		"CHURCH_CODE": "CSCPIONE01",
		"CHURCH_NAME": "IGLESIA PIONEROS CON JESUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "746",
		"CHURCH_CODE": "CSCREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE LA SELVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "747",
		"CHURCH_CODE": "CSCRENAC01",
		"CHURCH_NAME": "IGLESIA RENACER SAMPUES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "748",
		"CHURCH_CODE": "CSCRENAC02",
		"CHURCH_NAME": "IGLESIA RENACER ALTO SABANA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "113",
		"CHURCH_ID": "749",
		"CHURCH_CODE": "CSCSALEM01",
		"CHURCH_NAME": "IGLESIA SALEM - SINCELEJO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "750",
		"CHURCH_CODE": "CSNAGAPE01",
		"CHURCH_NAME": "IGLESIA AGAPE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "751",
		"CHURCH_CODE": "CSNEDEN01",
		"CHURCH_NAME": "IGLESIA EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "752",
		"CHURCH_CODE": "CSNGENEZ01",
		"CHURCH_NAME": "IGLESIA GENEZAREH MACAYEPOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "753",
		"CHURCH_CODE": "CSNLUZDE01",
		"CHURCH_NAME": "IGLESIA LUZ DE ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "754",
		"CHURCH_CODE": "CSNMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA MACAJAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "755",
		"CHURCH_CODE": "CSNMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE HOREB LA PICHI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "756",
		"CHURCH_CODE": "CSNNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "757",
		"CHURCH_CODE": "CSNNUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "758",
		"CHURCH_CODE": "CSNNUEVO01",
		"CHURCH_NAME": "IGLESIA NUEVO EDEN - SINCELEJO NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "759",
		"CHURCH_CODE": "CSNPARAI01",
		"CHURCH_NAME": "IGLESIA PARAISO COROZAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "760",
		"CHURCH_CODE": "CSNPAZ01",
		"CHURCH_NAME": "IGLESIA PAZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "761",
		"CHURCH_CODE": "CSNREMAN01",
		"CHURCH_NAME": "IGLESIA REMANENTE SINCE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "762",
		"CHURCH_CODE": "CSNRENAC01",
		"CHURCH_NAME": "IGLESIA RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "763",
		"CHURCH_CODE": "CSNROSAD01",
		"CHURCH_NAME": "IGLESIA ROSA DE SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "764",
		"CHURCH_CODE": "CSNSINAI01",
		"CHURCH_NAME": "IGLESIA SINAI AGUACATE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "114",
		"CHURCH_ID": "765",
		"CHURCH_CODE": "CSNVERDE01",
		"CHURCH_NAME": "IGLESIA EL VERGEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "767",
		"CHURCH_CODE": "CTCBELEN01",
		"CHURCH_NAME": "IGLESIA BELEN PALMIRAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "768",
		"CHURCH_CODE": "CTCBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "769",
		"CHURCH_CODE": "CTCBETSA01",
		"CHURCH_NAME": "IGLESIA BETSAIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "770",
		"CHURCH_CODE": "CTCCALEB01",
		"CHURCH_NAME": "IGLESIA CALEB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "771",
		"CHURCH_CODE": "CTCCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL TIERRA ALTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "772",
		"CHURCH_CODE": "CTCEFESO01",
		"CHURCH_NAME": "IGLESIA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "773",
		"CHURCH_CODE": "CTCGENEZ01",
		"CHURCH_NAME": "IGLESIA GENEZARETH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "774",
		"CHURCH_CODE": "CTCHEBRO01",
		"CHURCH_NAME": "IGLESIA HEBRON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "775",
		"CHURCH_CODE": "CTCMONTE01",
		"CHURCH_NAME": "IGLESIA MONTE HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "776",
		"CHURCH_CODE": "CTCNUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "777",
		"CHURCH_CODE": "CTCOLIVO01",
		"CHURCH_NAME": "IGLESIA OLIVOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "778",
		"CHURCH_CODE": "CTCPARAI01",
		"CHURCH_NAME": "IGLESIA PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "115",
		"CHURCH_ID": "779",
		"CHURCH_CODE": "CTCSARDI01",
		"CHURCH_NAME": "IGLESIA SARDIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "780",
		"CHURCH_CODE": "CTOCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL JESUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "781",
		"CHURCH_CODE": "CTOAPOSE01",
		"CHURCH_NAME": "IGLESIA APOSENTO ALTO LAS CLARAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "782",
		"CHURCH_CODE": "CTOBEREA01",
		"CHURCH_NAME": "IGLESIA BEREA ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "783",
		"CHURCH_CODE": "CTOBETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "784",
		"CHURCH_CODE": "CTOCANAA01",
		"CHURCH_NAME": "IGLESIA CANAAN FRASQUILLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "785",
		"CHURCH_CODE": "CTOCENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL JESUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "786",
		"CHURCH_CODE": "CTODICHA01",
		"CHURCH_NAME": "IGLESIA DICHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "787",
		"CHURCH_CODE": "CTODIOSP01",
		"CHURCH_NAME": "IGLESIA DIOS PROVEERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "788",
		"CHURCH_CODE": "CTOEBENE01",
		"CHURCH_NAME": "IGLESIA EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "789",
		"CHURCH_CODE": "CTOENMAN01",
		"CHURCH_NAME": "IGLESIA ENMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "790",
		"CHURCH_CODE": "CTOFILAD01",
		"CHURCH_NAME": "IGLESIA FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "791",
		"CHURCH_CODE": "CTOJARDI01",
		"CHURCH_NAME": "IGLESIA JARDIN DEL EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "792",
		"CHURCH_CODE": "CTOMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "793",
		"CHURCH_CODE": "CTONAZAR01",
		"CHURCH_NAME": "IGLESIA NAZARETH TORO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "794",
		"CHURCH_CODE": "CTONUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA ORSINA - DISTRITO TIERRA ALTA OCCIDE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "795",
		"CHURCH_CODE": "CTONUEVO01",
		"CHURCH_NAME": "IGLESIA NUEVO EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "796",
		"CHURCH_CODE": "CTOORION01",
		"CHURCH_NAME": "IGLESIA ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "797",
		"CHURCH_CODE": "CTOPENIE01",
		"CHURCH_NAME": "IGLESIA PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "798",
		"CHURCH_CODE": "CTOSILOL01",
		"CHURCH_NAME": "IGLESIA SILO LA OSSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "116",
		"CHURCH_ID": "799",
		"CHURCH_CODE": "CTOSINAI01",
		"CHURCH_NAME": "IGLESIA SINAI LAS DELICIAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "800",
		"CHURCH_CODE": "CVABETHE01",
		"CHURCH_NAME": "IGLESIA BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "801",
		"CHURCH_CODE": "CVACENTR01",
		"CHURCH_NAME": "IGLESIA CENTRAL DS VALENCIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "802",
		"CHURCH_CODE": "CVAEMBAJ01",
		"CHURCH_NAME": "IGLESIA EMBAJADORES DE CRISTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "803",
		"CHURCH_CODE": "CVAESTRE01",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "804",
		"CHURCH_CODE": "CVAFILAD01",
		"CHURCH_NAME": "IGLESIA FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "805",
		"CHURCH_CODE": "CVAGALIL01",
		"CHURCH_NAME": "IGLESIA DE GALILEA - DISTRITO VALENCIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "806",
		"CHURCH_CODE": "CVALIRIO01",
		"CHURCH_NAME": "IGLESIA LIRIOS DEL VALLE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "807",
		"CHURCH_CODE": "CVALUZVI01",
		"CHURCH_NAME": "IGLESIA LUZ Y VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "808",
		"CHURCH_CODE": "CVAMARAN01",
		"CHURCH_NAME": "IGLESIA MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "809",
		"CHURCH_CODE": "CVANUEVA01",
		"CHURCH_NAME": "IGLESIA NUEVA SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "810",
		"CHURCH_CODE": "CVANUEVA02",
		"CHURCH_NAME": "IGLESIA NUEVA LUZ - DISTRITO VALENCIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "811",
		"CHURCH_CODE": "CVANUEVA03",
		"CHURCH_NAME": "IGLESIA NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "812",
		"CHURCH_CODE": "CVANUEVA04",
		"CHURCH_NAME": "IGLESIA NUEVA EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "813",
		"CHURCH_CODE": "CVANUEVA05",
		"CHURCH_NAME": "IGLESIA NUEVA JERUSALEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "814",
		"CHURCH_CODE": "CVAPENIE01",
		"CHURCH_NAME": "IGLESIA PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "815",
		"CHURCH_CODE": "CVASALEM01",
		"CHURCH_NAME": "IGLESIA SALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "816",
		"CHURCH_CODE": "CVASARDI01",
		"CHURCH_NAME": "IGLESIA SARDIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "817",
		"CHURCH_CODE": "CVASINAI01",
		"CHURCH_NAME": "IGLESIA SINAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "818",
		"CHURCH_CODE": "CVASION01",
		"CHURCH_NAME": "IGLESIA SION 20 DE ENERO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "117",
		"CHURCH_ID": "819",
		"CHURCH_CODE": "CVAUNION01",
		"CHURCH_NAME": "IGLESIA UNION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "118",
		"CHURCH_ID": "820",
		"CHURCH_CODE": "CBETAN02",
		"CHURCH_NAME": "BETANIA -  ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "118",
		"CHURCH_ID": "821",
		"CHURCH_CODE": "CCOSTA01",
		"CHURCH_NAME": "COSTA HERMOSA -  ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "118",
		"CHURCH_ID": "822",
		"CHURCH_CODE": "CCRAVO01",
		"CHURCH_NAME": "CRAVONORTE -  ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "118",
		"CHURCH_ID": "823",
		"CHURCH_CODE": "CMARAN07",
		"CHURCH_NAME": "GRUPO MARANATHA-ARAUCA BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "118",
		"CHURCH_ID": "824",
		"CHURCH_CODE": "CPARAI01",
		"CHURCH_NAME": "PARAISO -  ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "119",
		"CHURCH_ID": "825",
		"CHURCH_CODE": "CRENAC08",
		"CHURCH_NAME": "RENACER - DS. ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "119",
		"CHURCH_ID": "826",
		"CHURCH_CODE": "CARAUC01",
		"CHURCH_NAME": "ARAUCA -  ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "123",
		"CHURCH_ID": "827",
		"CHURCH_CODE": "CESTRE01",
		"CHURCH_NAME": "ESTRELLA DEL AMANECER -  CUCUTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "119",
		"CHURCH_ID": "828",
		"CHURCH_CODE": "CESTRE02",
		"CHURCH_NAME": "IGLESIA ESTRELLA DE JACOB-DS. ARAUCA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "119",
		"CHURCH_ID": "829",
		"CHURCH_CODE": "CJIREH01",
		"CHURCH_NAME": "IGLESIA JIREH DS. ARACA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "119",
		"CHURCH_ID": "831",
		"CHURCH_CODE": "CSARON01",
		"CHURCH_NAME": "SARÓN -  ARAUCA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "832",
		"CHURCH_CODE": "CALFAY05",
		"CHURCH_NAME": "GRUPO ALFA Y OMEGA-DS. ARAUQUITA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "833",
		"CHURCH_CODE": "CARAUQ01",
		"CHURCH_NAME": "ARAUQUITA -  ARAUQUITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "834",
		"CHURCH_CODE": "CBENDI01",
		"CHURCH_NAME": "BENDICIÓN- REINER -  ARAUQUITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "835",
		"CHURCH_CODE": "CBRASI01",
		"CHURCH_NAME": "BRASILIA -  ARAUQUITA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "836",
		"CHURCH_CODE": "CCAUCH01",
		"CHURCH_NAME": "EL CAUCHO - ARAUQUITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "837",
		"CHURCH_CODE": "CENMAN02",
		"CHURCH_NAME": "ENMANUEL-PESQUERA -  ARAUQUITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "838",
		"CHURCH_CODE": "CHASHE01",
		"CHURCH_NAME": "HASHEN-PERALONSO -  ARAUQUITA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "839",
		"CHURCH_CODE": "CMARAN01",
		"CHURCH_NAME": "MARANATHA -  ARAUQUITA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "840",
		"CHURCH_CODE": "CPENIE05",
		"CHURCH_NAME": "PENIEL - ARAUQUITA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "841",
		"CHURCH_CODE": "CREDEN04",
		"CHURCH_NAME": "REDENCION-ARAQUITA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "842",
		"CHURCH_CODE": "CREMAN02",
		"CHURCH_NAME": "FILIAL REMANENTE - ARAUQUITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "120",
		"CHURCH_ID": "843",
		"CHURCH_CODE": "CRENAC01",
		"CHURCH_NAME": "RENACER-MAPORITA -  ARAUQUITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "844",
		"CHURCH_CODE": "CBETHE01",
		"CHURCH_NAME": "BETHEL -  BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "845",
		"CHURCH_CODE": "CCANAA01",
		"CHURCH_NAME": "CANAAN -  SARAVENA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "846",
		"CHURCH_CODE": "CEMAUS04",
		"CHURCH_NAME": "EMAUS DS. BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "847",
		"CHURCH_CODE": "CGENEZ01",
		"CHURCH_NAME": "GENEZARETH -  BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "848",
		"CHURCH_CODE": "CHOREB03",
		"CHURCH_NAME": "HOREB -  SARAVENA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "849",
		"CHURCH_CODE": "CPARAI05",
		"CHURCH_NAME": "PARAISO - BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "850",
		"CHURCH_CODE": "CPENIE01",
		"CHURCH_NAME": "PENIEL -  BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "121",
		"CHURCH_ID": "851",
		"CHURCH_CODE": "CSHADA01",
		"CHURCH_NAME": "SHADAY- BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "852",
		"CHURCH_CODE": "CAMANE01",
		"CHURCH_NAME": "AMANECER - FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "853",
		"CHURCH_CODE": "CBETAN01",
		"CHURCH_NAME": "BETANIA MALVINAS -  TAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "854",
		"CHURCH_CODE": "CCALEB01",
		"CHURCH_NAME": "IGLESIA CALEB DS. CARANAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "855",
		"CHURCH_CODE": "CGETSE01",
		"CHURCH_NAME": "GETSEMANY -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "856",
		"CHURCH_CODE": "CHOREB07",
		"CHURCH_NAME": "HOREB-FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "857",
		"CHURCH_CODE": "CMIESI01",
		"CHURCH_NAME": "MIES -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "858",
		"CHURCH_CODE": "CNUEVO01",
		"CHURCH_NAME": "NUEVO EDEN -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "859",
		"CHURCH_CODE": "CREMAN01",
		"CHURCH_NAME": "REMANENTE -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "860",
		"CHURCH_CODE": "CRENAC07",
		"CHURCH_NAME": "RENACER - FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "122",
		"CHURCH_ID": "861",
		"CHURCH_CODE": "CSAMAI01",
		"CHURCH_NAME": "SAMA -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "123",
		"CHURCH_ID": "862",
		"CHURCH_CODE": "CCENTR01",
		"CHURCH_NAME": "CENTRAL DE CÚCUTA -  CUCUTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "123",
		"CHURCH_ID": "863",
		"CHURCH_CODE": "CEMBAJ01",
		"CHURCH_NAME": "EMBAJADORES DE CRISTO-CUCUTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "123",
		"CHURCH_ID": "865",
		"CHURCH_CODE": "CSHALO03",
		"CHURCH_NAME": "FILIAL SHALOM - D. CUCUTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "123",
		"CHURCH_ID": "866",
		"CHURCH_CODE": "CSMIRN01",
		"CHURCH_NAME": "SMIRNA -  CUCUTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "123",
		"CHURCH_ID": "867",
		"CHURCH_CODE": "CTABIR01",
		"CHURCH_NAME": "TABIRO - CUCUTA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "868",
		"CHURCH_CODE": "CBETAN06",
		"CHURCH_NAME": "IG. BETANIA DS. ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "869",
		"CHURCH_CODE": "CEBENE02",
		"CHURCH_NAME": "EBENEZER-OASIS -  DS LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "870",
		"CHURCH_CODE": "CEDENI01",
		"CHURCH_NAME": "EDEN -  DS ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "871",
		"CHURCH_CODE": "CENMAN06",
		"CHURCH_NAME": "IG. ENMANUEL DS. LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "872",
		"CHURCH_CODE": "CHOREB02",
		"CHURCH_NAME": "HOREB- SAN LUIS -  DS LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "873",
		"CHURCH_CODE": "CJERUS02",
		"CHURCH_NAME": "JERUSALEN -  DS LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "874",
		"CHURCH_CODE": "CMAHAN01",
		"CHURCH_NAME": "MAHANAIN -  DS LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "875",
		"CHURCH_CODE": "CRENAC011",
		"CHURCH_NAME": "IG. RENACER DS. LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "876",
		"CHURCH_CODE": "CSARON02",
		"CHURCH_NAME": "SARON -  DS LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "124",
		"CHURCH_ID": "877",
		"CHURCH_CODE": "CSILOE02",
		"CHURCH_NAME": "SILOE -  DS LA ESMERALDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "878",
		"CHURCH_CODE": "CBETAN05",
		"CHURCH_NAME": "BETANIA - FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "879",
		"CHURCH_CODE": "CCAÑOC01",
		"CHURCH_NAME": "CAÑO CRISTAL -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "880",
		"CHURCH_CODE": "CFORTU01",
		"CHURCH_NAME": "FORTUL -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "881",
		"CHURCH_CODE": "CHEBRO02",
		"CHURCH_NAME": "IG. HEBRON DS. FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "882",
		"CHURCH_CODE": "CLAHER01",
		"CHURCH_NAME": "LA HERMOSA - FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "883",
		"CHURCH_CODE": "CORION01",
		"CHURCH_NAME": "ORIÓN -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "125",
		"CHURCH_ID": "884",
		"CHURCH_CODE": "CSUCOT01",
		"CHURCH_NAME": "SUCOT  -  FORTUL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "885",
		"CHURCH_CODE": "CHOREB01",
		"CHURCH_NAME": "HOREB",
		"STATUS": " LA HERMOSA -  DS HOREB"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "886",
		"CHURCH_CODE": "CMARAN06",
		"CHURCH_NAME": "MARANATHA - DS HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "887",
		"CHURCH_CODE": "CMARAN08",
		"CHURCH_NAME": "IGLESIA MARANATHA LA FLORIDA DS HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "888",
		"CHURCH_CODE": "CNAZAR01",
		"CHURCH_NAME": "IGLESIA NAZARETH DS HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "889",
		"CHURCH_CODE": "CPENIE06",
		"CHURCH_NAME": "PENIEL- DS. HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "890",
		"CHURCH_CODE": "CRENAC06",
		"CHURCH_NAME": "RENACER - DS HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "891",
		"CHURCH_CODE": "CSALEN01",
		"CHURCH_NAME": "SALEN -  DS HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "126",
		"CHURCH_ID": "892",
		"CHURCH_CODE": "CSILOE01",
		"CHURCH_NAME": "SILOE -  DS HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "893",
		"CHURCH_CODE": "CBETAN04",
		"CHURCH_NAME": "BETENIA -  JUAN ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "894",
		"CHURCH_CODE": "CCALED01",
		"CHURCH_NAME": "CALED -  JUAN ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "895",
		"CHURCH_CODE": "CENMAN04",
		"CHURCH_NAME": "ENMANUEL -  JUAN ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "896",
		"CHURCH_CODE": "CFILAD04",
		"CHURCH_NAME": "FILADELFIA -  JUAN ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "897",
		"CHURCH_CODE": "CGETSE05",
		"CHURCH_NAME": "GETSEMANY -  JUAN ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "898",
		"CHURCH_CODE": "CMARAN03",
		"CHURCH_NAME": "MARANATHA -  JUAN ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "127",
		"CHURCH_ID": "899",
		"CHURCH_CODE": "CNVAES01",
		"CHURCH_NAME": "IGLESIA NUEVAS ESPERANZA - DS ATALAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "900",
		"CHURCH_CODE": "CCANAA04",
		"CHURCH_NAME": "CANAAN -  DS LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "901",
		"CHURCH_CODE": "CJEZRE01",
		"CHURCH_NAME": "IGLESIA JEZREEL-DS. LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "902",
		"CHURCH_CODE": "CLAROC01",
		"CHURCH_NAME": "LA ROCA -  LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "903",
		"CHURCH_CODE": "CNUEVA03",
		"CHURCH_NAME": "NUEVA ESPERANZA -  LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "904",
		"CHURCH_CODE": "COVEJA01",
		"CHURCH_NAME": "OVEJAS -  LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "905",
		"CHURCH_CODE": "CPENIE03",
		"CHURCH_NAME": "PENIEL -  LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "128",
		"CHURCH_ID": "906",
		"CHURCH_CODE": "CTORCO03",
		"CHURCH_NAME": "GRUPO TORCOROMA 3 - LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "907",
		"CHURCH_CODE": "CGETSE02",
		"CHURCH_NAME": "GETSEMANY -  DS MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "908",
		"CHURCH_CODE": "CHERAL01",
		"CHURCH_NAME": "HERALDO -  DS MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "909",
		"CHURCH_CODE": "CMARAN02",
		"CHURCH_NAME": "MARANATHA -  DS MARANTHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "910",
		"CHURCH_CODE": "CSARON03",
		"CHURCH_NAME": "SARON -  DS MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "911",
		"CHURCH_CODE": "CSHALO02",
		"CHURCH_NAME": "SHALON -  DS MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "912",
		"CHURCH_CODE": "CSILOE03",
		"CHURCH_NAME": "SILOE -  DS MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "129",
		"CHURCH_ID": "913",
		"CHURCH_CODE": "CSIONI01",
		"CHURCH_NAME": "SION -  DS MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "914",
		"CHURCH_CODE": "CBETHE03",
		"CHURCH_NAME": "IGLESIA BETHEL-ABREGO-DS. OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "915",
		"CHURCH_CODE": "CCANAA06",
		"CHURCH_NAME": "CANAAN DS. OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "916",
		"CHURCH_CODE": "CEBENE05",
		"CHURCH_NAME": "EBENEZER CHITAGA - PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "917",
		"CHURCH_CODE": "CEDENI04",
		"CHURCH_NAME": "EDEN -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "918",
		"CHURCH_CODE": "CEMAUS01",
		"CHURCH_NAME": "EMAUS -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "919",
		"CHURCH_CODE": "CFILAD05",
		"CHURCH_NAME": "FILADEFIA -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "920",
		"CHURCH_CODE": "CGALIL01",
		"CHURCH_NAME": "GALILEA -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "921",
		"CHURCH_CODE": "CGETSE06",
		"CHURCH_NAME": "GETSEMANY-OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "922",
		"CHURCH_CODE": "CGRAFA01",
		"CHURCH_NAME": "GRAN FARO - PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "923",
		"CHURCH_CODE": "CMAHAN03",
		"CHURCH_NAME": "MAHANAIN -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "924",
		"CHURCH_CODE": "CMARAN05",
		"CHURCH_NAME": "MARANATHA -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "925",
		"CHURCH_CODE": "CNUEVA01",
		"CHURCH_NAME": "NUEVA JERUSALEN  -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "926",
		"CHURCH_CODE": "COCAÑA01",
		"CHURCH_NAME": "OCAÑA -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "927",
		"CHURCH_CODE": "CPENIEL04",
		"CHURCH_NAME": "PENIEL - PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "928",
		"CHURCH_CODE": "CREDEN02",
		"CHURCH_NAME": "REDENCIÓN -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "929",
		"CHURCH_CODE": "CRENAC05",
		"CHURCH_NAME": "RENACER -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "930",
		"CHURCH_CODE": "CSALVA01",
		"CHURCH_NAME": "SALVACIÓN -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "931",
		"CHURCH_CODE": "CSAMAR01",
		"CHURCH_NAME": "SILOE - (SAMARIA) -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "932",
		"CHURCH_CODE": "CSAMAR02",
		"CHURCH_NAME": "SAMARITANA -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "133",
		"CHURCH_ID": "933",
		"CHURCH_CODE": "CSARON04",
		"CHURCH_NAME": "IG. SARON DS. PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "934",
		"CHURCH_CODE": "CSILOE04",
		"CHURCH_NAME": "SILOE - OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "130",
		"CHURCH_ID": "935",
		"CHURCH_CODE": "CSIONI03",
		"CHURCH_NAME": "SIÓN -  OCAÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "943",
		"CHURCH_CODE": "CEBENE03",
		"CHURCH_NAME": "EBENEZER -  PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "944",
		"CHURCH_CODE": "CGERIZ01",
		"CHURCH_NAME": "IG. GERIZIM DS. PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "945",
		"CHURCH_CODE": "CGLOND01",
		"CHURCH_NAME": "GLONDRINAS -  PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "946",
		"CHURCH_CODE": "CHOREB08",
		"CHURCH_NAME": "HOREB-DS. PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "947",
		"CHURCH_CODE": "CMONTE01",
		"CHURCH_NAME": "MONTE DE LOS OLIVOS -  PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "948",
		"CHURCH_CODE": "CPALES01",
		"CHURCH_NAME": "PALESTINA -  PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "949",
		"CHURCH_CODE": "CPARAI02",
		"CHURCH_NAME": "PARAISO -  PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "132",
		"CHURCH_ID": "950",
		"CHURCH_CODE": "CVOZDE01",
		"CHURCH_NAME": "VOZ DE SALVACIÓN -  PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "960",
		"CHURCH_CODE": "CCANAA03",
		"CHURCH_NAME": "CANAAN -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "961",
		"CHURCH_CODE": "CCRIRE01",
		"CHURCH_NAME": "CRISTO RENDENTOR-PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "962",
		"CHURCH_CODE": "CEMAUS02",
		"CHURCH_NAME": "EMAUS -  PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "963",
		"CHURCH_CODE": "CFILAD03",
		"CHURCH_NAME": "FILADELFIA -  PAMPLONA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "964",
		"CHURCH_CODE": "CGALAA01",
		"CHURCH_NAME": "GALAAD -  PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "965",
		"CHURCH_CODE": "CHOREB04",
		"CHURCH_NAME": "HOREB -  PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "966",
		"CHURCH_CODE": "CJERUS04",
		"CHURCH_NAME": "JERUSALEN -  PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "967",
		"CHURCH_CODE": "CPARAI03",
		"CHURCH_NAME": "PARAISO BOCHALEMA -  PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "134",
		"CHURCH_ID": "968",
		"CHURCH_CODE": "CSINAI02",
		"CHURCH_NAME": "SINAI -  PATIOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "969",
		"CHURCH_CODE": "CALFAY01",
		"CHURCH_NAME": "ALFA Y OMEGA -  PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "970",
		"CHURCH_CODE": "CBUENO01",
		"CHURCH_NAME": "BUENOS AIRES -  PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "971",
		"CHURCH_CODE": "CBUENP01",
		"CHURCH_NAME": "EL BUEN PASTOR -  PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "972",
		"CHURCH_CODE": "CENMAN07",
		"CHURCH_NAME": "IG. ENMANUEL DS.P NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "973",
		"CHURCH_CODE": "CJERUS06",
		"CHURCH_NAME": "IG. JERUSALEM DS. PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "974",
		"CHURCH_CODE": "CJOHOV01",
		"CHURCH_NAME": "JEHOVA NISI - PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "975",
		"CHURCH_CODE": "CJORDA01",
		"CHURCH_NAME": "JORDAN -  PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "976",
		"CHURCH_CODE": "CMORIA01",
		"CHURCH_NAME": "IGLESIA MORIAH-DS.PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "977",
		"CHURCH_CODE": "COLIVA01",
		"CHURCH_NAME": "OLIVARES -  PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "978",
		"CHURCH_CODE": "CSHADA02",
		"CHURCH_NAME": "IGLESIA SHADAY DS. PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "979",
		"CHURCH_CODE": "CSHALO01",
		"CHURCH_NAME": "SHALON",
		"STATUS": " PANAMA -  PUEBLO NUEVO"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "980",
		"CHURCH_CODE": "CSINAI03",
		"CHURCH_NAME": "SINAI - PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "981",
		"CHURCH_CODE": "CVERGE01",
		"CHURCH_NAME": "EL VERGEL -  PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "135",
		"CHURCH_ID": "982",
		"CHURCH_CODE": "CZARET01",
		"CHURCH_NAME": "ZARET-SAHAR DS PUEBLO NUEVO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "984",
		"CHURCH_CODE": "CBETAN03",
		"CHURCH_NAME": "BETANIA -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "985",
		"CHURCH_CODE": "CCANAA02",
		"CHURCH_NAME": "CANAAN -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "986",
		"CHURCH_CODE": "CEMAUS03",
		"CHURCH_NAME": "EMAUS - REDECUBARA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "987",
		"CHURCH_CODE": "CFILAD02",
		"CHURCH_NAME": "FILADELFIA -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "988",
		"CHURCH_CODE": "CGETSE03",
		"CHURCH_NAME": "GETSEMANY -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "989",
		"CHURCH_CODE": "CHOREB06",
		"CHURCH_NAME": "GRUPO HOREB - REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "990",
		"CHURCH_CODE": "CJERUS07",
		"CHURCH_NAME": "JERUSALEM DS. CUBARA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "991",
		"CHURCH_CODE": "CMAHAN02",
		"CHURCH_NAME": "MAHANAIN -  REDENCION-CUBARA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "992",
		"CHURCH_CODE": "CMANAN02",
		"CHURCH_NAME": "IG. MANANTIAL DS. CUBARA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "993",
		"CHURCH_CODE": "CMONTE02",
		"CHURCH_NAME": "MONTE CARMELO -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "994",
		"CHURCH_CODE": "CORION02",
		"CHURCH_NAME": "ORION -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "995",
		"CHURCH_CODE": "CPENIE02",
		"CHURCH_NAME": "PENIEL -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "996",
		"CHURCH_CODE": "CSINAI01",
		"CHURCH_NAME": "SINAI -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "136",
		"CHURCH_ID": "997",
		"CHURCH_CODE": "CSIONG01",
		"CHURCH_NAME": "GRUPO SION - REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "998",
		"CHURCH_CODE": "CALFAY02",
		"CHURCH_NAME": "ALFA Y OMEGA -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "999",
		"CHURCH_CODE": "CEBENE04",
		"CHURCH_NAME": "EBENEZER -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "1000",
		"CHURCH_CODE": "CENMAN03",
		"CHURCH_NAME": "ENMANUEL -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "1001",
		"CHURCH_CODE": "CFILAD06",
		"CHURCH_NAME": "IG. FILADELFIA- DS. REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "1002",
		"CHURCH_CODE": "CJERUS08",
		"CHURCH_NAME": "GRUPO JERUSALEN - DS REDENCIÓN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "1003",
		"CHURCH_CODE": "CPTORI01",
		"CHURCH_NAME": "PUERTO RICO-DS REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "1004",
		"CHURCH_CODE": "CREDEN01",
		"CHURCH_NAME": "REDENCIÓN -  REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "137",
		"CHURCH_ID": "1005",
		"CHURCH_CODE": "CRENAC02",
		"CHURCH_NAME": "RENACER -  SARAVENA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "138",
		"CHURCH_ID": "1006",
		"CHURCH_CODE": "CCASAO01",
		"CHURCH_NAME": "CASA DE ORACION QUINTA ORIENTAL-DS. RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "138",
		"CHURCH_ID": "1007",
		"CHURCH_CODE": "CJERIC01",
		"CHURCH_NAME": "JERICO -  LIBERTAD",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "138",
		"CHURCH_ID": "1008",
		"CHURCH_CODE": "CMANAN03",
		"CHURCH_NAME": "IG. MANANTIAL DS. RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "138",
		"CHURCH_ID": "1009",
		"CHURCH_CODE": "CRENAC03",
		"CHURCH_NAME": "RENACER - RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "139",
		"CHURCH_ID": "1010",
		"CHURCH_CODE": "CEDENI02",
		"CHURCH_NAME": "EDEN -  REDENCION (SARAVENA CENTRAL)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "139",
		"CHURCH_ID": "1011",
		"CHURCH_CODE": "CRENAC10",
		"CHURCH_NAME": "RENACER-SARAVENA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "139",
		"CHURCH_ID": "1012",
		"CHURCH_CODE": "CSARAV01",
		"CHURCH_NAME": "SARAVENA CENTRAL -  SARAVENA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1013",
		"CHURCH_CODE": "CBETHE02",
		"CHURCH_NAME": "BETHEL -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1014",
		"CHURCH_CODE": "CJERUS03",
		"CHURCH_NAME": "JERUSALEN -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1015",
		"CHURCH_CODE": "CLAHER02",
		"CHURCH_NAME": "LA HERMOSA -DS CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1016",
		"CHURCH_CODE": "CLEGAD01",
		"CHURCH_NAME": "LEGADO -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1017",
		"CHURCH_CODE": "CNVAGA01",
		"CHURCH_NAME": "NUEVA GALILIA-DS. CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1018",
		"CHURCH_CODE": "CREDEN03",
		"CHURCH_NAME": "IG. REDENCION DS. CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "140",
		"CHURCH_ID": "1019",
		"CHURCH_CODE": "CSIONI02",
		"CHURCH_NAME": "SION -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "141",
		"CHURCH_ID": "1020",
		"CHURCH_CODE": "CALFAY03",
		"CHURCH_NAME": "ALFA Y OMEGA-TAME CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "141",
		"CHURCH_ID": "1021",
		"CHURCH_CODE": "CEDENT01",
		"CHURCH_NAME": "FILIAL EDEN DS. TAME CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "141",
		"CHURCH_ID": "1022",
		"CHURCH_CODE": "CENMAN01",
		"CHURCH_NAME": "ENMANUEL -  TAME CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "141",
		"CHURCH_ID": "1023",
		"CHURCH_CODE": "CHEBRO01",
		"CHURCH_NAME": "IG. HEBRON DS. TAME CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "141",
		"CHURCH_ID": "1024",
		"CHURCH_CODE": "CPUERT01",
		"CHURCH_NAME": "PUERTO RONDON -  TAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "141",
		"CHURCH_ID": "1025",
		"CHURCH_CODE": "CVILLA01",
		"CHURCH_NAME": "VILLA DE EMAUS -  TAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1026",
		"CHURCH_CODE": "CCANAA05",
		"CHURCH_NAME": "CANAAN - TAME ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1027",
		"CHURCH_CODE": "CEBENE01",
		"CHURCH_NAME": "EBENEZER -  TAME ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1028",
		"CHURCH_CODE": "CEFESO01",
		"CHURCH_NAME": "EFESO -  TAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1029",
		"CHURCH_CODE": "CENACO01",
		"CHURCH_NAME": "IG. ENACORE- DS TAME ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1030",
		"CHURCH_CODE": "CESMIR01",
		"CHURCH_NAME": "FILIAL ESMIRNA-DS. TAME ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1031",
		"CHURCH_CODE": "CJERHO01",
		"CHURCH_NAME": "JERUSALEM HOLANDA- DS TAME ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "142",
		"CHURCH_ID": "1032",
		"CHURCH_CODE": "CJERUS01",
		"CHURCH_NAME": "JERUSALEN -  DS TAME ORIENTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "143",
		"CHURCH_ID": "1033",
		"CHURCH_CODE": "CCABSS01",
		"CHURCH_NAME": "CABSSEL- RENACER -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "143",
		"CHURCH_ID": "1034",
		"CHURCH_CODE": "CMONTE03",
		"CHURCH_NAME": "MONTE ALTO -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "143",
		"CHURCH_ID": "1035",
		"CHURCH_CODE": "CPACHE01",
		"CHURCH_NAME": "PARAISO PACHELLY -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "143",
		"CHURCH_ID": "1036",
		"CHURCH_CODE": "CSOLED01",
		"CHURCH_NAME": "SOLEDAD -  CUCUTA NORTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "144",
		"CHURCH_ID": "1037",
		"CHURCH_CODE": "CBEREA01",
		"CHURCH_NAME": "BEREA -  VICHADA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "144",
		"CHURCH_ID": "1039",
		"CHURCH_CODE": "CLAROC02",
		"CHURCH_NAME": "LA ROCA - DS VICHADA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "144",
		"CHURCH_ID": "1040",
		"CHURCH_CODE": "CMARAN04",
		"CHURCH_NAME": "MARANATHA -  VICHADA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "144",
		"CHURCH_ID": "1041",
		"CHURCH_CODE": "CRENAC04",
		"CHURCH_NAME": "RENACER -  VICHADA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "144",
		"CHURCH_ID": "1042",
		"CHURCH_CODE": "CSINAI04",
		"CHURCH_NAME": "SINAI-DS. VICHADA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "145",
		"CHURCH_ID": "1043",
		"CHURCH_CODE": "CCRIST01",
		"CHURCH_NAME": "CRISTO LA ESPERANZA -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "145",
		"CHURCH_ID": "1045",
		"CHURCH_CODE": "CGETSE04",
		"CHURCH_NAME": "GETSEMANY -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "145",
		"CHURCH_ID": "1046",
		"CHURCH_CODE": "CJESUS05",
		"CHURCH_NAME": "JESUS ES LA ESPERANZA - VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "145",
		"CHURCH_ID": "1047",
		"CHURCH_CODE": "CNUEVA02",
		"CHURCH_NAME": "NUEVA JERUSALEN -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "145",
		"CHURCH_ID": "1048",
		"CHURCH_CODE": "CPARAI04",
		"CHURCH_NAME": "PARAISO -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "146",
		"CHURCH_ID": "1049",
		"CHURCH_CODE": "CEDENI03",
		"CHURCH_NAME": "EDEN -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "146",
		"CHURCH_ID": "1050",
		"CHURCH_CODE": "CFUERT01",
		"CHURCH_NAME": "FUERTE PREGON -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "146",
		"CHURCH_ID": "1051",
		"CHURCH_CODE": "CGENEZ02",
		"CHURCH_NAME": "GENEZARETH -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "146",
		"CHURCH_ID": "1052",
		"CHURCH_CODE": "CGERIS01",
		"CHURCH_NAME": "GERISIM - VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "146",
		"CHURCH_ID": "1053",
		"CHURCH_CODE": "CRAGON01",
		"CHURCH_NAME": "IG. RAGONBALIA DS. EDEN VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "146",
		"CHURCH_ID": "1054",
		"CHURCH_CODE": "CTRESA01",
		"CHURCH_NAME": "TRES ANGELES -  VILLA DEL ROSARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1055",
		"CHURCH_CODE": "CEFESO02",
		"CHURCH_NAME": "IGLESIA EFESO - DISTRITO ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1056",
		"CHURCH_CODE": "CHOREB05",
		"CHURCH_NAME": "GR. HOREB - DS ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1057",
		"CHURCH_CODE": "CPALES02",
		"CHURCH_NAME": "PALESTINA -DS ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1058",
		"CHURCH_CODE": "CREFUG01",
		"CHURCH_NAME": "REFUGIO -  DS ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1059",
		"CHURCH_CODE": "CRENAC09",
		"CHURCH_NAME": "GRUPO RENACER-DS ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1060",
		"CHURCH_CODE": "CZARDI01",
		"CHURCH_NAME": "IGLESIA ZARDIS DS. ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "1061",
		"CHURCH_CODE": "CZULIA01",
		"CHURCH_NAME": "ZULIA  -  DS ZULIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1062",
		"CHURCH_CODE": "CIGANDES01",
		"CHURCH_NAME": "ANDES - CENTRAL MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1063",
		"CHURCH_CODE": "CIGEBENE02",
		"CHURCH_NAME": "EBENEZER CARMEN DE ATRATO - BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1064",
		"CHURCH_CODE": "CIGELGOL01",
		"CHURCH_NAME": "EL GOLGOTA - BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1065",
		"CHURCH_CODE": "CIGLAPIN01",
		"CHURCH_NAME": "LA PINTADA-TAMESIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1066",
		"CHURCH_CODE": "CIGMARAN01",
		"CHURCH_NAME": "MARANATHA - BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1067",
		"CHURCH_CODE": "CIGSALGA01",
		"CHURCH_NAME": "SALGAR-BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1068",
		"CHURCH_CODE": "CIGSINAI01",
		"CHURCH_NAME": "SINAI ALTO DE LOS JARAMILLOS - BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1069",
		"CHURCH_CODE": "CIGTAMES01",
		"CHURCH_NAME": "TAMESIS-TAMESIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "148",
		"CHURCH_ID": "1070",
		"CHURCH_CODE": "CIGVALPA01",
		"CHURCH_NAME": "VALPARAISO-TAMESIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1071",
		"CHURCH_CODE": "CIGBELLO01",
		"CHURCH_NAME": "BELLO ORIENTE GETSEMANI-BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1072",
		"CHURCH_CODE": "CIGELBOS01",
		"CHURCH_NAME": "EL BOSQUE - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1073",
		"CHURCH_CODE": "CIGELGOL02",
		"CHURCH_NAME": "EL GOLGOTA - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1074",
		"CHURCH_CODE": "CIGJERUS02",
		"CHURCH_NAME": "JERUSALEN PINAR - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1075",
		"CHURCH_CODE": "CIGLAUDE01",
		"CHURCH_NAME": "LA UDEA - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1076",
		"CHURCH_CODE": "CIGLOSOL01",
		"CHURCH_NAME": "LOS OLIVOS - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1077",
		"CHURCH_CODE": "CIGSARON01",
		"CHURCH_NAME": "SARON - BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "149",
		"CHURCH_ID": "1078",
		"CHURCH_CODE": "CIGSINAI03",
		"CHURCH_NAME": "GRUPO SINAI SARON-BOSQUE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "150",
		"CHURCH_ID": "1079",
		"CHURCH_CODE": "CIGBETHE01",
		"CHURCH_NAME": "BETHEL LAS ANIMAS - BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "150",
		"CHURCH_ID": "1080",
		"CHURCH_CODE": "CIGCENTR01",
		"CHURCH_NAME": "CENTRAL - CENTRAL MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "150",
		"CHURCH_ID": "1081",
		"CHURCH_CODE": "CIGCONCO01",
		"CHURCH_NAME": "CONCORDIA - BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "150",
		"CHURCH_ID": "1082",
		"CHURCH_CODE": "CIGGETSE02",
		"CHURCH_NAME": "GETSEMANI PABON- BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "150",
		"CHURCH_ID": "1083",
		"CHURCH_CODE": "CIGMONTE02",
		"CHURCH_NAME": "MONTE SION LA IRACALA-BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "150",
		"CHURCH_ID": "1084",
		"CHURCH_CODE": "CIGPENIE02",
		"CHURCH_NAME": "PENIEL URRAO-BOLIVAR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1085",
		"CHURCH_CODE": "CIGCRIST01",
		"CHURCH_NAME": "CRISTO REY - CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1086",
		"CHURCH_CODE": "CIGENVIG01",
		"CHURCH_NAME": "ENVIGADO-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1087",
		"CHURCH_CODE": "CIGFREDO01",
		"CHURCH_NAME": "FREDONIA MARANTAHA-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1088",
		"CHURCH_CODE": "CIGGETSE04",
		"CHURCH_NAME": "GETSEMANI-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1089",
		"CHURCH_CODE": "CIGMANAN02",
		"CHURCH_NAME": "MANANTIAL-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1090",
		"CHURCH_CODE": "CIGSABAN01",
		"CHURCH_NAME": "SABANETA-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1091",
		"CHURCH_CODE": "CIGSIONA01",
		"CHURCH_NAME": "SION TRINIDAD-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "151",
		"CHURCH_ID": "1092",
		"CHURCH_CODE": "CIGTITIR01",
		"CHURCH_NAME": "TITIRIBI-CENTRO SUR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1093",
		"CHURCH_CODE": "CIGBAHIA01",
		"CHURCH_NAME": "BAHIA SOLANO - CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1094",
		"CHURCH_CODE": "CIGBETHA01",
		"CHURCH_NAME": "BETHANIA - CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1095",
		"CHURCH_CODE": "CIGBETHE03",
		"CHURCH_NAME": "BETHEL - CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1096",
		"CHURCH_CODE": "CIGBEVAR02",
		"CHURCH_NAME": "BEVARA - CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1097",
		"CHURCH_CODE": "CIGCANAA02",
		"CHURCH_NAME": "GRUPO CANAAN-CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1098",
		"CHURCH_CODE": "CIGESTRE01",
		"CHURCH_NAME": "ESTRELLA ICHO-CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1099",
		"CHURCH_CODE": "CIGMURIN01",
		"CHURCH_NAME": "MURINDO-CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1100",
		"CHURCH_CODE": "CIGORION01",
		"CHURCH_NAME": "ORION TUTUNENDO- CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1101",
		"CHURCH_CODE": "CIGPIEDR01",
		"CHURCH_NAME": "PIEDRA CANDELA-CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1102",
		"CHURCH_CODE": "CIGQUIBD01",
		"CHURCH_NAME": "QUIBDO CENTRAL EMMANUEL-CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "152",
		"CHURCH_ID": "1103",
		"CHURCH_CODE": "CIGVIGIA01",
		"CHURCH_NAME": "VIGIA DEL FUERTE-CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1104",
		"CHURCH_CODE": "CIGBELEN01",
		"CHURCH_NAME": "GRUPO BELÉN-CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1105",
		"CHURCH_CODE": "CIGELEDE01",
		"CHURCH_NAME": "EL EDEN - CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1106",
		"CHURCH_CODE": "CIGELPAR01",
		"CHURCH_NAME": "EL PARAISO-CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1107",
		"CHURCH_CODE": "CIGGETSE01",
		"CHURCH_NAME": "GETSEMANI - CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1108",
		"CHURCH_CODE": "CIGJERUS01",
		"CHURCH_NAME": "JERUSALEN - CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1109",
		"CHURCH_CODE": "CIGREDEN01",
		"CHURCH_NAME": "REDENCION-CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1110",
		"CHURCH_CODE": "CIGSALEM01",
		"CHURCH_NAME": "SALEM- CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "153",
		"CHURCH_ID": "1111",
		"CHURCH_CODE": "CIGSIONI01",
		"CHURCH_NAME": "SION LLORO - CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1112",
		"CHURCH_CODE": "CIGANDAG01",
		"CHURCH_NAME": "ANDAGOYA-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1113",
		"CHURCH_CODE": "CIGFILAD01",
		"CHURCH_NAME": "FILADELFIA- CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1114",
		"CHURCH_CODE": "CIGFUENT01",
		"CHURCH_NAME": "FUENTE DE VIDA-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1115",
		"CHURCH_CODE": "CIGGOSSE01",
		"CHURCH_NAME": "GOSSEM-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1116",
		"CHURCH_CODE": "CIGISTMI01",
		"CHURCH_NAME": "ITSMINA REDENCION-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1117",
		"CHURCH_CODE": "CIGJERUS03",
		"CHURCH_NAME": "JERUSALEN-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1118",
		"CHURCH_CODE": "CIGLAESP01",
		"CHURCH_NAME": "LA ESPERANZA-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1119",
		"CHURCH_CODE": "CIGMANAG01",
		"CHURCH_NAME": "MANUGRU LA ODISEA-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1120",
		"CHURCH_CODE": "CIGMANAN01",
		"CHURCH_NAME": "MANANTIAL-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1121",
		"CHURCH_CODE": "CIGMARAN03",
		"CHURCH_NAME": "MARANATHA-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1122",
		"CHURCH_CODE": "CIGMELUK01",
		"CHURCH_NAME": "MELUK REMANENTE-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1123",
		"CHURCH_CODE": "CIGPARAI01",
		"CHURCH_NAME": "PARAISO CIERPE-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "154",
		"CHURCH_ID": "1124",
		"CHURCH_CODE": "CIGRASPA01",
		"CHURCH_NAME": "RASPADURAS LA FE-CHOCO SAN JUAN BAUDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "155",
		"CHURCH_ID": "1125",
		"CHURCH_CODE": "CIGABEJO02",
		"CHURCH_NAME": "GRUPO ABEJORRAL-LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "155",
		"CHURCH_ID": "1126",
		"CHURCH_CODE": "CIGELCAR01",
		"CHURCH_NAME": "EL CARMEN DE VIBORAL - LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "155",
		"CHURCH_ID": "1127",
		"CHURCH_CODE": "CIGGUARN01",
		"CHURCH_NAME": "GUARNE EMAUS - LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "155",
		"CHURCH_ID": "1128",
		"CHURCH_CODE": "CIGLACEJ01",
		"CHURCH_NAME": "LA CEJA PENIEL - LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "155",
		"CHURCH_ID": "1129",
		"CHURCH_CODE": "CIGNARIÑ01",
		"CHURCH_NAME": "NARIÑO-LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "155",
		"CHURCH_ID": "1131",
		"CHURCH_CODE": "CIGSONSO01",
		"CHURCH_NAME": "SONSON - LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1132",
		"CHURCH_CODE": "CIGARANJ01",
		"CHURCH_NAME": "ARANJUEZ GETSEMANI - ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1134",
		"CHURCH_CODE": "CIGBETHE05",
		"CHURCH_NAME": "BETHEL JERUSALEN LA CRUZ- ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1135",
		"CHURCH_CODE": "CIGEMMAN01",
		"CHURCH_NAME": "EMMANUEL SAN BLAS-ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1136",
		"CHURCH_CODE": "CIGMARAN02",
		"CHURCH_NAME": "MARANATHA ANDALUCIA - ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1137",
		"CHURCH_CODE": "CIGNVAJE01",
		"CHURCH_NAME": "NVA JERUSALEN MANRIQUE - ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1138",
		"CHURCH_CODE": "CIGORION02",
		"CHURCH_NAME": "ORION VERSALLES - ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1139",
		"CHURCH_CODE": "CIGREDEN02",
		"CHURCH_NAME": "REDENCION - ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "156",
		"CHURCH_ID": "1140",
		"CHURCH_CODE": "CIGSINAI02",
		"CHURCH_NAME": "SINAI LA CRUZ-ORIENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1141",
		"CHURCH_CODE": "CIGAVILA01",
		"CHURCH_NAME": "AVILA JERUSALEN-ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1142",
		"CHURCH_CODE": "CIGBETHE02",
		"CHURCH_NAME": "BETHEL LA LIBERTAD - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1143",
		"CHURCH_CODE": "CIGCANAA01",
		"CHURCH_NAME": "CANAAN PINAL - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1144",
		"CHURCH_CODE": "CIGGOLON01",
		"CHURCH_NAME": "GOLONDRINA  EMANUEL- ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1145",
		"CHURCH_CODE": "CIGPENIE01",
		"CHURCH_NAME": "PENIEL VILLATINA - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1146",
		"CHURCH_CODE": "CIGPRIMA01",
		"CHURCH_NAME": "PRIMAVERA ISRAEL - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1147",
		"CHURCH_CODE": "CIGREMAN02",
		"CHURCH_NAME": "REMANENTE - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1148",
		"CHURCH_CODE": "CIGRENAC02",
		"CHURCH_NAME": "RENACER - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "157",
		"CHURCH_ID": "1149",
		"CHURCH_CODE": "CIGSUCRE01",
		"CHURCH_NAME": "SUCRE PALESTINA - ORION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "158",
		"CHURCH_ID": "1150",
		"CHURCH_CODE": "CIGINGLE01",
		"CHURCH_NAME": "INGLES HOPE CHAPEL-POBLADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "158",
		"CHURCH_ID": "1151",
		"CHURCH_CODE": "CIGPOBLA01",
		"CHURCH_NAME": "POBLADO-POBLADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "158",
		"CHURCH_ID": "1152",
		"CHURCH_CODE": "CIGSANTA01",
		"CHURCH_NAME": "SANTA BARBARA-POBLADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1153",
		"CHURCH_CODE": "CIGANORI01",
		"CHURCH_NAME": "ANORI - PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1154",
		"CHURCH_CODE": "CIGAPOSE01",
		"CHURCH_NAME": "APOSENTO ALTO-PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1155",
		"CHURCH_CODE": "CIGCISNE01",
		"CHURCH_NAME": "CISNEROS - PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1156",
		"CHURCH_CODE": "CIGELCAR02",
		"CHURCH_NAME": "EL CARMELO - PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1157",
		"CHURCH_CODE": "CIGELDIA01",
		"CHURCH_NAME": "EL DIAMANTE - PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1158",
		"CHURCH_CODE": "CIGGETSE03",
		"CHURCH_NAME": "GETSEMANI SEGOVIA-PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1159",
		"CHURCH_CODE": "CIGGILGA01",
		"CHURCH_NAME": "GILGAL - PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1160",
		"CHURCH_CODE": "CIGGUACA01",
		"CHURCH_NAME": "GUACAS-PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1161",
		"CHURCH_CODE": "CIGREMAN01",
		"CHURCH_NAME": "REMANENTE SAN ROQUE-PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1162",
		"CHURCH_CODE": "CIGREMED01",
		"CHURCH_NAME": "REMEDIOS- PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1163",
		"CHURCH_CODE": "CIGVEGAC01",
		"CHURCH_NAME": "VEGACHI-PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "159",
		"CHURCH_ID": "1164",
		"CHURCH_CODE": "CIGYOLOM01",
		"CHURCH_NAME": "YOLOMBO-PUERTO BERRIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "160",
		"CHURCH_ID": "1165",
		"CHURCH_CODE": "CIGEBENE01",
		"CHURCH_NAME": "EBENEZER - CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "160",
		"CHURCH_ID": "1166",
		"CHURCH_CODE": "CIGHOREB01",
		"CHURCH_NAME": "HOREB CENTRAL-CHOCO HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "160",
		"CHURCH_ID": "1167",
		"CHURCH_CODE": "CIGRENAC01",
		"CHURCH_NAME": "RENACER - CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "160",
		"CHURCH_ID": "1168",
		"CHURCH_CODE": "CIGROSAD01",
		"CHURCH_NAME": "ROSA DE SARON - CHOCO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1169",
		"CHURCH_CODE": "CIGCOCOR01",
		"CHURCH_NAME": "COCORNA - RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1170",
		"CHURCH_CODE": "CIGELPOR01",
		"CHURCH_NAME": "EL PORVENIR - RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1171",
		"CHURCH_CODE": "CIGGUATA01",
		"CHURCH_NAME": "GUATAPE- RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1172",
		"CHURCH_CODE": "CIGMARIN01",
		"CHURCH_NAME": "MARINILLA- RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1173",
		"CHURCH_CODE": "CIGMONTE01",
		"CHURCH_NAME": "MONTELORO - LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1174",
		"CHURCH_CODE": "CIGPEÑOL01",
		"CHURCH_NAME": "GRUPO EL PEÑOL-RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1175",
		"CHURCH_CODE": "CIGRIONE01",
		"CHURCH_NAME": "RIONEGRO CENTRAL-RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1176",
		"CHURCH_CODE": "CIGSANCA01",
		"CHURCH_NAME": "SAN CARLOS - RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1177",
		"CHURCH_CODE": "CIGSANLO01",
		"CHURCH_NAME": "SAN LORENZO-LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1178",
		"CHURCH_CODE": "CIGSANLU01",
		"CHURCH_NAME": "SAN LUIS - LA CEJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1179",
		"CHURCH_CODE": "CIGSANTU01",
		"CHURCH_NAME": "SANTUARIO SHARON-RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "161",
		"CHURCH_ID": "1180",
		"CHURCH_CODE": "CIGSANVI01",
		"CHURCH_NAME": "SAN VICENTE - RIONEGRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1181",
		"CHURCH_CODE": "CIGAMAGA01",
		"CHURCH_NAME": "AMAGA- SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1182",
		"CHURCH_CODE": "CIGBETHE04",
		"CHURCH_NAME": "BETHEL SAN FRANCISCO-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1183",
		"CHURCH_CODE": "CIGCALDA01",
		"CHURCH_NAME": "CALDAS EMMANUEL-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1184",
		"CHURCH_CODE": "CIGCOLSUR01",
		"CHURCH_NAME": "COLEGIO DEL SUR- SUR ITAGUI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1185",
		"CHURCH_CODE": "CIGELJOR01",
		"CHURCH_NAME": "MANTEQUILLA-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1186",
		"CHURCH_CODE": "CIGELMIL01",
		"CHURCH_NAME": "EL MILAGRO-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1187",
		"CHURCH_CODE": "CIGHOREB02",
		"CHURCH_NAME": "HOREB SAN ANTONIO PRADO-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1188",
		"CHURCH_CODE": "CIGLAEST01",
		"CHURCH_NAME": "LA ESTRELLA ORION-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "162",
		"CHURCH_ID": "1189",
		"CHURCH_CODE": "CIGMONTE03",
		"CHURCH_NAME": "MONTEBELLO-SUR MEDELLIN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1190",
		"CHURCH_CODE": "CELCAR01",
		"CHURCH_NAME": "EL CARMEN - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1191",
		"CHURCH_CODE": "CHARAN01",
		"CHURCH_NAME": "HARAN - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1192",
		"CHURCH_CODE": "CHOREB02",
		"CHURCH_NAME": "HOREB EL LIMÓN - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1193",
		"CHURCH_CODE": "CMIRAL01",
		"CHURCH_NAME": "MIRALINDO - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1194",
		"CHURCH_CODE": "CMONTE01",
		"CHURCH_NAME": "MONTE CARMELO - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1195",
		"CHURCH_CODE": "CORION04",
		"CHURCH_NAME": "ORION SAN PEDRO - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1196",
		"CHURCH_CODE": "CPARAI05",
		"CHURCH_NAME": "PARAÍSO VALPARAÍSO - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1197",
		"CHURCH_CODE": "CPENIE01",
		"CHURCH_NAME": "PENIEL MANTELLINA BAJA - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1198",
		"CHURCH_CODE": "CSARON01",
		"CHURCH_NAME": "SARON LA GUAYABITA - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1199",
		"CHURCH_CODE": "CSION02",
		"CHURCH_NAME": "SION CENTRAL- BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "163",
		"CHURCH_ID": "1200",
		"CHURCH_CODE": "CYOLAN01",
		"CHURCH_NAME": "YOLANDAS GRUPO - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "164",
		"CHURCH_ID": "1201",
		"CHURCH_CODE": "CBARRA01",
		"CHURCH_NAME": "BARRANCA CENTRAL - B. CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "164",
		"CHURCH_ID": "1202",
		"CHURCH_CODE": "CNIEVE01",
		"CHURCH_NAME": "LAS NIEVES - DS. BARRANCA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "164",
		"CHURCH_ID": "1203",
		"CHURCH_CODE": "CRENAC01",
		"CHURCH_NAME": "RENACER LAURELES - B. CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "164",
		"CHURCH_ID": "1204",
		"CHURCH_CODE": "CSANTU01",
		"CHURCH_NAME": "SANTUARIO - BARRANCA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1205",
		"CHURCH_CODE": "CEFESO02",
		"CHURCH_NAME": "EFESO - BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1206",
		"CHURCH_CODE": "CEMMAN09",
		"CHURCH_NAME": "EMMANUEL - DS BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1207",
		"CHURCH_CODE": "CMARAN03",
		"CHURCH_NAME": "MARANATHA PUENTE SOGAMOSO - BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1208",
		"CHURCH_CODE": "CPALES01",
		"CHURCH_NAME": "PALESTINA PUERTO WILCHES - B. EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1209",
		"CHURCH_CODE": "CSALEM02",
		"CHURCH_NAME": "SALEM LLANITO - BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1210",
		"CHURCH_CODE": "CSALEM03",
		"CHURCH_NAME": "SALEM - DS BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1211",
		"CHURCH_CODE": "CSHALE01",
		"CHURCH_NAME": "SHALEM - BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "165",
		"CHURCH_ID": "1212",
		"CHURCH_CODE": "CSILOE01",
		"CHURCH_NAME": "SILOÉ - BARRANCA EFESO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1213",
		"CHURCH_CODE": "CEDEN03",
		"CHURCH_NAME": "EL EDEN - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1214",
		"CHURCH_CODE": "CEMMAN11",
		"CHURCH_NAME": "EMMANUEL - DS BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1215",
		"CHURCH_CODE": "CESMIR01",
		"CHURCH_NAME": "ESMIRNA 9 DE ABRIL - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1216",
		"CHURCH_CODE": "CGALIL01",
		"CHURCH_NAME": "GALILEA - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1217",
		"CHURCH_CODE": "CLIZAM01",
		"CHURCH_NAME": "LIZAMA FILIAL - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1218",
		"CHURCH_CODE": "CMARAN04",
		"CHURCH_NAME": "MARANATHA PEAJE - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1219",
		"CHURCH_CODE": "CPARAI06",
		"CHURCH_NAME": "PARAÍSO MINAS - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "166",
		"CHURCH_ID": "1220",
		"CHURCH_CODE": "CRENAC11",
		"CHURCH_NAME": "RENACER- ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1221",
		"CHURCH_CODE": "CBETHE08",
		"CHURCH_NAME": "BETHEL CAMPO 23 -DISTRITO BARRANCA LA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1222",
		"CHURCH_CODE": "CCAPER01",
		"CHURCH_NAME": "CAPERNAUM - B. ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1223",
		"CHURCH_CODE": "CLAVIC01",
		"CHURCH_NAME": "LA VICTORIA - B. CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1225",
		"CHURCH_CODE": "CMARAN08",
		"CHURCH_NAME": "MARANATHA CANO SAN PEDRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1226",
		"CHURCH_CODE": "CMONTE03",
		"CHURCH_NAME": "MONTE SINAÍ ZAMBRANITO - B. ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1227",
		"CHURCH_CODE": "CNUEVA02",
		"CHURCH_NAME": "NUEVA JERUSALEM - B. LA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1228",
		"CHURCH_CODE": "CPALES04",
		"CHURCH_NAME": "PALESTINA - B. LA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1229",
		"CHURCH_CODE": "CPALMA01",
		"CHURCH_NAME": "PALMAR -BARRANCA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "167",
		"CHURCH_ID": "1231",
		"CHURCH_CODE": "CTRESA01",
		"CHURCH_NAME": "LOS TRES ANGELES - BARRANCA ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1232",
		"CHURCH_CODE": "CEMMAN02",
		"CHURCH_NAME": "EMMANUEL - B. NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1233",
		"CHURCH_CODE": "CJERUS01",
		"CHURCH_NAME": "JERUSALEM YONDÓ - B. NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1234",
		"CHURCH_CODE": "CLAESP02",
		"CHURCH_NAME": "LA UNICA ESPERANZA CANTAGALLO- BARRANCA NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1235",
		"CHURCH_CODE": "CMONTE02",
		"CHURCH_NAME": "MONTE SION SAN PABLO - B. NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1236",
		"CHURCH_CODE": "CNUEVA01",
		"CHURCH_NAME": "NUEVA VIDA - B. NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1237",
		"CHURCH_CODE": "CNUEVA06",
		"CHURCH_NAME": "NUEVA ESPERANZA - BARRANCA NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1238",
		"CHURCH_CODE": "CORION05",
		"CHURCH_NAME": "ORION - BARRANCA NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1239",
		"CHURCH_CODE": "CREDEN01",
		"CHURCH_NAME": "REDENCIÓN PATICO - B. NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "168",
		"CHURCH_ID": "1240",
		"CHURCH_CODE": "CRENAC08",
		"CHURCH_NAME": "RENACER - BARRANCA NUEVA VIDA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1241",
		"CHURCH_CODE": "CBETAN02",
		"CHURCH_NAME": "BETANIA DELICIAS - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1242",
		"CHURCH_CODE": "CBETHE03",
		"CHURCH_NAME": "BETHEL PAMPLONITA - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1243",
		"CHURCH_CODE": "CEBENE03",
		"CHURCH_NAME": "EBENEZER - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1244",
		"CHURCH_CODE": "CEDEN02",
		"CHURCH_NAME": "EL EDEN - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1245",
		"CHURCH_CODE": "CEMMAN04",
		"CHURCH_NAME": "EMMANUEL - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1246",
		"CHURCH_CODE": "CFILAD04",
		"CHURCH_NAME": "FILADELFIA - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1247",
		"CHURCH_CODE": "CLAESP01",
		"CHURCH_NAME": "LA ESPERANZA - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1248",
		"CHURCH_CODE": "CLAVIC03",
		"CHURCH_NAME": "LA VICTORIA - DISTRITO EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1249",
		"CHURCH_CODE": "CMAHAN01",
		"CHURCH_NAME": "MAHANAHIN - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1251",
		"CHURCH_CODE": "CMONTE08",
		"CHURCH_NAME": "MONTE DE SION - EL CARMEN DE CHUCURÍ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1252",
		"CHURCH_CODE": "CNAIN01",
		"CHURCH_NAME": "NAIN LA LAGUNA - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1253",
		"CHURCH_CODE": "CNAZAR01",
		"CHURCH_NAME": "NAZARETH - EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1254",
		"CHURCH_CODE": "CPARAI08",
		"CHURCH_NAME": "PARAISO- DISTRITO EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "169",
		"CHURCH_ID": "1255",
		"CHURCH_CODE": "CRENAC12",
		"CHURCH_NAME": "GRUPO RENACER-EL CARMEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1256",
		"CHURCH_CODE": "CBETHE02",
		"CHURCH_NAME": "BETHEL - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1257",
		"CHURCH_CODE": "CCANAA02",
		"CHURCH_NAME": "CANAAN - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1258",
		"CHURCH_CODE": "CEFESO01",
		"CHURCH_NAME": "EFESO - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1259",
		"CHURCH_CODE": "CINDIA01",
		"CHURCH_NAME": "LA INDIA - DS. NUEVO AMANECER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1260",
		"CHURCH_CODE": "CLIBAN01",
		"CHURCH_NAME": "EL LIBANO - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1261",
		"CHURCH_CODE": "CMARAN02",
		"CHURCH_NAME": "MARANATHA - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1262",
		"CHURCH_CODE": "CMONTE04",
		"CHURCH_NAME": "MONTE NEBO - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1263",
		"CHURCH_CODE": "CNUEVO04",
		"CHURCH_NAME": "NUEVO AMANECER - DISTRITO CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1264",
		"CHURCH_CODE": "COFRA01",
		"CHURCH_NAME": "OFRA LA PITALA - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1265",
		"CHURCH_CODE": "CORION02",
		"CHURCH_NAME": "ORION - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1266",
		"CHURCH_CODE": "CPALES02",
		"CHURCH_NAME": "PALESTINA - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1267",
		"CHURCH_CODE": "CRENAC04",
		"CHURCH_NAME": "RENACER NUEVA GRANADA - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "170",
		"CHURCH_ID": "1268",
		"CHURCH_CODE": "CSAMAR02",
		"CHURCH_NAME": "SAMARIA - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1269",
		"CHURCH_CODE": "CAMIUD01",
		"CHURCH_NAME": "AMIUD HORTA MEDIO - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1270",
		"CHURCH_CODE": "CBETHE01",
		"CHURCH_NAME": "BETHEL JIRETH - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1271",
		"CHURCH_CODE": "CCANAA01",
		"CHURCH_NAME": "CANAAN COBA PLATA - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1273",
		"CHURCH_CODE": "CEMMAN03",
		"CHURCH_NAME": "EMMANUEL - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1274",
		"CHURCH_CODE": "CHERMO01",
		"CHURCH_NAME": "HERMOSA PROVINCIA - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1275",
		"CHURCH_CODE": "CJERUS02",
		"CHURCH_NAME": "JERUSALEM - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1276",
		"CHURCH_CODE": "CLAHER01",
		"CHURCH_NAME": "LA HERMOSA - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1277",
		"CHURCH_CODE": "CORION01",
		"CHURCH_NAME": "ORION LA INDIA - CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "171",
		"CHURCH_ID": "1278",
		"CHURCH_CODE": "CSHALO02",
		"CHURCH_NAME": "SHALOM-CIMITARRA LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1279",
		"CHURCH_CODE": "CBALSO01",
		"CHURCH_NAME": "BALSOS - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1280",
		"CHURCH_CODE": "CCIMIT01",
		"CHURCH_NAME": "CIMITARRA CENTRAL - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1281",
		"CHURCH_CODE": "CELTRI01",
		"CHURCH_NAME": "EL TRIUNFO - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1282",
		"CHURCH_CODE": "CEMAUS01",
		"CHURCH_NAME": "EMAUS QUEBRADA NEGRA - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1283",
		"CHURCH_CODE": "CHEBRO01",
		"CHURCH_NAME": "HEBRÓN - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1286",
		"CHURCH_CODE": "CPENIE02",
		"CHURCH_NAME": "PENIEL TOROBA ALTA - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1288",
		"CHURCH_CODE": "CRENAC09",
		"CHURCH_NAME": "RENACER - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "172",
		"CHURCH_ID": "1289",
		"CHURCH_CODE": "CSION01",
		"CHURCH_NAME": "SION SAN ISIDRO - CIMITARRA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1290",
		"CHURCH_CODE": "CBETAN01",
		"CHURCH_NAME": "BETANIA QUEBRADA LARGA - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1291",
		"CHURCH_CODE": "CBETHE07",
		"CHURCH_NAME": "BETHEL LOS PINOS - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1292",
		"CHURCH_CODE": "CEBENE01",
		"CHURCH_NAME": "EBENEZER - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1293",
		"CHURCH_CODE": "CLARIB01",
		"CHURCH_NAME": "LA RIBERA - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1294",
		"CHURCH_CODE": "CNUEVO01",
		"CHURCH_NAME": "NUEVO EDÉN - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1296",
		"CHURCH_CODE": "CPALMA02",
		"CHURCH_NAME": "PALMAS DEL GUAYABITO - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "173",
		"CHURCH_ID": "1298",
		"CHURCH_CODE": "CSAMAR01",
		"CHURCH_NAME": "SAMARIA - CIMITARRA LA RIVERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "174",
		"CHURCH_ID": "1299",
		"CHURCH_CODE": "CCARRI01",
		"CHURCH_NAME": "GRUPO ALTOS DEL CARRIZAL- D GIRON EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "174",
		"CHURCH_ID": "1300",
		"CHURCH_CODE": "CEMMAN05",
		"CHURCH_NAME": "EMMANUEL POBLADO - GIRON EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "174",
		"CHURCH_ID": "1301",
		"CHURCH_CODE": "CGETSE02",
		"CHURCH_NAME": "GETSEMANÍ - GIRON EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "174",
		"CHURCH_ID": "1302",
		"CHURCH_CODE": "CHOREB04",
		"CHURCH_NAME": "HOREB - GIRON EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "174",
		"CHURCH_ID": "1303",
		"CHURCH_CODE": "CPENIE03",
		"CHURCH_NAME": "PENIEL BELLAVISTA - GIRON EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1304",
		"CHURCH_CODE": "CBETHE04",
		"CHURCH_NAME": "BETHEL - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1305",
		"CHURCH_CODE": "CFUERT01",
		"CHURCH_NAME": "FUERTE PREGON - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1307",
		"CHURCH_CODE": "CMARAN07",
		"CHURCH_NAME": "MARANATHA -DS.GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1308",
		"CHURCH_CODE": "CNUEVA05",
		"CHURCH_NAME": "NUEVA JERUSALEM - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1309",
		"CHURCH_CODE": "CNUEVO02",
		"CHURCH_NAME": "NUEVO EDEN ZAPATOCA - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1310",
		"CHURCH_CODE": "CORION06",
		"CHURCH_NAME": "ORION - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1311",
		"CHURCH_CODE": "CPALES03",
		"CHURCH_NAME": "PALESTINA - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "175",
		"CHURCH_ID": "1312",
		"CHURCH_CODE": "CRIVER01",
		"CHURCH_NAME": "RIVERA DEL RIO - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1313",
		"CHURCH_CODE": "CLAESP05",
		"CHURCH_NAME": "LA ESPERANZA -DS. GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1314",
		"CHURCH_CODE": "CNUEVO05",
		"CHURCH_NAME": "NUEVO RENACER - DS GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1315",
		"CHURCH_CODE": "COLIVO01",
		"CHURCH_NAME": "LOS OLIVOS - GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1316",
		"CHURCH_CODE": "CRINCO01",
		"CHURCH_NAME": "SION RINCON CANEYES- GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1317",
		"CHURCH_CODE": "CRINCO02",
		"CHURCH_NAME": "GRUPO RINCON DE GIRON - GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1318",
		"CHURCH_CODE": "CSANTA02",
		"CHURCH_NAME": "GRUPO SANTA CRUZ- GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "176",
		"CHURCH_ID": "1319",
		"CHURCH_CODE": "CVILLA02",
		"CHURCH_NAME": "VILLAS DE SION - GIRON SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1320",
		"CHURCH_CODE": "CAGUAC01",
		"CHURCH_NAME": "AGUA CHICA - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1321",
		"CHURCH_CODE": "CCARRE01",
		"CHURCH_NAME": "CARRETERO- LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1322",
		"CHURCH_CODE": "CEBENE04",
		"CHURCH_NAME": "EBENEZER KM 10 - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1323",
		"CHURCH_CODE": "CEDEN01",
		"CHURCH_NAME": "EDEN - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1324",
		"CHURCH_CODE": "CEMMAN06",
		"CHURCH_NAME": "EMMANUEL SANTA ROSA - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1325",
		"CHURCH_CODE": "CFILAD01",
		"CHURCH_NAME": "FILADELFIA - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1326",
		"CHURCH_CODE": "CGALLE01",
		"CHURCH_NAME": "GALLEGOS - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1327",
		"CHURCH_CODE": "CLANDA01",
		"CHURCH_NAME": "LANDAZURI CENTRAL - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1328",
		"CHURCH_CODE": "CLIBAN03",
		"CHURCH_NAME": "EL LIBANO CHOROLÓ - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1329",
		"CHURCH_CODE": "CLOMAL01",
		"CHURCH_NAME": "LOMALINDA - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1330",
		"CHURCH_CODE": "CNACUM01",
		"CHURCH_NAME": "NACUMALES ORION - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1331",
		"CHURCH_CODE": "CNUEVA03",
		"CHURCH_NAME": "NUEVA JERUSALEM - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1332",
		"CHURCH_CODE": "CPARAI07",
		"CHURCH_NAME": "PARAISO -DS.LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1333",
		"CHURCH_CODE": "CSAMAR03",
		"CHURCH_NAME": "SAMARIA KM 4 - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "177",
		"CHURCH_ID": "1334",
		"CHURCH_CODE": "CVILLA01",
		"CHURCH_NAME": "VILLA ALICIA - LANDAZURI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1335",
		"CHURCH_CODE": "CJERUS04",
		"CHURCH_NAME": "JERUSALEN - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1336",
		"CHURCH_CODE": "CLIBAN02",
		"CHURCH_NAME": "EL LIBANO CEDRO - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1337",
		"CHURCH_CODE": "CMANAN02",
		"CHURCH_NAME": "MANANTIAL - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1338",
		"CHURCH_CODE": "CMONTE05",
		"CHURCH_NAME": "MONTE DE LOS OLIVOS - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1339",
		"CHURCH_CODE": "CORION03",
		"CHURCH_NAME": "ORION - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1340",
		"CHURCH_CODE": "CPARAI01",
		"CHURCH_NAME": "PARAÍSO - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "178",
		"CHURCH_ID": "1341",
		"CHURCH_CODE": "CTERCE01",
		"CHURCH_NAME": "EL TERCER ANGEL - LEBRIJA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1342",
		"CHURCH_CODE": "CEBENE07",
		"CHURCH_NAME": "EBENEZER PAMPAS - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1343",
		"CHURCH_CODE": "CEMMAN08",
		"CHURCH_NAME": "EMMANUEL URIBE - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1344",
		"CHURCH_CODE": "CGOMEM01",
		"CHURCH_NAME": "GOMEZ FILIAL-SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1345",
		"CHURCH_CODE": "CJEHOV01",
		"CHURCH_NAME": "JEHOVA JIRETH - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1346",
		"CHURCH_CODE": "CLOMAL02",
		"CHURCH_NAME": "LOMALINDA - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1347",
		"CHURCH_CODE": "CPARAI03",
		"CHURCH_NAME": "PARAÍSO EL PROGRESO - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1348",
		"CHURCH_CODE": "CREDIM01",
		"CHURCH_NAME": "REDIMIR - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1349",
		"CHURCH_CODE": "CRENAC03",
		"CHURCH_NAME": "RENACER - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1350",
		"CHURCH_CODE": "CSABAN01",
		"CHURCH_NAME": "SABANA DE TORRES - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "179",
		"CHURCH_ID": "1352",
		"CHURCH_CODE": "CSHEKI01",
		"CHURCH_NAME": "SHEKINA - SABANA DE TORRES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "180",
		"CHURCH_ID": "1353",
		"CHURCH_CODE": "CBETHE06",
		"CHURCH_NAME": "BETHEL - SAN ALBERTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "180",
		"CHURCH_ID": "1354",
		"CHURCH_CODE": "CECOSD01",
		"CHURCH_NAME": "ECOS DE SION SAN RAFAEL - SAN ALBERTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "180",
		"CHURCH_ID": "1355",
		"CHURCH_CODE": "CFILAD03",
		"CHURCH_NAME": "FILADELFIA - SAN ALBERTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "180",
		"CHURCH_ID": "1356",
		"CHURCH_CODE": "CNAZAR02",
		"CHURCH_NAME": "NAZAREO - SAN ALBERTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "180",
		"CHURCH_ID": "1357",
		"CHURCH_CODE": "CPAPAY01",
		"CHURCH_NAME": "PAPAYAL RENACER- SAN ALBERTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "180",
		"CHURCH_ID": "1358",
		"CHURCH_CODE": "CSINAI02",
		"CHURCH_NAME": "SINAÍ GUADUAS - SAN ALBERTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1359",
		"CHURCH_CODE": "CCAMIN01",
		"CHURCH_NAME": "CAMINO A CRISTO - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1361",
		"CHURCH_CODE": "CLAHER02",
		"CHURCH_NAME": "LA HERMOSA - DS SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1362",
		"CHURCH_CODE": "CLAVIC02",
		"CHURCH_NAME": "LA VICTORIA - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1363",
		"CHURCH_CODE": "CNUEVA08",
		"CHURCH_NAME": "NUEVA VIDA - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1364",
		"CHURCH_CODE": "CPARAI04",
		"CHURCH_NAME": "PARAÍSO ALTO VIENTO - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1365",
		"CHURCH_CODE": "CPATMO01",
		"CHURCH_NAME": "PATMOS SAN CARLOS - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1366",
		"CHURCH_CODE": "CROCAE01",
		"CHURCH_NAME": "ROCA ETERNA - GIRON PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1367",
		"CHURCH_CODE": "CSALEM01",
		"CHURCH_NAME": "SALEM - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1368",
		"CHURCH_CODE": "CSILOE02",
		"CHURCH_NAME": "SILOÉ - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1369",
		"CHURCH_CODE": "CSINAI03",
		"CHURCH_NAME": "SINAÍ - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "181",
		"CHURCH_ID": "1370",
		"CHURCH_CODE": "CTESAL01",
		"CHURCH_NAME": "TESALONICA - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1371",
		"CHURCH_CODE": "CALBAN01",
		"CHURCH_NAME": "ALBANIA FILIAL-SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1373",
		"CHURCH_CODE": "CCANAA03",
		"CHURCH_NAME": "CANAAN - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1374",
		"CHURCH_CODE": "CEBENE06",
		"CHURCH_NAME": "EBENEZER - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1376",
		"CHURCH_CODE": "CESMER01",
		"CHURCH_NAME": "ESMERALDA -DS SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1377",
		"CHURCH_CODE": "CGETSE03",
		"CHURCH_NAME": "GETSEMANÍ - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1378",
		"CHURCH_CODE": "CJARDI01",
		"CHURCH_NAME": "JARDÍN DEL EDEN - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1380",
		"CHURCH_CODE": "CLAVIC02",
		"CHURCH_NAME": "LA VICTORIA - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1381",
		"CHURCH_CODE": "CMARAN06",
		"CHURCH_NAME": "MARANATHA TULKÁN - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1382",
		"CHURCH_CODE": "CNUEVA08",
		"CHURCH_NAME": "NUEVA VIDA - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1383",
		"CHURCH_CODE": "CORION07",
		"CHURCH_NAME": "ORION - DS SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1384",
		"CHURCH_CODE": "CPARAI04",
		"CHURCH_NAME": "PARAÍSO ALTO VIENTO - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1386",
		"CHURCH_CODE": "CRENAC07",
		"CHURCH_NAME": "RENACER - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1389",
		"CHURCH_CODE": "CSHALO03",
		"CHURCH_NAME": "SHALON - SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "182",
		"CHURCH_ID": "1392",
		"CHURCH_CODE": "CSION04",
		"CHURCH_NAME": "SION-SAN VICENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1394",
		"CHURCH_CODE": "CBETAN03",
		"CHURCH_NAME": "BETANIA - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1395",
		"CHURCH_CODE": "CBETHE05",
		"CHURCH_NAME": "BETHEL - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1396",
		"CHURCH_CODE": "CEBENE05",
		"CHURCH_NAME": "EBENEZER ARAGUA- SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1397",
		"CHURCH_CODE": "CEMMAN07",
		"CHURCH_NAME": "EMMANUEL - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1398",
		"CHURCH_CODE": "CFILIP01",
		"CHURCH_NAME": "FILIPENSES - SANTA HELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1399",
		"CHURCH_CODE": "CGERMA01",
		"CHURCH_NAME": "GERMANIA - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1400",
		"CHURCH_CODE": "CMARAN05",
		"CHURCH_NAME": "MARANATHA - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1401",
		"CHURCH_CODE": "CNUEVO03",
		"CHURCH_NAME": "NUEVO EDEN CACHIPAY- SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1402",
		"CHURCH_CODE": "CPARAI02",
		"CHURCH_NAME": "PARAÍSO SANTA RITA - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "183",
		"CHURCH_ID": "1403",
		"CHURCH_CODE": "CRENAC02",
		"CHURCH_NAME": "RENACER - SANTA ELENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1404",
		"CHURCH_CODE": "CARABI01",
		"CHURCH_NAME": "ARABIA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1405",
		"CHURCH_CODE": "CBETAN04",
		"CHURCH_NAME": "BETANIA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1406",
		"CHURCH_CODE": "CCANAA04",
		"CHURCH_NAME": "CANAAN LA COLORADA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1407",
		"CHURCH_CODE": "CCRIST01",
		"CHURCH_NAME": "CRISTALINA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1408",
		"CHURCH_CODE": "CEMMAN10",
		"CHURCH_NAME": "EMANUEL - DS.YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1409",
		"CHURCH_CODE": "CFILAD02",
		"CHURCH_NAME": "FILADELFIA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1410",
		"CHURCH_CODE": "CHOREB03",
		"CHURCH_NAME": "HOREB - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1411",
		"CHURCH_CODE": "CJERUS03",
		"CHURCH_NAME": "JERUSALEM - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1412",
		"CHURCH_CODE": "CLAESP03",
		"CHURCH_NAME": "LA ESPERANZA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1413",
		"CHURCH_CODE": "CMONTE01",
		"CHURCH_NAME": "MONTE CARMELO - BAJO JORDAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1414",
		"CHURCH_CODE": "CMONTE04",
		"CHURCH_NAME": "MONTE NEBO - CENTENARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1415",
		"CHURCH_CODE": "CMONTE06",
		"CHURCH_NAME": "MONTE CARMELO - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1416",
		"CHURCH_CODE": "CMONTE07",
		"CHURCH_NAME": "MONTE HOREB - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1417",
		"CHURCH_CODE": "CPENIE04",
		"CHURCH_NAME": "PENIEL - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1418",
		"CHURCH_CODE": "CRENAC10",
		"CHURCH_NAME": "RENACER - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1419",
		"CHURCH_CODE": "CSARON02",
		"CHURCH_NAME": "SARON - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "184",
		"CHURCH_ID": "1420",
		"CHURCH_CODE": "CSHALO01",
		"CHURCH_NAME": "SHALOM YARIMA - YARIMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1421",
		"CHURCH_CODE": "CAPAPART01",
		"CHURCH_NAME": "APARTADÓ CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1422",
		"CHURCH_CODE": "CAPBETHE01",
		"CHURCH_NAME": "BETHEL - LOMA VERDE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1423",
		"CHURCH_CODE": "CAPCHURI01",
		"CHURCH_NAME": "CHURIDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1424",
		"CHURCH_CODE": "CAPELREP01",
		"CHURCH_NAME": "EL REPOSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1425",
		"CHURCH_CODE": "CAPELSAL01",
		"CHURCH_NAME": "EL SALVADOR",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1426",
		"CHURCH_CODE": "CAPESMIR01",
		"CHURCH_NAME": "ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1427",
		"CHURCH_CODE": "CAPOASIS01",
		"CHURCH_NAME": "OASIS APARTADO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1428",
		"CHURCH_CODE": "CAPORION02",
		"CHURCH_NAME": "ORION TRES ESQUINAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1429",
		"CHURCH_CODE": "CAPREMAN01",
		"CHURCH_NAME": "REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "185",
		"CHURCH_ID": "1430",
		"CHURCH_CODE": "CAPSALEM02",
		"CHURCH_NAME": "SALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1431",
		"CHURCH_CODE": "CAPALFAY01",
		"CHURCH_NAME": "ALFA Y OMEGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1432",
		"CHURCH_CODE": "CAPCANAA01",
		"CHURCH_NAME": "CANAAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1433",
		"CHURCH_CODE": "CAPEMMAN01",
		"CHURCH_NAME": "EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1434",
		"CHURCH_CODE": "CAPLAH01",
		"CHURCH_NAME": "LA HERMOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1435",
		"CHURCH_CODE": "CAPMARAN01",
		"CHURCH_NAME": "MARANATHA SAN JOSÉ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1436",
		"CHURCH_CODE": "CAPNES01",
		"CHURCH_NAME": "LA VOZ DE LA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1437",
		"CHURCH_CODE": "CAPNUEVA01",
		"CHURCH_NAME": "NUEVA LUZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1438",
		"CHURCH_CODE": "CAPNUEVA02",
		"CHURCH_NAME": "NUEVA SIÓN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1439",
		"CHURCH_CODE": "CAPPENIE01",
		"CHURCH_NAME": "PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "186",
		"CHURCH_ID": "1440",
		"CHURCH_CODE": "CAPRENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1441",
		"CHURCH_CODE": "CARARBOL01",
		"CHURCH_NAME": "ARBOLETES-EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1442",
		"CHURCH_CODE": "CARBETHE01",
		"CHURCH_NAME": "BETHEL OCASIONAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1443",
		"CHURCH_CODE": "CARBUENA01",
		"CHURCH_NAME": "BUENA VISTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1444",
		"CHURCH_CODE": "CARCRI01",
		"CHURCH_NAME": "CRISTO REINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1445",
		"CHURCH_CODE": "CARCRIST01",
		"CHURCH_NAME": "CRISTO VIENE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1446",
		"CHURCH_CODE": "CARGALIL01",
		"CHURCH_NAME": "GALILEA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1447",
		"CHURCH_CODE": "CARLIR01",
		"CHURCH_NAME": "LIRIOS DE LOS VALLES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1448",
		"CHURCH_CODE": "CARLOSOL01",
		"CHURCH_NAME": "LOS OLIVOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1449",
		"CHURCH_CODE": "CARNRE01",
		"CHURCH_NAME": "NUEVA RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1450",
		"CHURCH_CODE": "CARNUE01",
		"CHURCH_NAME": "NUEVA SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1451",
		"CHURCH_CODE": "CARORION01",
		"CHURCH_NAME": "ORION UVEROS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1452",
		"CHURCH_CODE": "CARRENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1453",
		"CHURCH_CODE": "CARSARON01",
		"CHURCH_NAME": "SARON-ARBOLETES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1454",
		"CHURCH_CODE": "CARSINAI01",
		"CHURCH_NAME": "SINAI-EL CAUCHO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1455",
		"CHURCH_CODE": "CARTRINI01",
		"CHURCH_NAME": "TRINIDAD-EBENEZER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "187",
		"CHURCH_ID": "1456",
		"CHURCH_CODE": "CARVER01",
		"CHURCH_NAME": "VERJEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1457",
		"CHURCH_CODE": "CBACUTUR01",
		"CHURCH_NAME": "CUTURU BAGRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1458",
		"CHURCH_CODE": "CBARENAC01",
		"CHURCH_NAME": "RENACER PALIZADA EL BAGRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1459",
		"CHURCH_CODE": "CZAEBENE01",
		"CHURCH_NAME": "EBENEZER EL BAGRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1460",
		"CHURCH_CODE": "CZAGUAMA01",
		"CHURCH_NAME": "GUAMACO / VENTARRON / APOSENTO ALTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1461",
		"CHURCH_CODE": "CZALACIN01",
		"CHURCH_NAME": "LA CINCO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1462",
		"CHURCH_CODE": "CZALOSTR01",
		"CHURCH_NAME": "LOSTRES ÁNGELES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1463",
		"CHURCH_CODE": "CZALUZDE01",
		"CHURCH_NAME": "LUZ DE ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "188",
		"CHURCH_ID": "1464",
		"CHURCH_CODE": "CZAMARAN01",
		"CHURCH_NAME": "MARANATHA-EL BAGRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1465",
		"CHURCH_CODE": "CBGIRAR01",
		"CHURCH_NAME": "GIRARDOTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1466",
		"CHURCH_CODE": "CBMBARBO01",
		"CHURCH_NAME": "BARBOSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1467",
		"CHURCH_CODE": "CBMCOPAC01",
		"CHURCH_NAME": "COPACABANA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1468",
		"CHURCH_CODE": "CBMESMIR01",
		"CHURCH_NAME": "ESMIRNA MACHADO -BELLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1469",
		"CHURCH_CODE": "CBMFILAD01",
		"CHURCH_NAME": "FILADELFIA - NIQUIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1470",
		"CHURCH_CODE": "CBMLAESP01",
		"CHURCH_NAME": "LA ESPERANZA BELLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1471",
		"CHURCH_CODE": "CBMPEN01",
		"CHURCH_NAME": "PENIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "189",
		"CHURCH_ID": "1472",
		"CHURCH_CODE": "CBMSANPA01",
		"CHURCH_NAME": "SAN PABLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1473",
		"CHURCH_CODE": "CCACAREP01",
		"CHURCH_NAME": "CAREPA CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1474",
		"CHURCH_CODE": "CCAESMIR01",
		"CHURCH_NAME": "ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1475",
		"CHURCH_CODE": "CCAFILAD01",
		"CHURCH_NAME": "FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1476",
		"CHURCH_CODE": "CCANUEVA01",
		"CHURCH_NAME": "NUEVA JERUSALÉN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1477",
		"CHURCH_CODE": "CCAOASIS01",
		"CHURCH_NAME": "OASIS CAREPA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1478",
		"CHURCH_CODE": "CCATRIGA01",
		"CHURCH_NAME": "TRIGANA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "190",
		"CHURCH_ID": "1479",
		"CHURCH_CODE": "CCAZUNGO01",
		"CHURCH_NAME": "ZUNGO PARAISO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1480",
		"CHURCH_CODE": "CCAFIL01",
		"CHURCH_NAME": "FILADELFIA -CARIBE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1481",
		"CHURCH_CODE": "CCAFUE01",
		"CHURCH_NAME": "FUENTE DE VIDA -CARIBE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1482",
		"CHURCH_CODE": "CCALAN01",
		"CHURCH_NAME": "LA NUEVA JERUSALEN -CARIBE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1483",
		"CHURCH_CODE": "CCAMAR01",
		"CHURCH_NAME": "MAR DE CRISTAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1484",
		"CHURCH_CODE": "CCAREM01",
		"CHURCH_NAME": "REMANENTE - CARIBE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1485",
		"CHURCH_CODE": "CCAREM02",
		"CHURCH_NAME": "REMANENTE MACHENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1486",
		"CHURCH_CODE": "CCAVIL01",
		"CHURCH_NAME": "VILLA SONIA -CARIBE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1487",
		"CHURCH_CODE": "CNEBETAN01",
		"CHURCH_NAME": "BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1488",
		"CHURCH_CODE": "CNELAESP01",
		"CHURCH_NAME": "LA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1489",
		"CHURCH_CODE": "CNEMANAN01",
		"CHURCH_NAME": "MANANTILAL - EL CARLOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1490",
		"CHURCH_CODE": "CNENUEVO01",
		"CHURCH_NAME": "NUEVO HORIZONTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1491",
		"CHURCH_CODE": "CTCBELEN01",
		"CHURCH_NAME": "BELEN CIRILO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1492",
		"CHURCH_CODE": "CTCCOPES01",
		"CHURCH_NAME": "COPE SANTIDAD JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1493",
		"CHURCH_CODE": "CTCEMAUS01",
		"CHURCH_NAME": "EMAUS TULAPIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1494",
		"CHURCH_CODE": "CTCLUZDI01",
		"CHURCH_NAME": "LUZ DIVINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1495",
		"CHURCH_CODE": "CTCMARAN01",
		"CHURCH_NAME": "MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1496",
		"CHURCH_CODE": "CTCPALES01",
		"CHURCH_NAME": "PALESTIINA (TIE)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1497",
		"CHURCH_CODE": "CTCSAMAR01",
		"CHURCH_NAME": "SAMARIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "191",
		"CHURCH_ID": "1498",
		"CHURCH_CODE": "CTCSIONC01",
		"CHURCH_NAME": "SIÓN CAMERÚN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1499",
		"CHURCH_CODE": "CAUBARRO01",
		"CHURCH_NAME": "BARROBLANCO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1500",
		"CHURCH_CODE": "CAUBETAN01",
		"CHURCH_NAME": "BETANIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1501",
		"CHURCH_CODE": "CAUCAUCA01",
		"CHURCH_NAME": "CAUCASIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1502",
		"CHURCH_CODE": "CAUISRAE01",
		"CHURCH_NAME": "ISRAEL CACERES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1503",
		"CHURCH_CODE": "CAUJORAM01",
		"CHURCH_NAME": "JORAM (HOREB CAUCASIA)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1504",
		"CHURCH_CODE": "CAUNUEVO01",
		"CHURCH_NAME": "NUEVO EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1505",
		"CHURCH_CODE": "CAUNVAES01",
		"CHURCH_NAME": "NUEVA ESPERANZA CAUCASIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1506",
		"CHURCH_CODE": "CAUSANPA01",
		"CHURCH_NAME": "SAN PABLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "192",
		"CHURCH_ID": "1507",
		"CHURCH_CODE": "CAUTARAZ01",
		"CHURCH_NAME": "TARAZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "193",
		"CHURCH_ID": "1508",
		"CHURCH_CODE": "CDABETHE01",
		"CHURCH_NAME": "BETHEL PASO ARRIBA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "193",
		"CHURCH_ID": "1509",
		"CHURCH_CODE": "CDAEMMAN01",
		"CHURCH_NAME": "EMMANUEL-FRONTNO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "193",
		"CHURCH_ID": "1510",
		"CHURCH_CODE": "CDAPEQUE01",
		"CHURCH_NAME": "PEQUE JERIGUA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "193",
		"CHURCH_ID": "1511",
		"CHURCH_CODE": "CDARENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "193",
		"CHURCH_ID": "1512",
		"CHURCH_CODE": "CGRPARA01",
		"CHURCH_NAME": "EL PARAISO GRAN OCCIDENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1513",
		"CHURCH_CODE": "CCHCHIGO01",
		"CHURCH_NAME": "CHIGORODÓ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1514",
		"CHURCH_CODE": "CCHCUBL01",
		"CHURCH_NAME": "CUCHILLO BLANCO CHIGORODO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1515",
		"CHURCH_CODE": "CCHESMIR01",
		"CHURCH_NAME": "ESMIRNA SADEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1516",
		"CHURCH_CODE": "CCHGUA01",
		"CHURCH_NAME": "GUAPA - CHIGORODO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1517",
		"CHURCH_CODE": "CCHLABEN01",
		"CHURCH_NAME": "LA BENDICION CHIGORODO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1518",
		"CHURCH_CODE": "CCHMAC01",
		"CHURCH_NAME": "MACONDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1519",
		"CHURCH_CODE": "CCHMONTE01",
		"CHURCH_NAME": "MONTE MORIA CHIGORODO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1520",
		"CHURCH_CODE": "CCHNUEVA01",
		"CHURCH_NAME": "NUEVA JERUSALÉN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1521",
		"CHURCH_CODE": "CCHNUEVO01",
		"CHURCH_NAME": "NUEVO PARAÍSO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1522",
		"CHURCH_CODE": "CCHPRA01",
		"CHURCH_NAME": "PRADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1523",
		"CHURCH_CODE": "CCHRENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1524",
		"CHURCH_CODE": "CCHROSIT01",
		"CHURCH_NAME": "ROSITA CHIGORODO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "194",
		"CHURCH_ID": "1525",
		"CHURCH_CODE": "CCHSTODO01",
		"CHURCH_NAME": "SANTO DONMINGO CHIGORODO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1526",
		"CHURCH_CODE": "CAPBELEN01",
		"CHURCH_NAME": "BELEN-NUEVA COLONIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1527",
		"CHURCH_CODE": "CAPBETAN01",
		"CHURCH_NAME": "BETANIA (RIO GRANDE)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1528",
		"CHURCH_CODE": "CAPELEDE01",
		"CHURCH_NAME": "ELEDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1529",
		"CHURCH_CODE": "CAPELPAR01",
		"CHURCH_NAME": "EL PARAÍSO (COQUITOS)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1530",
		"CHURCH_CODE": "CAPSARON01",
		"CHURCH_NAME": "SARON (NVA.COLONIA)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1531",
		"CHURCH_CODE": "CCUFILAD01",
		"CHURCH_NAME": "FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1532",
		"CHURCH_CODE": "CCUSHADA01",
		"CHURCH_NAME": "SHADAY ELIEL 24 DIC - CURRULAO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1533",
		"CHURCH_CODE": "CTEARCUA01",
		"CHURCH_NAME": "ARCUAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1534",
		"CHURCH_CODE": "CTECURRU01",
		"CHURCH_NAME": "CURRULAO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1535",
		"CHURCH_CODE": "CTEJERUS01",
		"CHURCH_NAME": "JERUSALÉN-TÍO GIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1536",
		"CHURCH_CODE": "CTELARIB01",
		"CHURCH_NAME": "LA RIBERA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1537",
		"CHURCH_CODE": "CTEMANAH01",
		"CHURCH_NAME": "MANAHIN COLDESA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1538",
		"CHURCH_CODE": "CTENUEVA01",
		"CHURCH_NAME": "NUEVA LUZ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "195",
		"CHURCH_ID": "1539",
		"CHURCH_CODE": "CTEPENIE01",
		"CHURCH_NAME": "PENIEL CURRULAO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "196",
		"CHURCH_ID": "1540",
		"CHURCH_CODE": "CBMSANTA01",
		"CHURCH_NAME": "REDENCION SANTA ROSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "196",
		"CHURCH_ID": "1541",
		"CHURCH_CODE": "COCBETAN01",
		"CHURCH_NAME": "BETANIA (HOREB DON MATIAS)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "196",
		"CHURCH_ID": "1542",
		"CHURCH_CODE": "CYADIOSP01",
		"CHURCH_NAME": "DIOS PROVEERÁ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "196",
		"CHURCH_ID": "1543",
		"CHURCH_CODE": "CYAGETSE01",
		"CHURCH_NAME": "GETSEMANI SAN PEDRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "197",
		"CHURCH_ID": "1544",
		"CHURCH_CODE": "CBMGOSSE01",
		"CHURCH_NAME": "GOSSEN/KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "197",
		"CHURCH_ID": "1545",
		"CHURCH_CODE": "CBMMARAN01",
		"CHURCH_NAME": "MARANATHA/CASTILLA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "197",
		"CHURCH_ID": "1546",
		"CHURCH_CODE": "CBMREDEN01",
		"CHURCH_NAME": "REDENCÍON KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "197",
		"CHURCH_ID": "1547",
		"CHURCH_CODE": "CBMSALEM01",
		"CHURCH_NAME": "SALEM/PARÍS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "197",
		"CHURCH_ID": "1548",
		"CHURCH_CODE": "CKELIBER01",
		"CHURCH_NAME": "LIBERTAD KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "197",
		"CHURCH_ID": "1549",
		"CHURCH_CODE": "CKEPENIE01",
		"CHURCH_NAME": "PENIEL KENNEDY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1550",
		"CHURCH_CODE": "CAMARENU01",
		"CHURCH_NAME": "RENUEVAME GRUPO GARSA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1551",
		"CHURCH_CODE": "CAMDELAFE",
		"CHURCH_NAME": "LA FE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1552",
		"CHURCH_CODE": "CLFNVAES01",
		"CHURCH_NAME": "NUEVA ESPERANZA - LA FE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1553",
		"CHURCH_CODE": "COCAGAPE01",
		"CHURCH_NAME": "AGAPE-OLAYA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1554",
		"CHURCH_CODE": "COCCALA01",
		"CHURCH_NAME": "CALAZANS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1555",
		"CHURCH_CODE": "COCLACOL01",
		"CHURCH_NAME": "LA COLINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "198",
		"CHURCH_ID": "1556",
		"CHURCH_CODE": "COCLAFLO01",
		"CHURCH_NAME": "LA FLORESTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "199",
		"CHURCH_ID": "1557",
		"CHURCH_CODE": "CAMPORTA01",
		"CHURCH_NAME": "PORTADORES DE ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "199",
		"CHURCH_ID": "1558",
		"CHURCH_CODE": "COMROSAL01",
		"CHURCH_NAME": "ROSALES OASIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1559",
		"CHURCH_CODE": "CCAGEN01",
		"CHURCH_NAME": "GENESIS -CARIBE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1560",
		"CHURCH_CODE": "CLAODI01",
		"CHURCH_NAME": "LA ODISEA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1561",
		"CHURCH_CODE": "CNAZAR01",
		"CHURCH_NAME": "NAZARET",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1562",
		"CHURCH_CODE": "CNEBELEN01",
		"CHURCH_NAME": "BELEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1563",
		"CHURCH_CODE": "CNEBETHE01",
		"CHURCH_NAME": "BETHEL-MULATOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1564",
		"CHURCH_CODE": "CNECANAA01",
		"CHURCH_NAME": "CANAAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1565",
		"CHURCH_CODE": "CNEELJAR01",
		"CHURCH_NAME": "EL JARDÍN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1566",
		"CHURCH_CODE": "CNEELPAR01",
		"CHURCH_NAME": "EL PARAÍSO-CHANGAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1567",
		"CHURCH_CODE": "CNEEMA01",
		"CHURCH_NAME": "EMAUS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1568",
		"CHURCH_CODE": "CNEEMMAN01",
		"CHURCH_NAME": "EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1569",
		"CHURCH_CODE": "CNEGALIL01",
		"CHURCH_NAME": "GALILEA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1570",
		"CHURCH_CODE": "CNEGETSE01",
		"CHURCH_NAME": "GETSEMANÍ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1571",
		"CHURCH_CODE": "CNEHOREB01",
		"CHURCH_NAME": "HOREB",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1572",
		"CHURCH_CODE": "CNELAMAG01",
		"CHURCH_NAME": "LA MAGDALENA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1573",
		"CHURCH_CODE": "CNELAURE01",
		"CHURCH_NAME": "LAURELES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1574",
		"CHURCH_CODE": "CNEMACED01",
		"CHURCH_NAME": "MACEDONIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1575",
		"CHURCH_CODE": "CNEMAR01",
		"CHURCH_NAME": "MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1576",
		"CHURCH_CODE": "CNENECOC01",
		"CHURCH_NAME": "NECOCLÍ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1577",
		"CHURCH_CODE": "CNENUE01",
		"CHURCH_NAME": "NUEVO HORIZONTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1578",
		"CHURCH_CODE": "CNEOAS01",
		"CHURCH_NAME": "OASIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1579",
		"CHURCH_CODE": "CNERED01",
		"CHURCH_NAME": "REDENCION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1580",
		"CHURCH_CODE": "CNERENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1581",
		"CHURCH_CODE": "CNESAMAR01",
		"CHURCH_NAME": "SAMARIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1582",
		"CHURCH_CODE": "CNESIN01",
		"CHURCH_NAME": "SINAI VEREDA PAVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1583",
		"CHURCH_CODE": "CNESIO01",
		"CHURCH_NAME": "SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "200",
		"CHURCH_ID": "1584",
		"CHURCH_CODE": "CNEVALLE01",
		"CHURCH_NAME": "VALLE DE BENDICIONES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1585",
		"CHURCH_CODE": "CNOREDIL01",
		"CHURCH_NAME": "EL REDIL NVO OCCIDENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1586",
		"CHURCH_CODE": "CNORENAC01",
		"CHURCH_NAME": "RENACER - NVO OCCID",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1587",
		"CHURCH_CODE": "COCBETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1588",
		"CHURCH_CODE": "COCCORAZ01",
		"CHURCH_NAME": "CORAZÓN EMMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1589",
		"CHURCH_CODE": "COCMANAN01",
		"CHURCH_NAME": "MANANTIAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1590",
		"CHURCH_CODE": "COCMARAN01",
		"CHURCH_NAME": "MARANATHA-SN.JAVIER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "201",
		"CHURCH_ID": "1591",
		"CHURCH_CODE": "COCNUEVA01",
		"CHURCH_NAME": "NUEVA JERUSALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "202",
		"CHURCH_ID": "1592",
		"CHURCH_CODE": "COMLAESP01",
		"CHURCH_NAME": "BELEN LA PALMA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "202",
		"CHURCH_ID": "1593",
		"CHURCH_CODE": "COMOASIS01",
		"CHURCH_NAME": "OASIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "202",
		"CHURCH_ID": "1594",
		"CHURCH_CODE": "CSOAMAAA01",
		"CHURCH_NAME": "AMAAA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "202",
		"CHURCH_ID": "1595",
		"CHURCH_CODE": "CSOAPOSE01",
		"CHURCH_NAME": "APOSENTO ALTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "203",
		"CHURCH_ID": "1596",
		"CHURCH_CODE": "COCNUEVO01",
		"CHURCH_NAME": "NUEVO REBAÑO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "203",
		"CHURCH_ID": "1597",
		"CHURCH_CODE": "COCRENAC01",
		"CHURCH_NAME": "RENACE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "203",
		"CHURCH_ID": "1598",
		"CHURCH_CODE": "CUMBETHE01",
		"CHURCH_NAME": "BETHEL VALLEJUELOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1599",
		"CHURCH_CODE": "CCHCAMPO01",
		"CHURCH_NAME": "CAMPO ALEGRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1600",
		"CHURCH_CODE": "CCHCRIST01",
		"CHURCH_NAME": "CRISTO REINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1601",
		"CHURCH_CODE": "CCHEDENC01",
		"CHURCH_NAME": "EDEN COQUITOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1602",
		"CHURCH_CODE": "CCHELJOR01",
		"CHURCH_NAME": "ELJORDAN-RIOSUCIO CH.",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1603",
		"CHURCH_CODE": "CCHGETSE01",
		"CHURCH_NAME": "GETSEMANI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1604",
		"CHURCH_CODE": "CCHMARAN01",
		"CHURCH_NAME": "MARANATHA (BAJIRÁ)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1605",
		"CHURCH_CODE": "CCHNUEVO02",
		"CHURCH_NAME": "NUEVO PARAISO-7 DE AGOSTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1606",
		"CHURCH_CODE": "CCHSALEM01",
		"CHURCH_NAME": "SALEM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1607",
		"CHURCH_CODE": "CCHTEGUE01",
		"CHURCH_NAME": "NUEVA SION TEGUERRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1608",
		"CHURCH_CODE": "COREMA01",
		"CHURCH_NAME": "EMANUEL MADRE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "204",
		"CHURCH_ID": "1609",
		"CHURCH_CODE": "CSUBELEN01",
		"CHURCH_NAME": "BELEN DE BAJIRA SUR DE URABA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "205",
		"CHURCH_ID": "1610",
		"CHURCH_CODE": "CCAELCER01",
		"CHURCH_NAME": "EL CERRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "205",
		"CHURCH_ID": "1611",
		"CHURCH_CODE": "CCAELJAR01",
		"CHURCH_NAME": "ELJARDÍN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "205",
		"CHURCH_ID": "1612",
		"CHURCH_CODE": "CCALAUNI01",
		"CHURCH_NAME": "LA UNIÓN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "205",
		"CHURCH_ID": "1613",
		"CHURCH_CODE": "CCAPIEDR01",
		"CHURCH_NAME": "PIEDRAS BLANCAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "205",
		"CHURCH_ID": "1614",
		"CHURCH_CODE": "CCAPOLIN01",
		"CHURCH_NAME": "POLINES CAREPA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "205",
		"CHURCH_ID": "1615",
		"CHURCH_CODE": "CELRET01",
		"CHURCH_NAME": "EL RETIRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "206",
		"CHURCH_ID": "1616",
		"CHURCH_CODE": "CTEELPAR01",
		"CHURCH_NAME": "EL PARAÍSO SAN PEDRO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "206",
		"CHURCH_ID": "1617",
		"CHURCH_CODE": "CTEESMIR01",
		"CHURCH_NAME": "ESMIRNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "206",
		"CHURCH_ID": "1618",
		"CHURCH_CODE": "CTELIRIO01",
		"CHURCH_NAME": "LOS LIRIOS PUEBLITO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "206",
		"CHURCH_ID": "1619",
		"CHURCH_CODE": "CTELOSOL01",
		"CHURCH_NAME": "LOS OLIVOS ( GRANADA)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "206",
		"CHURCH_ID": "1620",
		"CHURCH_CODE": "CTESARDI01",
		"CHURCH_NAME": "SARDIS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1621",
		"CHURCH_CODE": "CCMRENAC01",
		"CHURCH_NAME": "RENACER GUAYABAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1622",
		"CHURCH_CODE": "CCMSALEM01",
		"CHURCH_NAME": "SALEM SOPETRÁN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1623",
		"CHURCH_CODE": "CCMSANAT01",
		"CHURCH_NAME": "SANAT FÉ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1624",
		"CHURCH_CODE": "CCMSOPET01",
		"CHURCH_NAME": "SOPETRÁN RENUEVAME",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1625",
		"CHURCH_CODE": "CSFEBEJI01",
		"CHURCH_NAME": "EBEJICO LA RENTA STA FE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1626",
		"CHURCH_CODE": "CSFSANJE01",
		"CHURCH_NAME": "SAN JERONIMO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "207",
		"CHURCH_ID": "1627",
		"CHURCH_CODE": "CSFSARON01",
		"CHURCH_NAME": "SARÓN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1628",
		"CHURCH_CODE": "CCHBEDOM01",
		"CHURCH_NAME": "BEDOMUTATÁ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1629",
		"CHURCH_CODE": "CDADABEI01",
		"CHURCH_NAME": "DABEIBA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1630",
		"CHURCH_CODE": "CSUALFAR01",
		"CHURCH_NAME": "ALFARERO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1631",
		"CHURCH_CODE": "CSUCETIN01",
		"CHURCH_NAME": "CETINO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1632",
		"CHURCH_CODE": "CSUEFE01",
		"CHURCH_NAME": "EFESO BOTON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1633",
		"CHURCH_CODE": "CSUEMA01",
		"CHURCH_NAME": "EMANUEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1634",
		"CHURCH_CODE": "CSUPAVAR01",
		"CHURCH_NAME": "PAVARANDO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1635",
		"CHURCH_CODE": "CSUREMAN01",
		"CHURCH_NAME": "REMANENTE PISINGOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "208",
		"CHURCH_ID": "1636",
		"CHURCH_CODE": "CSURENAC01",
		"CHURCH_NAME": "RENACER LA SECRETA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "209",
		"CHURCH_ID": "1637",
		"CHURCH_CODE": "CSOELEDE01",
		"CHURCH_NAME": "EL EDEN LAS MERCEDES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "209",
		"CHURCH_ID": "1638",
		"CHURCH_CODE": "CSOELRED01",
		"CHURCH_NAME": "EL REDIL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "209",
		"CHURCH_ID": "1639",
		"CHURCH_CODE": "CSOLASVI01",
		"CHURCH_NAME": "LAS VIOLETAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "209",
		"CHURCH_ID": "1640",
		"CHURCH_CODE": "CSOSHALO01",
		"CHURCH_NAME": "SHALOM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "209",
		"CHURCH_ID": "1641",
		"CHURCH_CODE": "CUMBELEN01",
		"CHURCH_NAME": "BELEN ALIADAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1642",
		"CHURCH_CODE": "CTCBETHE01",
		"CHURCH_NAME": "BETHEL (EL UNO)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1643",
		"CHURCH_CODE": "CTCCENTR01",
		"CHURCH_NAME": "CENTRAL TURBO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1644",
		"CHURCH_CODE": "CTCELEDE01",
		"CHURCH_NAME": "LA HERMOSA EL EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1645",
		"CHURCH_CODE": "CTCELPRA01",
		"CHURCH_NAME": "EL PRADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1646",
		"CHURCH_CODE": "CTCFILAD01",
		"CHURCH_NAME": "FILADELFIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1647",
		"CHURCH_CODE": "CTCGRHOREB01",
		"CHURCH_NAME": "GRUPO HOREB TURBO CENTRAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1648",
		"CHURCH_CODE": "CTCNAZAR01",
		"CHURCH_NAME": "NAZARETH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1649",
		"CHURCH_CODE": "CTCSILOE01",
		"CHURCH_NAME": "SILOE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "210",
		"CHURCH_ID": "1650",
		"CHURCH_CODE": "CTCZARET01",
		"CHURCH_NAME": "ZARET",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1651",
		"CHURCH_CODE": "CTEAPOSE01",
		"CHURCH_NAME": "APOSENTO ALTO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1652",
		"CHURCH_CODE": "CTEEMMAN01",
		"CHURCH_NAME": "EMMANUEL-TURBO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1653",
		"CHURCH_CODE": "CTEESMIR02",
		"CHURCH_NAME": "ESMIRNA LAS GARZAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1654",
		"CHURCH_CODE": "CTEGALIL01",
		"CHURCH_NAME": "GALILEA CASANOVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1655",
		"CHURCH_CODE": "CTEHOREB01",
		"CHURCH_NAME": "HOREB EL LIMON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1656",
		"CHURCH_CODE": "CTELAESP01",
		"CHURCH_NAME": "LA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1657",
		"CHURCH_CODE": "CTELAUNI01",
		"CHURCH_NAME": "LA UNIÓN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1658",
		"CHURCH_CODE": "CTEMARAN01",
		"CHURCH_NAME": "MARANATHA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1659",
		"CHURCH_CODE": "CTEREMAN01",
		"CHURCH_NAME": "REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "211",
		"CHURCH_ID": "1660",
		"CHURCH_CODE": "CTERENAC01",
		"CHURCH_NAME": "RENACER-HOOVER Q.",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1661",
		"CHURCH_CODE": "COCLACAM01",
		"CHURCH_NAME": "MANAHAIN LA CAMPIÑA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1662",
		"CHURCH_CODE": "CUMAGU01",
		"CHURCH_NAME": "BELEN AGUAS FRIAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1663",
		"CHURCH_CODE": "CUMEMMAN01",
		"CHURCH_NAME": "EMMANUEL BELEN ZAFRA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1664",
		"CHURCH_CODE": "CUMKOI01",
		"CHURCH_NAME": "KOINONIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1665",
		"CHURCH_CODE": "CUMLAHUE01",
		"CHURCH_NAME": "LA HUERTA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1666",
		"CHURCH_CODE": "CUMREN01",
		"CHURCH_NAME": "RENUEVAME SANTA MONICA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1667",
		"CHURCH_CODE": "CUMSANTU01",
		"CHURCH_NAME": "SANTUARIO ICOLVEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "212",
		"CHURCH_ID": "1668",
		"CHURCH_CODE": "CUMUNACM01",
		"CHURCH_NAME": "UNIVERSITARIA - UNAC",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1669",
		"CHURCH_CODE": "CTCBETHA01",
		"CHURCH_NAME": "BETHANIA (PERANCH.)",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1670",
		"CHURCH_CODE": "CTCBETHE02",
		"CHURCH_NAME": "BETHEL ACANDI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1671",
		"CHURCH_CODE": "CTCGENEZ01",
		"CHURCH_NAME": "GENEZARETH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1672",
		"CHURCH_CODE": "CTCHOREB01",
		"CHURCH_NAME": "HOREB-UNGUÍA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1673",
		"CHURCH_CODE": "CTCSINAÍ01",
		"CHURCH_NAME": "SINAÍ - TANELA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1674",
		"CHURCH_CODE": "CUNAMIGO01",
		"CHURCH_NAME": "AMIGOS DE DIOS - ACANDÍ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1675",
		"CHURCH_CODE": "CUNJEHOV01",
		"CHURCH_NAME": "JEHOVA ES MI LUZ - DARIEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1676",
		"CHURCH_CODE": "CUNLAHER01",
		"CHURCH_NAME": "LA HERMOSA - CAPURGANÁ",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "213",
		"CHURCH_ID": "1677",
		"CHURCH_CODE": "CUNRENAC01",
		"CHURCH_NAME": "RENACER - UNGUIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "214",
		"CHURCH_ID": "1678",
		"CHURCH_CODE": "CAUAPOSE01",
		"CHURCH_NAME": "APOSENTOS VALDIVIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "214",
		"CHURCH_ID": "1679",
		"CHURCH_CODE": "CAUCORCO01",
		"CHURCH_NAME": "CORCOBADO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "214",
		"CHURCH_ID": "1680",
		"CHURCH_CODE": "CAUPUERT01",
		"CHURCH_NAME": "PUERTO  VALDIVIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "214",
		"CHURCH_ID": "1681",
		"CHURCH_CODE": "CAUYARUM01",
		"CHURCH_NAME": "YARUMAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "214",
		"CHURCH_ID": "1682",
		"CHURCH_CODE": "CYASEMBR01",
		"CHURCH_NAME": "SEMBRADORES BRICEÑO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1683",
		"CHURCH_CODE": "CZABETHE01",
		"CHURCH_NAME": "BETHEL - ZARAGOZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1684",
		"CHURCH_CODE": "CZACHEQU01",
		"CHURCH_NAME": "CHEQUINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1685",
		"CHURCH_CODE": "CZAEMMAN01",
		"CHURCH_NAME": "EMMANUEL-ESMERALDAS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1686",
		"CHURCH_CODE": "CZAREDEN01",
		"CHURCH_NAME": "REDENCIÓN ZARAGOZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1687",
		"CHURCH_CODE": "CZASANTU01",
		"CHURCH_NAME": "SANTUARIO PATO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1688",
		"CHURCH_CODE": "CZASILOH01",
		"CHURCH_NAME": "SILOH-PEÑITAS-ZARAGOZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "215",
		"CHURCH_ID": "1689",
		"CHURCH_CODE": "CZASOLDE01",
		"CHURCH_NAME": "SOL DE JUSTICIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "147",
		"CHURCH_ID": "2692",
		"CHURCH_CODE": "CALFAY04",
		"CHURCH_NAME": "ALFA Y OMEGA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "144",
		"CHURCH_ID": "2693",
		"CHURCH_CODE": "CJEHOV01",
		"CHURCH_NAME": "JEHOVA JIRETH",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "2695",
		"CHURCH_CODE": "CARTUCUR01",
		"CHURCH_NAME": "GRUPO TUCURINCA EL EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "2696",
		"CHURCH_CODE": "CARJERUS01",
		"CHURCH_NAME": "GRUPO JERUSALEM LA COLOMBIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "23",
		"CHURCH_ID": "2697",
		"CHURCH_CODE": "CARESPER01",
		"CHURCH_NAME": "IGLESIA LA ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "25",
		"CHURCH_ID": "2699",
		"CHURCH_CODE": "CCECANAA01",
		"CHURCH_NAME": "GRUPO CANAAN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "27",
		"CHURCH_ID": "2700",
		"CHURCH_CODE": "CCOVICTO01",
		"CHURCH_NAME": "GRUPO LA VICTORIA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "2701",
		"CHURCH_CODE": "CFUBETHE01",
		"CHURCH_NAME": "GRUPO BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "2702",
		"CHURCH_CODE": "CFUNUEVO01",
		"CHURCH_NAME": "NUEVO EDEN",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "2703",
		"CHURCH_CODE": "CFUCRISR01",
		"CHURCH_NAME": "GRUPO CRISTO REY",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "2704",
		"CHURCH_CODE": "CJEROCAE01",
		"CHURCH_NAME": "GRUPO ROCA ETERNA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "2705",
		"CHURCH_CODE": "CJEPALES01",
		"CHURCH_NAME": "GRUPO PALESTINA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "2706",
		"CHURCH_CODE": "CPARIOSD01",
		"CHURCH_NAME": "GRUPO RIOS DE AGUA VIVA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "30",
		"CHURCH_ID": "2707",
		"CHURCH_CODE": "CCEANGEL01",
		"CHURCH_NAME": "LOS ANGELES",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "31",
		"CHURCH_ID": "2708",
		"CHURCH_CODE": "CPAURUMI01",
		"CHURCH_NAME": "GRUPO URUMITA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "2709",
		"CHURCH_CODE": "CMABETHE01",
		"CHURCH_NAME": "BETHEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "2710",
		"CHURCH_CODE": "CMANOULA01",
		"CHURCH_NAME": "GRUPO NOULAKA JESU",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "32",
		"CHURCH_ID": "2711",
		"CHURCH_CODE": "CMASENDE01",
		"CHURCH_NAME": "GRUPO SENDERO DE ESPERANZA",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "2712",
		"CHURCH_CODE": "CNEHOGAR01",
		"CHURCH_NAME": "GRUPO HOGAR CELESTIAL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "33",
		"CHURCH_ID": "2713",
		"CHURCH_CODE": "CNEREMAN01",
		"CHURCH_NAME": "REMANENTE",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "39",
		"CHURCH_ID": "2714",
		"CHURCH_CODE": "CSCELIEL01",
		"CHURCH_NAME": "GRUPO ELIEL",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "43",
		"CHURCH_ID": "2715",
		"CHURCH_CODE": "CSBMONTO01",
		"CHURCH_NAME": "MONTE DE LOS OLIVOS",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "43",
		"CHURCH_ID": "2716",
		"CHURCH_CODE": "CSBMONTE01",
		"CHURCH_NAME": "MONTE DE SION",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "45",
		"CHURCH_ID": "2717",
		"CHURCH_CODE": "CSORENAC01",
		"CHURCH_NAME": "RENACER",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "2718",
		"CHURCH_CODE": "CVAELOHI01",
		"CHURCH_NAME": "GRUPO ELOHIM",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "2719",
		"CHURCH_CODE": "CVAROSAD01",
		"CHURCH_NAME": "GRUPO ROSA DE SARON",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "46",
		"CHURCH_ID": "2720",
		"CHURCH_CODE": "CVASANTU01",
		"CHURCH_NAME": "GRUPO SANTUARIO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "48",
		"CHURCH_ID": "2721",
		"CHURCH_CODE": "CVLREMAN01",
		"CHURCH_NAME": "GRUPO REMANENTE PUEBLO BELLO",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "28",
		"CHURCH_ID": "2724",
		"CHURCH_CODE": "CFUEBENE01",
		"CHURCH_NAME": "EBENEZER - PIVIJAI",
		"STATUS": "0"
	},
	{
		"DISTRICT_ID": "24",
		"CHURCH_ID": "2726",
		"CHURCH_CODE": "CBODIFIC01",
		"CHURCH_NAME": "EL DIFICIL-BOSCONIA",
		"STATUS": "0"
	}
]
function Download(props) {

    useEffect(() => {

        const consultar = async() => {

            datos.forEach(element => {
                props.firebase.doRefDoc('iglesias', element.CHURCH_ID).set(element)
                .then(
                   console.log(element.CHURCH_ID)
                )
                
            })
            
        }

        consultar();

    }, [])

    return ( <text>entra</text>)

}

const condition = authUser => authUser !== null;

export default withAuthorization(condition)(withAuthentication(Download));