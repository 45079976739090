import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux'
import { withAuthorization } from '../context/session';
import "../../assets/css/sra.css";
import {withAuthentication} from '../context/session';
import Swal from 'sweetalert2';


function Home (props) {
    
    const user_uid = useSelector((state) => state.user.uid);
    const [asociaciones, setAsociaciones] = useState([]);
    const [distritos, setDistritos] = useState([]);
    const [iglesias, setIglesias] = useState([]);
    const [iglesia, setIglesia] = useState('');
    
    useEffect(()=>{
        props.firebase.doRefCollection('asociaciones').orderBy("BU_NAME", "asc").get()
        .then(
            (querySnapshot) => {
                let data=[];
               querySnapshot.forEach((doc) => {
                    data.push({...doc.data(), id:doc.id});
               });
               setAsociaciones(data);               
            }
        );

    },[])
    
    const enviar = (e) =>{
        e.preventDefault();
        let nombres = e.target.nombre.value;
        let apellidos = e.target.apellido.value;
        let telefono = e.target.telefono.value;
        let tipoDocumento = e.target.tipo_doc.value;
        let documento = e.target.doc.value;

        nombres = nombres.toUpperCase();
        apellidos = apellidos.toUpperCase();
        telefono = telefono;
        tipoDocumento = tipoDocumento.toUpperCase();
        documento = documento.trim();
        /* let edad = e.target.edad.value; */
        
        if( nombres === '' ||
            apellidos === '' ||
            telefono === '' ||
            tipoDocumento === 'Seleccione...' ||
            documento === '' || iglesia===''){

                Swal.fire({
                    icon: 'info',
                    title: 'Faltan datos o están mal escritos.',
                    text: 'Le agradecemos llenar todos los datos correctamente.',
                    confirmButtonColor: '#F9B000',
                })
                return;
            }

            swalWithBootstrapButtons.fire({
                title: '¿Acepta los términos y condiciones del uso de sus datos personales?',
                html:'<center>Los datos personales serán tratados por la Iglesia Adventista del Séptimo Día de Colombia ,en conformidad con la política de protección de datos personales de nuestra institución con la finalidad de gestionar reservas para el ingreso a los templos cumpliendo los protocolos de bioseguridad exigidos por el gobierno nacional. El Derecho al Habeas Data podrá ejercerlo a través del siguiente correo electrónico privacidad@iasd.org.co<br/><b><a href="https://www.unioncolombiana.org.co/es/politica-de-cookies/">https://www.unioncolombiana.org.co/es/politica-de-cookies/</a></b></center>',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Si, acepto',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    let  dataUser = {
                        usuario : nombres+" "+ apellidos,                       
                        iglesia,  
                        register: true,
                        documento                      
                    }

                    let dataPerson ={
                        nombres,
                        apellidos,
                        telefono,
                        tipoDocumento,
                        documento,                        
                        usuarioAdmin:user_uid
                    }
                            
                    props.firebase.doRefDoc('user',user_uid).update(dataUser)
                    props.firebase.doRefDoc('user',user_uid).collection('miembros').doc(documento).set(dataPerson)

                    props.history.push('/participantes')
                   
                } else if (
                    
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                    'Cancelado',
                    '',
                    'error'
                    )
                }
            })


        
        
    } 
    
    const consultarDistritos = (idAsociacion) =>{
        props.firebase.doRefCollection('distritos').where("BU_CODE","==",idAsociacion).orderBy("DISTRICT_NAME", "asc").get()
        .then(
            (querySnapshot) => {
                let data=[];
               querySnapshot.forEach((doc) => {
                    data.push({...doc.data(), id:doc.id});
               });
               setDistritos(data);               
            }
        );
    }
    const consultarIglesias = (idDistrito) =>{
        props.firebase.doRefCollection('iglesias').where("DISTRICT_ID","==",idDistrito).orderBy("CHURCH_NAME", "asc").get()
        .then(
            (querySnapshot) => {
                let data=[];
               querySnapshot.forEach((doc) => {
                    data.push({...doc.data(), id:doc.id});
               });
               setIglesias(data);               
            }
        );
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-warning'
        },
        buttonsStyling: false
        })
    return ( 
        <>            
           <div className="container  p-auto  bg-white">
                <div className="row ">
                    <div className="col-xl-4 col-sm-1">
                    </div>                
                    <div className="col-xl-4 col-sm-10">
                        <form onSubmit={(e)=>enviar(e)}>
                         <center><h1  className="font-weight-normal text-dark">Información Personal</h1></center>
                         
                            <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Nombre:</label>
                                <input type="text" className="form-control  text-dark"  name="nombre" placeholder="Nombres" />
                            </div>
                            <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Apellido:</label>
                                <input type="text" className="form-control  text-dark" name="apellido" placeholder="Apellidos" />
                            </div>
                            <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Teléfono:</label>
                                <input type="number" className="form-control  text-dark"  name="telefono" placeholder="Teléfono"/>
                            </div>
                            <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Tipo Documento:</label>
                                <select className="form-control  text-dark" name="tipo_doc" >
                                    <option>Seleccione...</option>
                                    <option value="CC">Cédula de Ciudadanía</option>
                                    <option value="TI">Tarjeta de Identidad</option>
                                    <option value="PP">Pasaporte</option>
                                </select>                                                                
                            </div>
                            <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Documento:</label>
                                <input type="text" className="form-control  text-dark" name="doc" placeholder="Numero Documento" />                    
                            </div>
                            {/* <div className="form-group text-dark">
                                <label htmlFor="exampleInputEmail1">Edad:</label>
                                <input type="number" className="form-control  text-dark" name="edad" placeholder="Edad" />
                            </div>     */}                        
                            <div className="form-select text-dark" type="iglesia">
                            <b><label htmlFor="exampleInputEmail1">Seleccione su iglesia:</label></b>                            
                                <select className="form-control"  name="asociacion" onChange={(e) =>consultarDistritos(e.target.value)}>
                                    <option >Seleccione la Asociación</option>
                                {asociaciones.map((asociacion,index) => <option value={asociacion.BU_CODE} key={index}>{asociacion.BU_NAME}</option> )}
                                </select>
                            </div>
                            <div className="form-select text-dark" type="iglesia">                            
                                <select className="form-control"  name="distrito" onChange={(e) =>consultarIglesias(e.target.value)}>
                                    <option >Seleccione el distrito</option>
                                {distritos.map((distrito,index) => <option value={distrito.DISTRICT_ID} key={index}>{distrito.DISTRICT_NAME}</option> )}
                                </select>
                            </div>
                            <div className="form-select text-dark" type="iglesia">                            
                                <select className="form-control"  name="iglesia" onChange={(e) =>setIglesia(e.target.value)}>
                                    <option >Seleccione la iglesia</option>
                                {iglesias.map((iglesia,index) => <option value={iglesia.CHURCH_ID} key={index}>{iglesia.CHURCH_NAME}</option> )}
                                </select>
                            </div>
                            <div className="form-group">
                            <center><button className="btn btn-default"  style={{"backgroundColor": "#F9B000"}}  type="submit"> Guardar Datos</button></center> 
                            </div>
                        </form>
                    </div>
                                 
                    
                </div>   
            </div>           
        </>  
      );
}

const condition = authUser => authUser !== null;
 
export default withAuthorization(condition)(withAuthentication(Home));
